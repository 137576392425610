import React from "react";
import { Field, reduxForm, change } from "redux-form";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import countryList from "react-select-country-list";
import PhoneInput from "react-phone-number-input";
import BirthDateComponent from "../BirthDateComponent";
import PhoneComponent from "../PhoneComponent";
import { formatDateToString } from "../../utils/Date";
import "react-phone-input-2/lib/style.css";
import "react-phone-number-input/style.css";
import * as validation from "../../utils/ValidationRules";
import validateFirstStep from "../../utils/validation_forms/IdentityForm/validate_first_step";
import { validationRules } from "../../utils/validation_forms/IdentityForm/validate_first_step";
import help from "../../assets/group-5/group-5.png";
import help2x from "../../assets/group-5/group-5@2x.png";
import help3x from "../../assets/group-5/group-5@3x.png";
import vitaleCard from "../../assets/mtr_images/Carte vitale.png";
import ModalImage from "../Modals/modal_image";
import { getDefaultLanguage } from "../../utils/language";

/**
 * This component is using to manage user identity
 * [MAGE.COM.WA.18 - 1.8] to [MAGE.COM.WA.30 - 1.8] and [MAGE.COM.WA.82 - 1.8] and [MAGE.ALERT.WA.1 - 1.8]
 * @component
 */
class Identity extends React.Component {
  constructor(props) {
    super(props);

    //Get list of country
    this.listOfCountry = countryList().getData();

    this.dateConfig = {
      date: {
        format: "DD",
        caption: props.translate("DATE_DAY_LABEL"),
        step: 1,
      },
      month: {
        format: "MM",
        caption: props.translate("DATE_MONTH_LABEL"),
        step: 1,
      },
      year: {
        format: "YYYY",
        caption: props.translate("DATE_YEAR_LABEL"),
        step: 1,
      },
    };

    this.state = props.forms.hasOwnProperty("wizard")
      ? {
          gender: props.forms.wizard.values.gender
            ? props.forms.wizard.values.gender
            : "",
          isMan: props.forms.wizard.values.gender
            ? props.forms.wizard.values.gender === "M"
              ? true
              : false
            : "",
          firstName: props.forms.wizard.values.firstName
            ? props.forms.wizard.values.firstName
            : "",
          lastName: props.forms.wizard.values.lastName
            ? props.forms.wizard.values.lastName
            : "",
          birthName: props.forms.wizard.values.birthName
            ? props.forms.wizard.values.birthName
            : "",
          dtDay: props.forms.wizard.values.dtDay
            ? props.forms.wizard.values.dtDay
            : "",
          dtMonth: props.forms.wizard.values.dtMonth
            ? props.forms.wizard.values.dtMonth
            : "",
          dtYear: props.forms.wizard.values.dtYear
            ? props.forms.wizard.values.dtYear
            : "",
          birthPlace: props.forms.wizard.values.birthPlace
            ? props.forms.wizard.values.birthPlace
            : "",
          nationality: props.forms.wizard.values.nationality
            ? props.forms.wizard.values.nationality
            : "",
          hasNir: props.forms.wizard.values.hasNir
            ? props.forms.wizard.values.hasNir === "true"
              ? true
              : false
            : "",
          nir: props.forms.wizard.values.nir
            ? props.forms.wizard.values.nir
            : "",
          email: props.forms.wizard.values.email
            ? props.forms.wizard.values.email
            : props.userEmail !== ""
            ? props.userEmail
            : "",
          phoneNumber: props.forms.wizard.values.phoneNumber
            ? props.forms.wizard.values.phoneNumber
            : "",
          dialCode: props.forms.wizard.values.dialCode
            ? props.forms.wizard.values.dialCode
            : "33",
          listOfCountry: this.listOfCountry,
          isModalSecuritySocialShowing: false,
          isOpen: false,
          date: new Date(),
          dateConfig: this.dateConfig,
          defaultLanguage: getDefaultLanguage(),
          errors: {},
        }
      : {
          gender: "",
          isMan: "",
          firstName: "",
          lastName: "",
          birthName: "",
          dtDay: "",
          dtMonth: "",
          dtYear: "",
          birthPlace: "",
          nationality: "",
          hasNir: "",
          nir: "",
          email: props.userEmail !== "" ? props.userEmail : "",
          phoneNumber: "",
          dialCode: "33",
          listOfCountry: this.listOfCountry,
          isModalSecuritySocialShowing: false,
          isOpen: false,
          date: new Date(),
          dateConfig: this.dateConfig,
          defaultLanguage: getDefaultLanguage(),
          errors: {},
        };
    this.handleGenderChange = this.handleGenderChange.bind(this);
    this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
    this.handleLastNameChange = this.handleLastNameChange.bind(this);
    this.handleBirthNameChange = this.handleBirthNameChange.bind(this);
    this.handleDtDayChange = this.handleDtDayChange.bind(this);
    this.handleDtMonthChange = this.handleDtMonthChange.bind(this);
    this.handleDtYearChange = this.handleDtYearChange.bind(this);
    this.handleBirthPlaceChange = this.handleBirthPlaceChange.bind(this);
    this.handleNationalityChange = this.handleNationalityChange.bind(this);
    this.handleSocialSecurityChange =
      this.handleSocialSecurityChange.bind(this);
    this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleSocialSecurityNumberChange =
      this.handleSocialSecurityNumberChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  openModalHandler(values) {
    ModalImage(values);
  }

  /**
   * [MAGE.COM.WA.18 - 1.8], [MAGE.COM.WA.19 - 1.8]
   */
  handleGenderChange(e) {
    e.target.value === "M"
      ? this.setState({ isMan: true })
      : this.setState({ isMan: false });
    var errors = this.state.errors;
    errors.gender = "";
    errors.formError = "";
    this.setState({ errors: errors, gender: e.target.value });
  }

  /**
   * [MAGE.COM.WA.20 - 1.8]
   */
  //   handleFirstNameChange(e) {
  //     var errors = this.state.errors;
  //     if (
  //       validation.charLengthValidation(e.target.value, 20) &&
  //       e.target.value.match(/^[A-Za-zÀ-ÖØ-öø-ÿ\s]*$/)
  //     ) {
  //       this.setState({ firstName: e.target.value });
  //       errors.firstName = "";
  //       errors.formError = "";
  //       this.setState({ errors: errors });
  //       this.props.change("firstName", e.target.value);
  //     }
  //   }

  //   TODO:
  handleFirstNameChange(e) {
    var errors = this.state.errors;
    if (validation.charLengthValidation(e.target.value, 30)) {
      this.setState({ firstName: e.target.value });
      errors.firstName = "";
      errors.formError = "";
      this.setState({ errors: errors });
      this.props.change("firstName", e.target.value);
    }
  }

  //TODO:

  handleLastNameChange(e) {
    var errors = this.state.errors;
    if (validation.charLengthValidation(e.target.value, 30)) {
      this.setState({ lastName: e.target.value });
      errors.lastName = "";
      errors.formError = "";
      this.setState({ errors: errors });
      this.props.change("lastName", e.target.value);
    }
  }

  /**
   * [MAGE.COM.WA.18 - 1.8], [MAGE.COM.WA.19 - 1.8]
   */
  //   handleLastNameChange(e) {
  //     var errors = this.state.errors;
  //     if (
  //       validation.charLengthValidation(e.target.value, 30) &&
  //       e.target.value.match(/^[A-Za-zÀ-ÖØ-öø-ÿ\s]*$/)
  //     ) {
  //       this.setState({ lastName: e.target.value });
  //       errors.lastName = "";
  //       errors.formError = "";
  //       this.setState({ errors: errors });
  //       this.props.change("lastName", e.target.value);
  //     }
  //   }

  /**
   * [MAGE.COM.WA.18 - 1.8], [MAGE.COM.WA.19 - 1.8]
   */
  handleBirthNameChange(e) {
    var errors = this.state.errors;
    if (
      validation.charLengthValidation(e.target.value, 30) &&
      e.target.value.match(/^[A-Za-zÀ-ÖØ-öø-ÿ\s]*$/)
    ) {
      this.setState({ birthName: e.target.value });
      errors.birthName = "";
      errors.formError = "";
      this.setState({ errors: errors });
      this.props.change("birthName", e.target.value);
    }
  }

  /**
   * [MAGE.COM.WA.21 - 1.8]
   */
  handleDtDayChange(e) {
    var errors = this.state.errors;
    if (validation.charLengthValidation(e.target.value, 2)) {
      errors.dtDay = "";
      errors.birthDate = "";
      errors.formError = "";
      this.setState({ errors: errors, dtDay: e.target.value });
    }
  }
  handleDtMonthChange(e) {
    var errors = this.state.errors;
    if (validation.charLengthValidation(e.target.value, 2)) {
      errors.dtMonth = "";
      errors.birthDate = "";
      errors.formError = "";
      this.setState({ errors: errors, dtMonth: e.target.value });
    }
  }
  handleDtYearChange(e) {
    var errors = this.state.errors;
    if (validation.charLengthValidation(e.target.value, 4)) {
      errors.dtYear = "";
      errors.birthDate = "";
      errors.formError = "";
      this.setState({ errors: errors, dtYear: e.target.value });
    }
  }

  handleBirthDateSelected = (date) => {
    console.log("Date : " + date);
    this.props.change("birthDate", formatDateToString(date));
    this.setState({ birthDate: formatDateToString(date), date, isOpen: false });
  };

  /**
   * [MAGE.COM.WA.22 - 1.8]
   */
  handleBirthPlaceChange(e) {
    var errors = this.state.errors;
    if (validation.charLengthValidation(e.target.value, 30)) {
      this.setState({ birthPlace: e.target.value });
      errors.birthPlace = "";
      errors.formError = "";
      this.setState({ errors: errors });
    }
  }

  /**
   * [MAGE.COM.WA.26 - 1.8]
   */
  handleNationalityChange(e) {
    this.setState({ nationality: e.target.value });
  }

  /**
   * [MAGE.COM.WA.24 - 1.8]
   */
  handlePhoneNumberChange(value) {
    console.log(value);
    var errors = this.state.errors;
    errors.phoneNumber = "";
    errors.formError = "";
    this.setState({ errors: errors, phoneNumber: value });
    this.props.change("phoneNumber", value);
  }

  /**
   * [MAGE.COM.WA.23 - 1.8]
   * This spec has many aspects
   */
  handleEmailChange(e) {
    var errors = this.state.errors;
    if (validation.charLengthValidation(e.target.value, 40)) {
      this.setState({ email: e.target.value });
      errors.email = "";
      errors.formError = "";
      this.setState({ errors: errors });
    }
  }

  /**
   * [MAGE.COM.WA.27 - 1.8]
   */
  handleSocialSecurityNumberChange(e) {
    var errors = this.state.errors;
    this.setState({ nir: e.target.value });
    if (validation.charLengthValidation(e.target.value, 15)) {
      errors.nir = "";
      errors.formError = "";
      this.setState({ errors: errors });
    }
  }

  /**
   * [MAGE.COM.WA.27 - 1.8]
   */
  handleSocialSecurityChange(e) {
    var errors = this.state.errors;
    var choice = "";

    if (e.target.value === "true") choice = true;
    else choice = false;

    errors.hasNir = "";
    errors.formError = "";
    this.setState({ hasNir: choice, errors: errors, nir: "" });
    this.props.change("nir", "");
  }

  /**
   * [MAGE.COM.WA.30 - 1.8]
   */
  handleSubmit(e) {
    const dataToValidate = {
      gender: this.state.gender,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      birthName: this.state.birthName,
      dtDay: this.state.dtDay,
      dtMonth: this.state.dtMonth,
      dtYear: this.state.dtYear,
      birthPlace: this.state.birthPlace,
      nationality: this.state.nationality,
      hasNir: this.state.hasNir,
      nir: this.state.nir,
      email: this.state.email,
      phoneNumber: this.state.phoneNumber,
    };

    var errorList = validationRules(dataToValidate, this.props.translate);
    if (Object.keys(errorList).length !== 0) {
      this.setState({ errors: errorList });
    } else {
      this.props.onSubmit();
    }

    e.preventDefault();
  }

  renderError = ({ meta: { touched, error } }) =>
    touched && error ? <span>{error}</span> : false;

  render() {
    const { translate, device, userEmail } = this.props;
    let countryList = this.state.listOfCountry;
    const toReplace = { value: "ES", label: translate("ES") };
    countryList = countryList.map((country) =>
      country.value !== toReplace.value ? country : toReplace
    );
    countryList.sort(function (a, b) {
      return a.label.localeCompare(b.label);
    });
    return (
      <div>
        {device === "lap/desk" && (
          <>
            <p className="title">{translate("IDENTITY_COMPONENT_TITLE")}</p>
            <div>
              <form onSubmit={this.handleSubmit}>
                <div className="fieldContainer">
                  <div className="input-container">
                    <div>
                      <p className="label">
                        {translate("GENDER_FORM_LABEL")}
                        <span className="required-tag">*</span>
                      </p>
                      <div>
                        <label
                          className={
                            this.state.isMan === true
                              ? "radio-field-checked"
                              : "radio-field"
                          }
                        >
                          <Field
                            name="gender"
                            component="input"
                            type="radio"
                            className="radioBtn"
                            value="M"
                            checked={this.state.isMan === true}
                            onChange={this.handleGenderChange}
                          />{" "}
                          {translate("GENDER_MALE_LABEL")}
                        </label>
                      </div>
                      <div>
                        <label
                          className={
                            this.state.isMan === false
                              ? "radio-field-checked"
                              : "radio-field"
                          }
                        >
                          <Field
                            name="gender"
                            component="input"
                            type="radio"
                            className="radioBtn"
                            value="F"
                            checked={this.state.isMan === false}
                            onChange={this.handleGenderChange}
                          />{" "}
                          {translate("GENDER_FEMALE_LABEL")}
                        </label>
                      </div>
                      {this.state.errors.gender &&
                        this.state.errors.gender !== "" && (
                          <p className="error-label">
                            {this.state.errors.gender}
                          </p>
                        )}
                    </div>
                  </div>
                  {/* [MAGE.COM.WA.20 - 1.8] */}
                  <div className="input-container">
                    <div>
                      <p className="label">
                        {translate("FIRSTNAME_LABEL")}
                        <span className="required-tag">*</span>
                      </p>
                      <div>
                        <input
                          type="text"
                          className={
                            this.state.errors.firstName &&
                            this.state.errors.firstName !== ""
                              ? "input-large-error"
                              : "input-large"
                          }
                          placeholder={translate("FIRSTNAME_LABEL")}
                          maxLength="20"
                          value={this.state.firstName}
                          onChange={this.handleFirstNameChange}
                        />
                        {/* <Field
                          name="firstName"
                          component="input"
                          type="hidden"
                          value={this.state.firstName}
                        /> */}

                        {this.state.errors.firstName &&
                          this.state.errors.firstName !== "" && (
                            <p className="error-label">
                              {this.state.errors.firstName}
                            </p>
                          )}
                      </div>
                    </div>
                  </div>
                  {/* [MAGE.COM.WA.18 - 1.8], [MAGE.COM.WA.19 - 1.8] */}
                  <div className="input-container">
                    <p className="label">
                      {this.state.isMan
                        ? translate("LASTNAME_MALE_LABEL")
                        : translate("LASTNAME_FEMALE_LABEL")}
                      <span className="required-tag">*</span>
                    </p>
                    <div>
                      <input
                        type="text"
                        className={
                          this.state.errors.lastName &&
                          this.state.errors.lastName !== ""
                            ? "input-large-error"
                            : "input-large"
                        }
                        placeholder={translate("LASTNAME_LABEL")}
                        maxLength="30"
                        value={this.state.lastName}
                        onChange={this.handleLastNameChange}
                      />
                      <Field
                        name="lastName"
                        component="input"
                        type="hidden"
                        value={this.state.lastName}
                      />
                      {this.state.errors.lastName &&
                        this.state.errors.lastName !== "" && (
                          <p className="error-label">
                            {this.state.errors.lastName}
                          </p>
                        )}
                    </div>
                  </div>
                  <div className="input-container">
                    <p className="label">
                      {this.state.isMan
                        ? translate("BIRTHNAME_MALE_LABEL")
                        : translate("BIRTHNAME_FEMALE_LABEL")}
                    </p>
                    <div>
                      <input
                        type="text"
                        className={
                          this.state.errors.birthName &&
                          this.state.errors.birthName !== ""
                            ? "input-large-error"
                            : "input-large"
                        }
                        placeholder={translate("BIRTHNAME_MALE_LABEL")}
                        maxLength="30"
                        value={this.state.birthName}
                        onChange={this.handleBirthNameChange}
                      />
                      <Field
                        name="birthName"
                        component="input"
                        type="hidden"
                        value={this.state.birthName}
                      />
                      {this.state.errors.birthName &&
                        this.state.errors.birthName !== "" && (
                          <p className="error-label">
                            {this.state.errors.birthName}
                          </p>
                        )}
                    </div>
                  </div>
                  <div className="row-input">
                    <div className="zone-input">
                      <p className="label">
                        {translate("BIRTHDAY_LABEL")}
                        <span className="required-tag">*</span>
                      </p>
                      <BirthDateComponent
                        tag={Field}
                        translate={translate}
                        dayName="dtDay"
                        dayValue={this.state.dtDay}
                        dayOnChange={this.handleDtDayChange}
                        dayOnError={this.state.errors.dtDay}
                        monthName="dtMonth"
                        monthValue={this.state.dtMonth}
                        monthOnChange={this.handleDtMonthChange}
                        monthOnError={this.state.errors.dtMonth}
                        yearName="dtYear"
                        yearValue={this.state.dtYear}
                        yearOnChange={this.handleDtYearChange}
                        yearOnError={this.state.errors.dtYear}
                        onFieldError={this.state.errors.birthDate}
                      />
                    </div>
                  </div>
                  {/* [MAGE.COM.WA.22 - 1.8, 1.13, 1.17] */}
                  <div className="input-container">
                    <p className="label">{translate("BIRTHPLACE_LABEL")}</p>
                    <div>
                      <Field
                        name="birthPlace"
                        component="input"
                        type="text"
                        placeholder={translate("BIRTHPLACE_PLACEHOLDER_LABEL")}
                        className={
                          this.state.errors.birthPlace &&
                          this.state.errors.birthPlace !== ""
                            ? "input-large-error"
                            : "input-large"
                        }
                        maxLength="30"
                        value={this.state.birthPlace}
                        onChange={this.handleBirthPlaceChange}
                      />
                      {this.state.errors.birthPlace &&
                        this.state.errors.birthPlace !== "" && (
                          <p className="error-label">
                            {this.state.errors.birthPlace}
                          </p>
                        )}
                    </div>
                  </div>
                  {/* [MAGE.COM.WA.26 - 1.8] */}
                  <div className="input-container">
                    <p className="label">{translate("NATIONALITY_LABEL")}</p>
                    <Field
                      name="nationality"
                      component="select"
                      value={this.state.nationality}
                      className={
                        this.state.errors.nationality &&
                        this.state.errors.nationality !== ""
                          ? "input-large-error"
                          : "input-large"
                      }
                      onChange={this.handleNationalityChange}
                    >
                      {[
                        <option value="">
                          {translate("NATIONALITY_CHOOSE_LABEL")}
                        </option>,
                        countryList.map((cty) => {
                          return <option value={cty.value}>{cty.label}</option>;
                        }),
                      ]}
                    </Field>
                  </div>
                  {/* [MAGE.COM.WA.27 - 1.8] */}
                  <div className="input-container">
                    <div>
                      <p className="label">
                        {translate("QUESTION_SECU_LABEL")}
                        <span className="required-tag">*</span>
                      </p>
                      <div>
                        <label
                          className={
                            this.state.hasNir === true
                              ? "radio-field-checked"
                              : "radio-field"
                          }
                        >
                          <Field
                            name="hasNir"
                            component="input"
                            type="radio"
                            className="radioBtn"
                            value={true}
                            checked={this.state.hasNir === true}
                            onChange={this.handleSocialSecurityChange}
                          />{" "}
                          {translate("RESPONSE_YES_TO_SECU_LABEL")}
                        </label>
                      </div>
                      <div>
                        <label
                          className={
                            this.state.hasNir === false
                              ? "radio-field-checked"
                              : "radio-field"
                          }
                        >
                          <Field
                            name="hasNir"
                            component="input"
                            type="radio"
                            className="radioBtn"
                            value={false}
                            checked={this.state.hasNir === false}
                            onChange={this.handleSocialSecurityChange}
                          />{" "}
                          {translate("RESPONSE_NO_TO_SECU_LABEL")}
                        </label>
                      </div>
                      {this.state.errors.hasNir &&
                        this.state.errors.hasNir !== "" && (
                          <p className="error-label">
                            {this.state.errors.hasNir}
                          </p>
                        )}
                    </div>
                  </div>
                  {this.state.hasNir === true && (
                    <div className="row-input">
                      <div>
                        <p className="label">
                          {translate("SECU_NUMBER_LABEL")}
                          <span className="required-tag">*</span>
                        </p>
                        <div className="input-with-icon">
                          <Field
                            name="nir"
                            component="input"
                            type="text"
                            className={
                              this.state.errors.nir &&
                              this.state.errors.nir !== ""
                                ? "input-large-error"
                                : "input-large"
                            }
                            placeholder={translate(
                              "SECU_NUMBER_PLACEHOLDER_LABEL"
                            )}
                            maxLength="15"
                            value={this.state.nir}
                            onChange={this.handleSocialSecurityNumberChange}
                          />
                          <img
                            className="icon-help"
                            alt="ihelp"
                            src={help}
                            srcSet={`${help2x} 768w, ${help3x} 1280w`}
                            onClick={() =>
                              this.openModalHandler({
                                title: translate("SECURITY_SOCIAL_IMAGE_LABEL"),
                                image: vitaleCard,
                              })
                            }
                          />
                        </div>
                        {/* [MAGE.ALERT.WA.11 - 1.8] */}
                        {this.state.errors.nir &&
                          this.state.errors.nir !== "" && (
                            <p className="error-label">
                              {this.state.errors.nir}
                            </p>
                          )}
                      </div>
                    </div>
                  )}
                  {/** [MAGE.COM.WA.23 - 1.8] */}
                  <div className="input-container">
                    <p className="label">
                      {translate("EMAIL_ADDRESS_LABEL")}
                      <span className="required-tag">*</span>
                    </p>
                    <Field
                      name="email"
                      component="input"
                      type="email"
                      className={
                        this.state.errors.email &&
                        this.state.errors.email !== ""
                          ? "input-large-error"
                          : "input-large"
                      }
                      placeholder={translate("EMAIL_ADDRESS_PLACEHOLDER_LABEL")}
                      maxLength="40"
                      value={userEmail}
                      onChange={this.handleEmailChange}
                      disabled={userEmail !== "" ? "disabled" : ""}
                    />
                    {this.state.errors.email &&
                      this.state.errors.email !== "" && (
                        <p className="error-label">{this.state.errors.email}</p>
                      )}
                  </div>
                  <div className="input-container">
                    <p className="label">
                      {translate("PHONE_NUMBER_LABEL")}
                      <span className="required-tag">*</span>
                    </p>
                    <PhoneInput
                      placeholder="00 00 00 00 00"
                      value={this.state.phoneNumber}
                      onChange={this.handlePhoneNumberChange}
                      defaultCountry="FR"
                      className={
                        this.state.errors.phoneNumber &&
                        this.state.errors.phoneNumber !== ""
                          ? "PhoneInputError"
                          : ""
                      }
                    />
                    {this.state.errors.phoneNumber &&
                      this.state.errors.phoneNumber !== "" && (
                        <p className="error-label">
                          {this.state.errors.phoneNumber}
                        </p>
                      )}
                  </div>
                </div>
                {/* [MAGE.ALERT.WA.1 - 1.8] */}
                {this.state.errors.formError && this.state.errors.formError && (
                  <div className="form-error-container">
                    <p className="form-error-label">
                      {this.state.errors.formError}
                    </p>
                  </div>
                )}
                <div>
                  <p className="requireLabel">
                    <span className="required-tag">*</span>{" "}
                    {translate("REQUIRE_LABEL")}
                  </p>
                  <button type="submit" className="btnMain">
                    {translate("BUTTON_NEXT_LABEL")}
                  </button>
                </div>
              </form>
            </div>
          </>
        )}
        {device === "phone/tablet" && (
          <>
            <div className="container-min">
              <p className="title-min">
                {translate("IDENTITY_COMPONENT_TITLE")}
              </p>
              <form onSubmit={this.handleSubmit}>
                <div className="fieldContainer">
                  <div className="input-container">
                    <div>
                      <p className="label">
                        {translate("GENDER_FORM_LABEL")}
                        <span className="required-tag">*</span>
                      </p>
                      <div>
                        <label
                          className={
                            this.state.isMan === true
                              ? "radio-field-checked"
                              : "radio-field"
                          }
                        >
                          <Field
                            name="gender"
                            component="input"
                            type="radio"
                            className="radioBtn"
                            value="M"
                            checked={this.state.isMan === true}
                            onChange={this.handleGenderChange}
                          />{" "}
                          {translate("GENDER_MALE_LABEL")}
                        </label>
                      </div>
                      <div>
                        <label
                          className={
                            this.state.isMan === false
                              ? "radio-field-checked"
                              : "radio-field"
                          }
                        >
                          <Field
                            name="gender"
                            component="input"
                            type="radio"
                            className="radioBtn"
                            value="F"
                            checked={this.state.isMan === false}
                            onChange={this.handleGenderChange}
                          />{" "}
                          {translate("GENDER_FEMALE_LABEL")}
                        </label>
                      </div>
                      {this.state.errors.gender &&
                        this.state.errors.gender !== "" && (
                          <p className="error-label">
                            {this.state.errors.gender}
                          </p>
                        )}
                    </div>
                  </div>
                  {/* [MAGE.COM.WA.20 - 1.8] */}
                  <div className="input-container">
                    <div>
                      <p className="label">
                        {translate("FIRSTNAME_LABEL")}
                        <span className="required-tag">*</span>
                      </p>
                      <div>
                        <input
                          type="text"
                          className={
                            this.state.errors.firstName &&
                            this.state.errors.firstName !== ""
                              ? "input-large-error"
                              : "input-large"
                          }
                          placeholder={translate("FIRSTNAME_LABEL")}
                          maxLength="20"
                          value={this.state.firstName}
                          onChange={this.handleFirstNameChange}
                        />
                        <Field
                          name="firstName"
                          component="input"
                          type="hidden"
                          value={this.state.firstName}
                        />
                        {this.state.errors.firstName &&
                          this.state.errors.firstName !== "" && (
                            <p className="error-label">
                              {this.state.errors.firstName}
                            </p>
                          )}
                      </div>
                    </div>
                  </div>
                  {/* [MAGE.COM.WA.18 - 1.8], [MAGE.COM.WA.19 - 1.8] */}
                  <div className="input-container">
                    <p className="label">
                      {this.state.isMan
                        ? translate("LASTNAME_MALE_LABEL")
                        : translate("LASTNAME_FEMALE_LABEL")}
                      <span className="required-tag">*</span>
                    </p>
                    <div>
                      <input
                        type="text"
                        className={
                          this.state.errors.lastName &&
                          this.state.errors.lastName !== ""
                            ? "input-large-error"
                            : "input-large"
                        }
                        placeholder={translate("LASTNAME_LABEL")}
                        maxLength="30"
                        value={this.state.lastName}
                        onChange={this.handleLastNameChange}
                      />
                      <Field
                        name="lastName"
                        component="input"
                        type="hidden"
                        value={this.state.lastName}
                      />
                      {this.state.errors.lastName &&
                        this.state.errors.lastName !== "" && (
                          <p className="error-label">
                            {this.state.errors.lastName}
                          </p>
                        )}
                    </div>
                  </div>
                  <div className="input-container">
                    <p className="label">
                      {this.state.isMan
                        ? translate("BIRTHNAME_MALE_LABEL")
                        : translate("BIRTHNAME_FEMALE_LABEL")}
                    </p>
                    <div>
                      <input
                        type="text"
                        className={
                          this.state.errors.birthName &&
                          this.state.errors.birthName !== ""
                            ? "input-large-error"
                            : "input-large"
                        }
                        placeholder={translate("BIRTHNAME_MALE_LABEL")}
                        maxLength="30"
                        value={this.state.birthName}
                        onChange={this.handleBirthNameChange}
                      />
                      <Field
                        name="birthName"
                        component="input"
                        type="hidden"
                        value={this.state.birthName}
                      />
                      {this.state.errors.birthName &&
                        this.state.errors.birthName !== "" && (
                          <p className="error-label">
                            {this.state.errors.birthName}
                          </p>
                        )}
                    </div>
                  </div>
                  <div className="row-input">
                    <div className="zone-input">
                      <p className="label">
                        {translate("BIRTHDAY_LABEL")}
                        <span className="required-tag">*</span>
                      </p>
                      <BirthDateComponent
                        tag={Field}
                        translate={translate}
                        dayName="dtDay"
                        dayValue={this.state.dtDay}
                        dayOnChange={this.handleDtDayChange}
                        dayOnError={this.state.errors.dtDay}
                        monthName="dtMonth"
                        monthValue={this.state.dtMonth}
                        monthOnChange={this.handleDtMonthChange}
                        monthOnError={this.state.errors.dtMonth}
                        yearName="dtYear"
                        yearValue={this.state.dtYear}
                        yearOnChange={this.handleDtYearChange}
                        yearOnError={this.state.errors.dtYear}
                        onFieldError={this.state.errors.birthDate}
                      />
                    </div>
                  </div>
                  {/* [MAGE.COM.WA.22 - 1.8, 1.13, 1.17] */}
                  <div className="input-container">
                    <p className="label">{translate("BIRTHPLACE_LABEL")}</p>
                    <div>
                      <Field
                        name="birthPlace"
                        component="input"
                        type="text"
                        placeholder={translate("BIRTHPLACE_PLACEHOLDER_LABEL")}
                        className={
                          this.state.errors.birthPlace &&
                          this.state.errors.birthPlace !== ""
                            ? "input-large-error"
                            : "input-large"
                        }
                        maxLength="30"
                        value={this.state.birthPlace}
                        onChange={this.handleBirthPlaceChange}
                      />
                      {this.state.errors.birthPlace &&
                        this.state.errors.birthPlace !== "" && (
                          <p className="error-label">
                            {this.state.errors.birthPlace}
                          </p>
                        )}
                    </div>
                  </div>
                  {/* [MAGE.COM.WA.26 - 1.8] */}
                  <div className="input-container">
                    <p className="label">{translate("NATIONALITY_LABEL")}</p>
                    <Field
                      name="nationality"
                      component="select"
                      value={this.state.nationality}
                      className={
                        this.state.errors.nationality &&
                        this.state.errors.nationality !== ""
                          ? "input-large-error"
                          : "input-large"
                      }
                      onChange={this.handleNationalityChange}
                    >
                      {[
                        <option value="">
                          {translate("NATIONALITY_CHOOSE_LABEL")}
                        </option>,
                        countryList.map((cty) => {
                          return <option value={cty.value}>{cty.label}</option>;
                        }),
                      ]}
                    </Field>
                    {this.state.errors.nationality &&
                      this.state.errors.nationality !== "" && (
                        <p className="error-label">
                          {this.state.errors.nationality}
                        </p>
                      )}
                  </div>
                  {/* [MAGE.COM.WA.27 - 1.8] */}
                  <div className="input-container">
                    <div>
                      <p className="label">
                        {translate("QUESTION_SECU_LABEL")}
                        <span className="required-tag">*</span>
                      </p>
                      <div>
                        <label
                          className={
                            this.state.hasNir === true
                              ? "radio-field-checked"
                              : "radio-field"
                          }
                        >
                          <Field
                            name="hasNir"
                            component="input"
                            type="radio"
                            className="radioBtn"
                            value={true}
                            checked={this.state.hasNir === true}
                            onChange={this.handleSocialSecurityChange}
                          />{" "}
                          {translate("RESPONSE_YES_TO_SECU_LABEL")}
                        </label>
                      </div>
                      <div>
                        <label
                          className={
                            this.state.hasNir === false
                              ? "radio-field-checked"
                              : "radio-field"
                          }
                        >
                          <Field
                            name="hasNir"
                            component="input"
                            type="radio"
                            className="radioBtn"
                            value={false}
                            checked={this.state.hasNir === false}
                            onChange={this.handleSocialSecurityChange}
                          />{" "}
                          {translate("RESPONSE_NO_TO_SECU_LABEL")}
                        </label>
                      </div>
                      {this.state.errors.hasNir &&
                        this.state.errors.hasNir !== "" && (
                          <p className="error-label">
                            {this.state.errors.hasNir}
                          </p>
                        )}
                    </div>
                  </div>
                  {this.state.hasNir === true && (
                    <div className="row-input">
                      <div className="zone-input">
                        <p className="label">
                          {translate("SECU_NUMBER_LABEL")}
                          <span className="required-tag">*</span>
                        </p>
                        <div className="input-with-icon">
                          <Field
                            name="nir"
                            component="input"
                            type="text"
                            className={
                              this.state.errors.nir &&
                              this.state.errors.nir !== ""
                                ? "input-large-error"
                                : "input-large"
                            }
                            placeholder={translate(
                              "SECU_NUMBER_PLACEHOLDER_LABEL"
                            )}
                            maxLength="15"
                            value={this.state.nir}
                            onChange={this.handleSocialSecurityNumberChange}
                          />
                          <img
                            className="icon-help"
                            alt="ihelp"
                            src={help}
                            srcSet={`${help2x} 768w, ${help3x} 1280w`}
                            onClick={() =>
                              this.openModalHandler({
                                title: translate("SECURITY_SOCIAL_IMAGE_LABEL"),
                                image: vitaleCard,
                              })
                            }
                          />
                        </div>
                        {/* [MAGE.ALERT.WA.11 - 1.8] */}
                        {this.state.errors.nir &&
                          this.state.errors.nir !== "" && (
                            <p className="error-label">
                              {this.state.errors.nir}
                            </p>
                          )}
                      </div>
                    </div>
                  )}
                  <div className="input-container">
                    <p className="label">
                      {translate("EMAIL_ADDRESS_LABEL")}
                      <span className="required-tag">*</span>
                    </p>
                    <Field
                      name="email"
                      component="input"
                      type="email"
                      className={
                        this.state.errors.email &&
                        this.state.errors.email !== ""
                          ? "input-large-error"
                          : "input-large"
                      }
                      placeholder={translate("EMAIL_ADDRESS_PLACEHOLDER_LABEL")}
                      maxLength="40"
                      value={userEmail}
                      onChange={this.handleEmailChange}
                      disabled={userEmail !== "" ? "disabled" : ""}
                    />
                    {this.state.errors.email &&
                      this.state.errors.email !== "" && (
                        <p className="error-label">{this.state.errors.email}</p>
                      )}
                  </div>
                  <div className="input-container">
                    <p className="label">
                      {translate("PHONE_NUMBER_LABEL")}
                      <span className="required-tag">*</span>
                    </p>
                    <PhoneInput
                      placeholder="00 00 00 00 00"
                      value={this.state.phoneNumber}
                      onChange={this.handlePhoneNumberChange}
                      defaultCountry="FR"
                      className={
                        this.state.errors.phoneNumber &&
                        this.state.errors.phoneNumber !== ""
                          ? "PhoneInputError"
                          : ""
                      }
                    />
                    {this.state.errors.phoneNumber &&
                      this.state.errors.phoneNumber !== "" && (
                        <p className="error-label">
                          {this.state.errors.phoneNumber}
                        </p>
                      )}
                  </div>
                </div>
                {/* [MAGE.ALERT.WA.1 - 1.8] */}
                {this.state.errors.formError && this.state.errors.formError && (
                  <div className="form-error-container">
                    <p className="form-error-label">
                      {this.state.errors.formError}
                    </p>
                  </div>
                )}
                <div>
                  <p className="requireLabel">
                    <span className="required-tag">*</span>{" "}
                    {translate("REQUIRE_LABEL")}
                  </p>
                  <button type="submit" className="btnMain">
                    {translate("BUTTON_NEXT_LABEL")}
                  </button>
                </div>
              </form>
            </div>
          </>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    forms: state.form,
    comingEmail: state.email,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        change,
      },
      dispatch
    ),
  };
}

export default reduxForm({
  form: "wizard",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: false,
  validateFirstStep,
})(connect(mapStateToProps, mapDispatchToProps)(Identity));
