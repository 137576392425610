import React, { useState } from 'react';
import ResultDetails from '../../components/LostResultForm/ResultDetails';
import PropTypes from 'prop-types';
import Header from '../../components/HeaderComponent';
import LeftCard from '../../components/LeftCardComponent';
import Responsive from '../../components/ResponsiveComponent';
import document from '../../assets/document/group-10.png';
import document2x from '../../assets/document/group-10@2x.png';
import document3x from '../../assets/document/group-10@3x.png';
import '../../styles/identity.scss';
import '../../styles/forms_style.scss';

const ThirdStep = (props) => {

    const { onSubmit, translate, onChangeLanguage, device } = props
    return (
        <Responsive
            webView={<>
                <Header translate={translate} isForm={true} device="lap/desk" />
                <div className="row">
                    <LeftCard
                        stepTitle={translate("RESULT_DETAIL_TITLE_LABEL")}
                        bottomImage={<img src={document}
                            srcSet={document2x, document3x}
                            alt="Img Document"
                            className="Group-10" />}
                        websiteLink={translate("WEBSITE_TEXT")}
                    />
                    <div className="zone scrollable-zone">
                        <ResultDetails onSubmit={onSubmit} device="lap/desk" translate={translate} onChangeLanguage={onChangeLanguage} />
                    </div>
                </div>
            </>}
            mobileView={<>
                <Header translate={translate} isForm={false} device="phone/tablet" title={translate("RESULT_DETAIL_TITLE_LABEL")} />
                <div className="row">
                    <div className="zone scrollable-zone">
                        <div className="header-title-container">
                            <p className="header-title">{translate("RESULT_DETAIL_TITLE_LABEL")}</p>
                        </div>
                        <ResultDetails onSubmit={onSubmit} device="phone/tablet" translate={translate} onChangeLanguage={onChangeLanguage} />
                    </div>
                </div>
            </>}
        />
    );
}

ThirdStep.propTypes = {
    onSubmit: PropTypes.func.isRequired
}

export default ThirdStep;