// [MAGE.COM.WA.46 - 1.12]
//Identity Form Step 4 validation rules
//Used to validate form before move to next form
export function validationRules(values, translate) {

  const errors = {};

  /* 
    [MAGE.COM.WA.43 - 1.12] [MAGE.COM.WA.44 - 1.12]
    Test Reason validation
  */
  if (!values.hasBeenInContactWithCovidPerson && !values.screeningForMySelf &&
      !values.professionalNeeded && !values.willGoingToInstitution && !values.travelByPlane ) {
    errors.reasonTest = translate("QUESTIONS_REQUIRED_FIELD")
  } else {

    /**
     * Flight number verification
     */
    if (values.travelByPlane) {
      if (values.flightNumber.trim().length > 6) {
        errors.flightNumber = "Max 6 ch.";
      } else if (values.flightNumber.trim() !== "" && !/^([A-Z]{2}|[A-Z]\d|\d[A-Z])[1-9](\d{1,3})?$/.test(values.flightNumber.trim())) {
        errors.flightNumber = translate("INVALID_FLIGHT_NUMBER");
      }
    }
  }

  if (Object.keys(errors).length !== 0) {
    // [MAGE.ALERT.WA.9 - 1.12]
    errors.formError = translate("FORM_ERROR");
  }

  return errors
}

const validateFourthStep = values => {
  return validationRules(values);
}

export default validateFourthStep;