import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import CustomLink from '../CustomLink';

/**
 * This component is using to show user tests list
 * [MAGE.COM.WA.71 - 1.19]
 * @component
 */
class ResultList extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        const { onSubmit, translate, device } = this.props;

        return (
            <div>
                { device === "lap/desk" && <>
                    <p className="title">{translate("FOUND_RESULT_LABEL")}</p>
                    <div className="container">
                        <CustomLink className="testBtn" tag="div" onClick={onSubmit}>
                            <p className="whoBtnContent btn-title-left">Test XXXX</p>
                            <p className="whoBtnContent btn-sub-title-left">réalisé le 13/11/2020 à 13h25</p>
                        </CustomLink>
                    </div>
                </>}
                { device === "phone/tablet" && <>
                    <div className="container-min">
                        <p className="title-min">{translate("FOUND_RESULT_LABEL")}</p>
                        <CustomLink className="testBtn-min" tag="div" onClick={onSubmit}>
                            <p className="whoBtnContent btn-title-left">Test XXXX</p>
                            <p className="whoBtnContent btn-sub-title-left">réalisé le 13/11/2020 à 13h25</p>
                        </CustomLink>
                    </div>
                </>}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        forms: state.form
    }
}

export default reduxForm({
    form: 'wizardLost',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false
})(connect(
    mapStateToProps,
    null
)(ResultList))
