import React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import BeforeUnloadComponent from 'react-beforeunload-component';
import FirstStep from './step1';
import SecondStep from './step2';
import ThirdStep from './step3';
import FourthStep from './step4';
import FifthStep from './step5';
import SixthStep from './step6';
import { goToError } from '../../utils/redirect';

class IdentityWizard extends React.Component {

  constructor(props) {
    super(props);

    this.state = (props.location["state"] !== undefined) ? {
      page: 1,
      patientID: "",
      userEmail: props.location.state.email
    } : {
        page: 1,
        patientID: "",
        userEmail: ""
      };

    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
  }

  componentDidMount(){
    //Redirect user if he type directly route in url
    goToError(this.props);
  }

  nextPage = values => {
    console.log(values);
    if (values != undefined) {
      this.setState({ patientID: values })
    }
    this.setState({ page: this.state.page + 1 })
  }

  previousPage = values => {
    console.log(values);
    if (values != undefined) {
      this.setState({ page: values });
      return;
    }
    this.setState({ page: this.state.page - 1 })
  }

  render() {
    const { onSubmit, translate, onChangeLanguage } = this.props
    const { page } = this.state
    return (
      <div>
        <BeforeUnloadComponent
          blockRoute={true}
        />
        {page === 1 && <FirstStep onSubmit={this.nextPage} translate={translate} onChangeLanguage={onChangeLanguage} userEmail={this.state.userEmail} />}
        {page === 2 && <SecondStep previousPage={this.previousPage} onSubmit={this.nextPage} translate={translate} onChangeLanguage={onChangeLanguage} />}
        {page === 3 && <ThirdStep previousPage={this.previousPage} onSubmit={this.nextPage} translate={translate} onChangeLanguage={onChangeLanguage} />}
        {page === 4 && <FourthStep previousPage={this.previousPage} onSubmit={this.nextPage} translate={translate} onChangeLanguage={onChangeLanguage} />}
        {page === 5 && <FifthStep previousPage={this.previousPage} onSubmit={this.nextPage} translate={translate} onChangeLanguage={onChangeLanguage} />}
        {page === 6 && <SixthStep previousPage={this.previousPage} onSubmit={onSubmit} translate={translate} onChangeLanguage={onChangeLanguage} patientID={this.state.patientID} />}
      </div>
    )
  }

}

IdentityWizard.propTypes = {
  onSubmit: PropTypes.func.isRequired
}

IdentityWizard = reduxForm({
  form: 'wizard',
})(IdentityWizard)

export default connect((state, props) => (
  console.log("Has state: " + props.location["state"]),
  {
    initialValues: (props.location["state"] !== undefined) ? {
      email: props.location.state.email,
      accommodationCountry: "FR",
      accommodationType: "I"
    } : {
        email: ""
      }
  }))(IdentityWizard);