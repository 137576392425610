import React from 'react';
import '../styles/menu_style.scss';
import CustomLink from './CustomLink';
import homeIcon3x from '../assets/home-icon/stroke-1@3x.png';
import emailIcon3x from '../assets/email-icon/group-4@3x.png';
import shareIcon3x from '../assets/share-icon/group-5@3x.png';
import magentineIcon3x from '../assets/magentine-icon/group@3x.png';
import tousCovidIcon3x from '../assets/touscovid-icon/group-11@3x.png';

/**
 * This component is using to allow user to navigate in the application
 * [MAGE.COM.WA.4 - 1.16] to [MAGE.COM.WA.8 - 1.16] and [MAGE.COM.WA.10 - 1.16] and [MAGE.COM.WA.94 - TOUS]
 * @component
 */
class Menu extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isCopied: false
        }

        this.copyToCliboard = this.copyToCliboard.bind(this);
    }

    copyToCliboard(e) {
        navigator.clipboard.writeText(this.props.translate("WEBSITE_URL"));
        this.setState({ isCopied: true });
        setTimeout(function () {
            this.setState({ isCopied: false });
        }.bind(this), 5000);
    }


    render() {
        const { device, translate, onClose } = this.props;
        return (
            <span>
                { device === "lap/desk" &&
                    <div className="menu-container">
                        <div className="menu-close"><span className="menu-close-btn" onClick={onClose}></span></div>
                        <div className="menu-scrollable-zone">
                            <div className="menu-content">
                                <CustomLink className="menu-row-input" tag="div" to="/">
                                    <div className="menu-icon-zone">
                                        <img className="menu-icon-large" src={homeIcon3x} />
                                    </div>
                                    <div className="menu-text-zone">
                                        <p className="menu-home-text">{translate("MENU_HOME_TEXT")}</p>
                                    </div>
                                </CustomLink>
                                <div className="menu-separator"></div>
                                {/**
                                * [MAGE.COM.WA.10 - 1.16]
                                */}
                                <CustomLink className="menu-row-input" tag="div" to="/contact-us">
                                    <div className="menu-icon-zone">
                                        <img className="menu-icon-large" src={emailIcon3x} />
                                    </div>
                                    <div className="menu-text-zone">
                                        <p className="menu-other-text">{translate("MENU_CONTACT_US_TEXT")}</p>
                                    </div>
                                </CustomLink>
                                {/**
                                 * [MAGE.COM.WA.5 - 1.16]
                                 */}
                                <CustomLink className="menu-row-input" tag="div" onClick={this.copyToCliboard}>
                                    <div className="menu-icon-zone">
                                        <img className="menu-icon" src={shareIcon3x} />
                                    </div>
                                    <div className="menu-text-zone">
                                        <p className="menu-other-text">{translate("MENU_SHARE_TEXT")}</p>
                                    </div>
                                </CustomLink>
                                {this.state.isCopied && <div className="copied-box">{translate("URL_COPIED")}</div>}
                                {/**
                                 * [MAGE.COM.WA.6 - 1.16]
                                 */}
                                <a className="menu-row-input" href={translate("MAGENTINE_HEALTH_CARE_URL")} target="_blank">
                                    <div className="menu-icon-zone">
                                        <img className="menu-icon" src={magentineIcon3x} />
                                    </div>
                                    <div className="menu-text-zone">
                                        <p className="menu-other-text">{translate("MENU_VISIT_MAGENTINE_TEXT")}</p>
                                    </div>
                                </a>
                                <div className="menu-separator"></div>
                                <a className="menu-row-input" href={translate("TOUS_ANTI_COVID_URL")} target="_blank">
                                    <div className="menu-icon-zone">
                                        <img className="menu-icon" src={tousCovidIcon3x} />
                                    </div>
                                    <div className="menu-text-zone">
                                        <p className="menu-other-text">{translate("MENU_GO_TO_TOUS_COVID_APP_TEXT")}</p>
                                    </div>
                                </a>
                            </div>
                            <div className="menu-footer">
                                {/**
                                 * [MAGE.COM.WA.7 - 1.16]
                                 */}
                                <CustomLink className="menu-footer-text" tag="a" to="/legal-informations-policy">
                                    {translate("FOOTER_MENTIONS_LEGAL_TEXT")}
                                </CustomLink>
                                {/**
                                * [MAGE.COM.WA.8 - 1.16]
                                */}
                                <CustomLink className="menu-footer-text" tag="a" to="/data-policy">
                                    {translate("FOOTER_DATA_POLICY_TEXT")}
                                </CustomLink>
                                <CustomLink className="menu-footer-text" tag="a" to="/cookies-policy">
                                    {translate("FOOTER_COOKIES_TEXT")}
                                </CustomLink>
                            </div>
                        </div>
                    </div>
                }
                { device === "phone/tablet" &&
                    <div className="menu-container">
                        <div className="menu-close-language-container">
                            <div className="menu-close"><span className="menu-close-btn" onClick={onClose}></span></div>
                        </div>
                        <div className="menu-scrollable-zone">
                            <div className="menu-content">
                                <CustomLink className="menu-row-input" tag="div" to="/">
                                    <div className="menu-icon-zone">
                                        <img className="menu-icon-large" src={homeIcon3x} />
                                    </div>
                                    <div className="menu-text-zone">
                                        <p className="menu-home-text">{translate("MENU_HOME_TEXT")}</p>
                                    </div>
                                </CustomLink>
                                <div className="menu-separator"></div>
                                {/**
                                * [MAGE.COM.WA.10 - 1.16]
                                */}
                                <CustomLink className="menu-row-input" tag="div" to="/contact-us">
                                    <div className="menu-icon-zone">
                                        <img className="menu-icon-large" src={emailIcon3x} />
                                    </div>
                                    <div className="menu-text-zone">
                                        <p className="menu-other-text">{translate("MENU_CONTACT_US_TEXT")}</p>
                                    </div>
                                </CustomLink>
                                {/**
                                 * [MAGE.COM.WA.5 - 1.16]
                                 */}
                                <CustomLink className="menu-row-input" tag="div" onClick={this.copyToCliboard}>
                                    <div className="menu-icon-zone">
                                        <img className="menu-icon" src={shareIcon3x} />
                                    </div>
                                    <div className="menu-text-zone">
                                        <p className="menu-other-text">{translate("MENU_SHARE_TEXT")}</p>
                                    </div>
                                </CustomLink>
                                {this.state.isCopied && <div className="copied-box">{translate("URL_COPIED")}</div>}
                                {/**
                                 * [MAGE.COM.WA.6 - 1.16]
                                 */}
                                <a className="menu-row-input" href={translate("MAGENTINE_HEALTH_CARE_URL")} target="_blank">
                                    <div className="menu-icon-zone">
                                        <img className="menu-icon" src={magentineIcon3x} />
                                    </div>
                                    <div className="menu-text-zone">
                                        <p className="menu-other-text">{translate("MENU_VISIT_MAGENTINE_TEXT")}</p>
                                    </div>
                                </a>
                                <div className="menu-separator"></div>
                                <a className="menu-row-input" href={translate("TOUS_ANTI_COVID_URL")} target="_blank">
                                    <div className="menu-icon-zone">
                                        <img className="menu-icon" src={tousCovidIcon3x} />
                                    </div>
                                    <div className="menu-text-zone">
                                        <p className="menu-other-text">{translate("MENU_GO_TO_TOUS_COVID_APP_TEXT")}</p>
                                    </div>
                                </a>
                            </div>
                            <div className="menu-footer">
                                {/**
                                 * [MAGE.COM.WA.7 - 1.16]
                                 */}
                                <CustomLink className="menu-footer-text" tag="a" to="/legal-informations-policy">
                                    {translate("FOOTER_MENTIONS_LEGAL_TEXT")}
                                </CustomLink>
                                {/**
                                * [MAGE.COM.WA.8 - 1.16]
                                */}
                                <CustomLink className="menu-footer-text" tag="a" to="/data-policy">
                                    {translate("FOOTER_DATA_POLICY_TEXT")}
                                </CustomLink>
                                <CustomLink className="menu-footer-text" tag="a" to="/cookies-policy">
                                    {translate("FOOTER_COOKIES_TEXT")}
                                </CustomLink>
                            </div>
                        </div>
                    </div>
                }
            </span>
        );
    }
}

export default Menu