import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import CustomLink from '../CustomLink';

/**
 * This component is using to show user test selected details
 * [MAGE.COM.WA.72 - 1.20] and [MAGE.COM.WA.74 - 1.20]
 * @component
 */
class ResultDetails extends React.Component {
    constructor(props) {
        super(props);
    }

    /**
     * [MAGE.COM.WA.74 - 1.20]
     * 
     * TODO
     */

    render() {

        const { translate, device } = this.props;

        return (
            <div>
                { device === "lap/desk" && <>
                    <p className="title">{translate("RESULT_DETAILS_LABEL")}</p>
                    <div className="container">
                        <div className="test-info">

                        </div>
                        <div className="contact-zone">
                            <p className="normal-text">{translate("RESULT_OTHERS_QUESTIONS")}<br />
                                {/* [MAGE.COM.WA.10 - 1.20] */}
                                <CustomLink className="magentine-link" tag="a" to={{
                                    pathname: "/contact-us",
                                }}>{translate("CONTACT_US")}</CustomLink></p>
                        </div>
                        <div className="buttons-zone">
                            {/* [MAGE.COM.WA.72 - 1.20] */}
                            <CustomLink className="btnMain" tag="button" to={{
                                pathname: "/",
                            }}>{translate("BUTTON_END_LABEL")}</CustomLink>
                        </div>
                    </div>

                </>}
                { device === "phone/tablet" && <>
                    <div className="container-min">
                        <p className="title-min">{translate("RESULT_DETAILS_LABEL")}</p>
                        <div className="test-info">

                        </div>
                        <div className="contact-zone">
                            <p className="normal-text">{translate("RESULT_OTHERS_QUESTIONS")}<br />
                                {/* [MAGE.COM.WA.10 - 1.20] */}
                                <CustomLink className="magentine-link" tag="a" to={{
                                    pathname: "/contact-us",
                                }}>{translate("CONTACT_US")}</CustomLink></p>
                        </div>
                        <div className="buttons-zone">
                            <div className="center-end-button">
                                {/* [MAGE.COM.WA.72 - 1.20] */}
                                <CustomLink className="btnMain" tag="button" to={{
                                    pathname: "/",
                                }}>{translate("BUTTON_END_LABEL")}</CustomLink>
                            </div>
                        </div>
                    </div>
                </>}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        forms: state.form
    }
}

export default reduxForm({
    form: 'wizardLost',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false
})(connect(
    mapStateToProps,
    null
)(ResultDetails))
