import React from 'react';
import parse from 'html-react-parser';

const Data = (props) => {

    const { device, translate } = props;
    return (
        <span>
            { device === "lap/desk" && <>
                <div className="content-container">
                    <p className="title">{translate("DATAS_POLICY_FOREWORD_TITLE")}</p>
                    <div className="text-container">{translate("DATAS_POLICY_FOREWORD_TEXT")}</div>
                    <div className="separator"></div>
                    <p className="title">{translate("DATAS_POLICY_DEFINITIONS_TITLE")}</p>
                    <div className="text-container">{translate("DATAS_POLICY_DEFINITIONS_TEXT")}</div>
                    <div className="separator"></div>
                    <p className="title">{translate("DATAS_POLICY_SUBJECT_TITLE")}</p>
                    <div className="text-container">{translate("DATAS_POLICY_SUBJECT_TEXT")}</div>
                    <div className="separator"></div>
                    <p className="title">{translate("DATAS_POLICY_ORIGIN_TITLE")}</p>
                    <div className="text-container">{translate("DATAS_POLICY_ORIGIN_TEXT")}</div>
                    <div className="separator"></div>
                    <p className="title">{translate("DATAS_POLICY_IDENTITY_TITLE")}</p>
                    <div className="text-container">{translate("DATAS_POLICY_IDENTITY_TEXT")}</div>
                    <div className="separator"></div>
                    <p className="title">{translate("DATAS_POLICY_FINALITY_AND_PROCESSING_TITLE")}</p>
                    <div className="text-container">{translate("DATAS_POLICY_FINALITY_AND_PROCESSING_TEXT")}</div>
                    <div className="separator"></div>
                    <p className="title">{translate("DATAS_POLICY_RECIPIENT_TITLE")}</p>
                    <div className="text-container">{parse(translate("DATAS_POLICY_RECIPIENT_TEXT"))}</div>
                    <div className="separator"></div>
                    <p className="title">{translate("DATAS_POLICY_USERS_RIGHTS_TITLE")}</p>
                    <div className="text-container">{translate("DATAS_POLICY_USERS_RIGHTS_TEXT")}</div>
                    <div className="separator"></div>
                    <p className="title">{translate("DATAS_POLICY_SOCIAL_NETWORK_TITLE")}</p>
                    <div className="text-container">{translate("DATAS_POLICY_SOCIAL_NETWORK_TEXT")}</div>
                    <div className="separator"></div>
                    <p className="title">{translate("DATAS_POLICY_SECURITY_TITLE")}</p>
                    <div className="text-container">{translate("DATAS_POLICY_SECURITY_TEXT")}</div>
                    <div className="separator"></div>
                    <p className="title">{translate("DATAS_POLICY_ADDRESS_TITLE")}</p>
                    <div className="text-container">{translate("DATAS_POLICY_ADDRESS_TEXT")}</div>
                    <div className="separator"></div>
                    <p className="title">{translate("DATAS_POLICY_PERSONAL_INFORMATIONS_TITLE")}</p>
                    <div className="text-container">{translate("DATAS_POLICY_PERSONAL_INFORMATIONS_TEXT")}</div>
                    <div className="separator"></div>
                    <p className="title">{translate("DATAS_POLICY_APPLICATION_CONDITIONS_TITLE")}</p>
                    <div className="text-container">{translate("DATAS_POLICY_APPLICATION_CONDITIONS_TEXT")}</div>
                </div>
            </>}
            { device === "phone/tablet" && <>
                <div className="content-container">
                    <p className="title-min">{translate("DATAS_POLICY_FOREWORD_TITLE")}</p>
                    <div className="text-container">{translate("DATAS_POLICY_FOREWORD_TEXT")}</div>
                    <div className="separator"></div>
                    <p className="title-min">{translate("DATAS_POLICY_DEFINITIONS_TITLE")}</p>
                    <div className="text-container">{translate("DATAS_POLICY_DEFINITIONS_TEXT")}</div>
                    <div className="separator"></div>
                    <p className="title-min">{translate("DATAS_POLICY_SUBJECT_TITLE")}</p>
                    <div className="text-container">{translate("DATAS_POLICY_SUBJECT_TEXT")}</div>
                    <div className="separator"></div>
                    <p className="title-min">{translate("DATAS_POLICY_ORIGIN_TITLE")}</p>
                    <div className="text-container">{translate("DATAS_POLICY_ORIGIN_TEXT")}</div>
                    <div className="separator"></div>
                    <p className="title-min">{translate("DATAS_POLICY_IDENTITY_TITLE")}</p>
                    <div className="text-container">{translate("DATAS_POLICY_IDENTITY_TEXT")}</div>
                    <div className="separator"></div>
                    <p className="title-min">{translate("DATAS_POLICY_FINALITY_AND_PROCESSING_TITLE")}</p>
                    <div className="text-container">{translate("DATAS_POLICY_FINALITY_AND_PROCESSING_TEXT")}</div>
                    <div className="separator"></div>
                    <p className="title-min">{translate("DATAS_POLICY_RECIPIENT_TITLE")}</p>
                    <div className="text-container">{parse(translate("DATAS_POLICY_RECIPIENT_TEXT"))}</div>
                    <div className="separator"></div>
                    <p className="title-min">{translate("DATAS_POLICY_USERS_RIGHTS_TITLE")}</p>
                    <div className="text-container">{translate("DATAS_POLICY_USERS_RIGHTS_TEXT")}</div>
                    <div className="separator"></div>
                    <p className="title-min">{translate("DATAS_POLICY_SOCIAL_NETWORK_TITLE")}</p>
                    <div className="text-container">{translate("DATAS_POLICY_SOCIAL_NETWORK_TEXT")}</div>
                    <div className="separator"></div>
                    <p className="title-min">{translate("DATAS_POLICY_SECURITY_TITLE")}</p>
                    <div className="text-container">{translate("DATAS_POLICY_SECURITY_TEXT")}</div>
                    <div className="separator"></div>
                    <p className="title-min">{translate("DATAS_POLICY_ADDRESS_TITLE")}</p>
                    <div className="text-container">{translate("DATAS_POLICY_ADDRESS_TEXT")}</div>
                    <div className="separator"></div>
                    <p className="title-min">{translate("DATAS_POLICY_PERSONAL_INFORMATIONS_TITLE")}</p>
                    <div className="text-container">{translate("DATAS_POLICY_PERSONAL_INFORMATIONS_TEXT")}</div>
                    <div className="separator"></div>
                    <p className="title-min">{translate("DATAS_POLICY_APPLICATION_CONDITIONS_TITLE")}</p>
                    <div className="text-container">{translate("DATAS_POLICY_APPLICATION_CONDITIONS_TEXT")}</div>
                </div>
            </>}
        </span>
    );
}

export default Data;