//Form validation rules

export function emailValidation(email) {
    if (/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) return true;
    console.log("Your email address is invalid !")
    return false;
}

export function socialSecurityFrenchValidation(socialSecurityNumber) {
    if (/[0-9]+/g.test(socialSecurityNumber)) return true;
    console.log("Invalid security social number !");
    return false;
}

export function charLengthValidation(value, maxLength) {
    if (value.length <= maxLength) return true;
    console.log("Your value is too long !");
    return false;
} 