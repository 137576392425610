import React, { useState } from 'react';
import ResultList from '../../components/LostResultForm/ResultList';
import PropTypes from 'prop-types';
import Header from '../../components/HeaderComponent';
import LeftCard from '../../components/LeftCardComponent';
import Responsive from '../../components/ResponsiveComponent';
import assistant from '../../assets/assistant/group-10.png';
import assistant2x from '../../assets/assistant/group-10@2x.png';
import assistant3x from '../../assets/assistant/group-10@3x.png';
import '../../styles/identity.scss';
import '../../styles/forms_style.scss';

const SecondStep = (props) => {

    const { onSubmit, translate, onChangeLanguage } = props
    return (
        <Responsive
            webView={<>
                <Header translate={translate} isForm={true} device="lap/desk" />
                <div className="row">
                    <LeftCard
                        stepTitleWithDescription={translate("RESULT_TITLE_LABEL")}
                        stepDescription={translate("RESULT_FOUND_SUBTITLE_LABEL")}
                        bottomImage={<img src={assistant}
                            srcSet={assistant2x, assistant3x}
                            alt="Img Doctor"
                            className="Group-10" />}
                        websiteLink={translate("WEBSITE_TEXT")}
                    />
                    <div className="zone scrollable-zone">
                        <ResultList onSubmit={onSubmit} device="lap/desk" translate={translate} onChangeLanguage={onChangeLanguage} />
                    </div>
                </div>
            </>}
            mobileView={<>
                <Header translate={translate} isForm={false} device="phone/tablet" title={translate("RESULT_TITLE_LABEL")} subtitle={translate("RESULT_FOUND_SUBTITLE_LABEL")} />
                <div className="row">
                    <div className="zone scrollable-zone">
                        <div className="header-title-container">
                            <p className="header-title">{translate("RESULT_TITLE_LABEL")}</p>
                            <p className="header-subtitle">{translate("RESULT_FOUND_SUBTITLE_LABEL")}</p>
                        </div>
                        <ResultList onSubmit={onSubmit} device="phone/tablet" translate={translate} onChangeLanguage={onChangeLanguage} />
                    </div>
                </div>
            </>}
        />
    );
}

SecondStep.propTypes = {
    onSubmit: PropTypes.func.isRequired
}

export default SecondStep;