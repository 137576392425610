import React, { useState } from 'react';
import { withRouter } from 'react-router'; 
import * as HttpRequest from '../../utils/HttpRequest';
import config from '../../config/load_config';
import queryString from 'query-string';
import LeftCard from '../../components/LeftCardComponent';
 import Responsive from '../../components/ResponsiveComponent';
 import Header from '../../components/HeaderComponent';
 import imgDoctors from '../../assets/doctors/group-5@3x.png';
import '../../styles/buttons.scss';
import '../../styles/user_type.scss';
import '../../styles/standard_style.scss';
import EmailConfirmationErrorComponent from '../../components/IdentityForm/EmailConfirmationComponent';

const ConfirmationEmail = (props) => {

    const [error, setError] = useState(false);

    const email = queryString.parse(props.location.search).email || "";
    const token = queryString.parse(props.location.search).token || "";

    const toSend = {
        email: email,
        token: token,
    }

    const query = queryString.stringify(toSend);

    //Construct JSON Object to send
    HttpRequest.doGet(process.env.REACT_APP_API_BASE_URL + config.API.EMAIL_CONFIRMATION + "?" + query, {
        "Access-Control-Allow-Origin": "*"
    }).then((response) => {
        console.log(response);
        if (response.error) {
            //Show error page
            setError(true);
        } else {
            //Go to form
            setError(false);
            props.history.push({
                pathname: "/patient-register",
                state: { email: email }
            });
            return;
        }
    });

    
    const { translate, onChangeLanguage } = props

    return (
        <Responsive
            webView={<>
                <Header translate={translate} isForm={true} device="lap/desk" />
                <div className="row">
                    <LeftCard
                        centeredImage={<img src={imgDoctors}
                            alt="Img Doctors"
                            className="leftimage" />}
                    />
                    <div className="zone scrollablezone">
                        <EmailConfirmationErrorComponent translate={translate} device="lap/desk" onChangeLanguage={onChangeLanguage} />

                    </div>
                </div>
            </>}
            mobileView={<>
                <Header translate={translate} isForm={false} device="phone/tablet" />
                <div className="rowmin">
                    <div className="zonemin scrollablezone">
                        <EmailConfirmationErrorComponent translate={translate} device="phone/tablet" onChangeLanguage={onChangeLanguage} />
                    </div>
                </div>
            </>}
        />
    )
}

export default withRouter(ConfirmationEmail);