import React from 'react';
import CustomLink from '../CustomLink';

/**
 * This component is the user selection page
 * [MAGE.COM.WA.12 - 1.5] and [MAGE.COM.WA.13 - 1.5]
 * @component
 */
class User extends React.Component {
    constructor(props) {
        super(props);

        this.userType = "patient";
        
        this.state = {
            user: ""
        };
    }

    render() {

        const { translate, device } = this.props;
        return (
            <span>
                {device === "lap/desk" && <>
                    <div className="row-input">
                        <span className="content">
                            <p className="title">{translate("FOR_USER_TYPE_LABEL")}</p>
                            <div className="row-input">
                                <div className="zone-input">
                                    {/* [MAGE.COM.WA.12 - 1.5] */}
                                    <CustomLink className="whoBtn center-btn-title" tag="div" to={{
                                        pathname: "/email-verification",
                                        state: {userType: "self"}
                                    }}>
                                        <p className="whoBtnContent btn-title">{translate("FOR_USER_TYPE_SELF_LABEL")}</p>
                                    </CustomLink>
                                </div>
                                <div className="zone-input">
                                    {/* [MAGE.COM.WA.13 - 1.5] */}
                                    <CustomLink className="whoBtn center-btn-title" tag="div" to={{
                                        pathname: "/email-verification",
                                        state: {userType: "other"}
                                    }}>
                                        <p className="whoBtnContent btn-title">{translate("FOR_USER_TYPE_OTHER_LABEL")}</p>
                                    </CustomLink>
                                </div>
                            </div>
                        </span>
                    </div></>}
                {device === "phone/tablet" && <>
                    <div className="row-input-min">
                        <div className="content-min">
                            <p className="title-min">{translate("FOR_USER_TYPE_LABEL")}</p>
                            {/* [MAGE.COM.WA.12 - 1.5] */}
                            <div className="container-min">
                                <CustomLink className="whoBtn-min center-btn-title" tag="div" to={{
                                    pathname: "/email-verification",
                                    state: {userType: "self"}
                                }}>
                                    <p className="whoBtnContent btn-title">{translate("FOR_USER_TYPE_SELF_LABEL")}</p>
                                </CustomLink>
                                {/* [MAGE.COM.WA.13 - 1.5] */}
                                <CustomLink className="whoBtn-min center-btn-title" tag="div" to={{
                                    pathname: "/email-verification",
                                    state: {userType: "other"}
                                }}>
                                    <p className="whoBtnContent btn-title">{translate("FOR_USER_TYPE_OTHER_LABEL")}</p>
                                </CustomLink>
                            </div>
                            <p className="test-rapide-link">{translate("WEBSITE_TEXT")}</p>
                        </div>
                    </div></>}
            </span>
        );
    }
}

export default User;