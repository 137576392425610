import React from 'react';
import GenericCheckbox from '../GenericCheckboxComponent';

/**
 * This component is using to manage Motivation component with one text field
 * @component
 */

const MotivationWithTextField = ({ tag: Tag, translate, name, value, onChange, subfieldName, subfieldValue, subfieldChange, subfieldPlaceholder, errorData, ...rest }) => {

    return (<>
        <GenericCheckbox tag={Tag} translate={translate} name={name} value={value} onChange={onChange} />
        {
        value && <div className="input-container">
            <Tag name={subfieldName} component="input"
                className={errorData && errorData != "" ? "input-large-error" : "input-large"}
                type="text" placeholder={subfieldPlaceholder} value={subfieldValue}
                onChange={subfieldChange} />
            {errorData && errorData != "" &&
                <p className="error-label">{errorData}</p>}
        </div>
        }
    </>);
}

export default MotivationWithTextField;