import React from 'react';

class LeftCard extends React.Component {

    render() {
        const { stepTitle, stepTitleWithDescription, stepDescription, stepSubTitle, stepImage, bottomImage, websiteLink, centeredImage } = this.props;
        return (
            <div className="zone">
                {!centeredImage && 
                    <div className="left-card">
                        <div className="step-card">
                            {stepTitle && <p className="step-title">{stepTitle}</p>}
                            {stepTitleWithDescription && <p className="step-title-with-description">{stepTitleWithDescription}</p>}
                            {stepDescription && <p className="step-title-description">{stepDescription}</p>}
                            {stepImage}
                            {stepSubTitle && <p className="step-sub-title">{stepSubTitle}</p>}
                        </div>
                        <div className="image-card">
                            {bottomImage}
                            <p className="website-text">{websiteLink}</p>
                        </div>
                    </div>
                }
                {centeredImage && 
                    <div className="left-image-container">
                        {centeredImage}
                    </div>
                }
            </div>
        );
    }
}

export default LeftCard;