import React from 'react';
const LegalInformations = (props) => {

    const { device, translate } = props;
    return (
        <span>
            { device === "lap/desk" && <>
                <div className="content-container">
                    <p className="title">{translate("LEGAL_INFORMATIONS_PRESENTATION_TITLE")}</p>
                    <div className="text-container">{translate("LEGAL_INFORMATIONS_PRESENTATION_TEXT")}</div>
                    <div className="separator"></div>
                    <p className="title">{translate("LEGAL_INFORMATIONS_CONDITIONS_TITLE")}</p>
                    <div className="text-container">{translate("LEGAL_INFORMATIONS_CONDITIONS_TEXT")}</div>
                    <div className="separator"></div>
                    <p className="title">{translate("LEGAL_INFORMATIONS_SERVICES_DESCRIPTION_TITLE")}</p>
                    <div className="text-container">{translate("LEGAL_INFORMATIONS_SERVICES_DESCRIPTION_TEXT")}</div>
                    <div className="separator"></div>
                    <p className="title">{translate("LEGAL_INFORMATIONS_LIMITATIONS_TITLE")}</p>
                    <div className="text-container">{translate("LEGAL_INFORMATIONS_LIMITATIONS_TEXT")}</div>
                    <div className="separator"></div>
                    <p className="title">{translate("LEGAL_INFORMATIONS_INTELLECTUAL_PROPERTY_TITLE")}</p>
                    <div className="text-container">{translate("LEGAL_INFORMATIONS_INTELLECTUAL_PROPERTY_TEXT")}</div>
                    <div className="separator"></div>
                    <p className="title">{translate("LEGAL_INFORMATIONS_RESPONSABILITY_LIMITATIONS_TITLE")}</p>
                    <div className="text-container">{translate("LEGAL_INFORMATIONS_RESPONSABILITY_LIMITATIONS_TEXT")}</div>
                    <div className="separator"></div>
                    <p className="title">{translate("LEGAL_INFORMATIONS_DATA_MANAGEMENT_TITLE")}</p>
                    <div className="text-container">{translate("LEGAL_INFORMATIONS_DATA_MANAGEMENT_TEXT")}</div>
                    <div className="separator"></div>
                    <p className="title">{translate("LEGAL_INFORMATIONS_HYPERTEXT_LINK_TITLE")}</p>
                    <div className="text-container">{translate("LEGAL_INFORMATIONS_HYPERTEXT_LINK_TEXT")}</div>
                    <div className="separator"></div>
                    <p className="title">{translate("LEGAL_INFORMATIONS_APPLICABLE_RIGHT_TITLE")}</p>
                    <div className="text-container">{translate("LEGAL_INFORMATIONS_APPLICABLE_RIGHT_TEXT")}</div>
                    <div className="separator"></div>
                    <p className="title">{translate("LEGAL_INFORMATIONS_MAIN_LAW_TITLE")}</p>
                    <div className="text-container">{translate("LEGAL_INFORMATIONS_MAIN_LAW_TEXT")}</div>
                    <div className="separator"></div>
                    <p className="title">{translate("LEGAL_INFORMATIONS_LEXICAL_TITLE")}</p>
                    <div className="text-container">{translate("LEGAL_INFORMATIONS_LEXICAL_TEXT")}</div>
                </div>
            </>}
            { device === "phone/tablet" && <>
                <div className="content-container">
                    <p className="title-min">{translate("LEGAL_INFORMATIONS_PRESENTATION_TITLE")}</p>
                    <div className="text-container">{translate("LEGAL_INFORMATIONS_PRESENTATION_TEXT")}</div>
                    <div className="separator"></div>
                    <p className="title-min">{translate("LEGAL_INFORMATIONS_CONDITIONS_TITLE")}</p>
                    <div className="text-container">{translate("LEGAL_INFORMATIONS_CONDITIONS_TEXT")}</div>
                    <div className="separator"></div>
                    <p className="title-min">{translate("LEGAL_INFORMATIONS_SERVICES_DESCRIPTION_TITLE")}</p>
                    <div className="text-container">{translate("LEGAL_INFORMATIONS_SERVICES_DESCRIPTION_TEXT")}</div>
                    <div className="separator"></div>
                    <p className="title-min">{translate("LEGAL_INFORMATIONS_LIMITATIONS_TITLE")}</p>
                    <div className="text-container">{translate("LEGAL_INFORMATIONS_LIMITATIONS_TEXT")}</div>
                    <div className="separator"></div>
                    <p className="title-min">{translate("LEGAL_INFORMATIONS_INTELLECTUAL_PROPERTY_TITLE")}</p>
                    <div className="text-container">{translate("LEGAL_INFORMATIONS_INTELLECTUAL_PROPERTY_TEXT")}</div>
                    <div className="separator"></div>
                    <p className="title-min">{translate("LEGAL_INFORMATIONS_RESPONSABILITY_LIMITATIONS_TITLE")}</p>
                    <div className="text-container">{translate("LEGAL_INFORMATIONS_RESPONSABILITY_LIMITATIONS_TEXT")}</div>
                    <div className="separator"></div>
                    <p className="title-min">{translate("LEGAL_INFORMATIONS_DATA_MANAGEMENT_TITLE")}</p>
                    <div className="text-container">{translate("LEGAL_INFORMATIONS_DATA_MANAGEMENT_TEXT")}</div>
                    <div className="separator"></div>
                    <p className="title-min">{translate("LEGAL_INFORMATIONS_HYPERTEXT_LINK_TITLE")}</p>
                    <div className="text-container">{translate("LEGAL_INFORMATIONS_HYPERTEXT_LINK_TEXT")}</div>
                    <div className="separator"></div>
                    <p className="title-min">{translate("LEGAL_INFORMATIONS_APPLICABLE_RIGHT_TITLE")}</p>
                    <div className="text-container">{translate("LEGAL_INFORMATIONS_APPLICABLE_RIGHT_TEXT")}</div>
                    <div className="separator"></div>
                    <p className="title-min">{translate("LEGAL_INFORMATIONS_MAIN_LAW_TITLE")}</p>
                    <div className="text-container">{translate("LEGAL_INFORMATIONS_MAIN_LAW_TEXT")}</div>
                    <div className="separator"></div>
                    <p className="title-min">{translate("LEGAL_INFORMATIONS_LEXICAL_TITLE")}</p>
                    <div className="text-container">{translate("LEGAL_INFORMATIONS_LEXICAL_TEXT")}</div>
                </div>
            </>}
        </span>
    );
}

export default LegalInformations;