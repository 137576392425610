// []
//Lost result validation rules
//Used to validate form before move to next form
import { parseDate } from '../../Date';

export function validationRules(values, translate) {

  const errors = {};
  if (values.gender.trim() === "") {
    errors.gender = translate("REQUIRED_FIELD")
  }

  /*
    [MAGE.COM.WA.20 - 1.8]
    First name validation  
  */
  if (values.firstName.trim() === "") {
    errors.firstName = translate("REQUIRED_FIELD")
  } else if (values.firstName.trim().length > 30) {
    errors.firstName = 'Max. 30 ch.'
  }

  /*
    [MAGE.COM.WA.18 - 1.8]
    [MAGE.COM.WA.19 - 1.8]
    Last name validation  
  */
  if (values.lastName.trim() === "") {
    errors.lastName = translate("REQUIRED_FIELD")
  } else if (values.firstName.trim().length > 30) {
    errors.firstName = 'Max. 30 ch.'
  }

  /*
    [MAGE.COM.WA.18 - 1.8]
    [MAGE.COM.WA.19 - 1.8]
    Usual name validation  
  */
  if (values.useName.trim() !== "" && values.useName.trim().length > 30) {
    errors.useName = 'Max. 30 ch.'
  }

  if (values.dtDay.trim() === "") {
    errors.birthDate = errors.dtDay = translate("REQUIRED_FIELD_DAY")
  } else if (!/[0-9]+/g.test(values.dtDay.trim()) || values.dtDay.trim().length !== 2) {
    errors.birthDate = errors.dtDay = translate("INVALID_BIRTHDATE_DAY")
  }

  if (values.dtMonth.trim() === "") {
    errors.birthDate = errors.dtMonth = translate("REQUIRED_FIELD_MONTH")
  } else if (!/[0-9]+/g.test(values.dtMonth.trim()) || values.dtMonth.trim().length !== 2) {
    errors.birthDate = errors.dtMonth = translate("INVALID_BIRTHDATE_MONTH")
  }

  if (values.dtYear.trim() === "") {
    errors.birthDate = errors.dtYear = translate("REQUIRED_FIELD_YEAR")
  } else if (!/[0-9]+/g.test(values.dtYear.trim()) || values.dtYear.trim().length !== 4) {
    errors.birthDate = errors.dtYear = translate("INVALID_BIRTHDATE_YEAR")
  }

  if(values.dtDay.trim() !== "" && values.dtMonth.trim() !== "" && values.dtYear.trim() !== ""){
    const date = values.dtYear + "-" + values.dtMonth + "-" + values.dtDay;
    if(new Date(date).getTime() >= new Date().getTime() || new Date(date).getTime() < new Date("1900-01-01").getTime()){
      errors.birthDate = errors.dtDay = errors.dtMonth = errors.dtYear = translate("INVALID_BIRTHDATE_INTERVAL")
    }else if(!new Date(date).isValid()){
      errors.birthDate = errors.dtDay = errors.dtMonth = errors.dtYear = translate("INVALID_BIRTHDATE")
    }
  }

  if (Object.keys(errors).length !== 0) {
    errors.formError = translate("FORM_ERROR");
  }

  return errors
}

//Used for redux form validation
const validateLostResult = values => {
  return validationRules(values);
}

export default validateLostResult