import React from 'react';
import GenericCheckbox from '../GenericCheckboxComponent';
import ModalImage from '../Modals/modal_image';
import fligthNumberImage from '../../assets/mtr_images/Billet_d_avion_num_vol.png'
import planeTicketImage from '../../assets/mtr_images/Billet_d_avion_billet_electronique.png'
import help from '../../assets/group-5/group-5.png'
import help2x from '../../assets/group-5/group-5@2x.png'
import help3x from '../../assets/group-5/group-5@3x.png'

/**
 * This component is using to manage Travel by plane component
 * @component
 */

const TravelByPlane = ({ tag: Tag, translate, name, value, onChange,
    flightNumberName, flightNumberValue, flightNumberPlaceholder, flightNumberError, flightNumberChange,
    planeTicketName, planeTicketValue, planeTicketPlaceholder, planeTicketError, planeTicketChange, ...rest }) => {

    const openModalHandler = (values) => {
        ModalImage(values);
    }
    return (<>
        <GenericCheckbox tag={Tag} translate={translate} name={name} value={value} onChange={onChange} />
        {value && <>
            <div className="row-input">
                <div className="zone-input">
                    <div className="input-with-icon">
                        <Tag name={flightNumberName} component="input"
                            className={flightNumberError && flightNumberError != "" ? "input-large-error" : "input-large"}
                            type="text" placeholder={flightNumberPlaceholder} value={flightNumberValue}
                            onChange={flightNumberChange} />
                        <img className="icon-help icon-flexible" alt="ihelp" src={help}
                            onClick={() => openModalHandler({ title: flightNumberPlaceholder, image: fligthNumberImage })}
                            srcSet={`${help2x} 768w, ${help3x} 1280w`} />
                    </div>
                    {flightNumberError && flightNumberError != "" &&
                        <p className="error-label">{flightNumberError}</p>}
                </div>
            </div>
            <div className="row-input">
                <div className="zone-input">
                    <div className="input-with-icon">
                        <Tag name={planeTicketName} component="input"
                            className={planeTicketError && planeTicketError != "" ? "input-large-error" : "input-large"}
                            type="text" placeholder={planeTicketPlaceholder} value={planeTicketValue} onChange={planeTicketChange} />
                        <img className="icon-help icon-flexible" alt="ihelp" src={help}
                            onClick={() => openModalHandler({ title: planeTicketPlaceholder, image: planeTicketImage })}
                            srcSet={`${help2x} 768w, ${help3x} 1280w`} />
                    </div>
                    {planeTicketError && planeTicketError != "" &&
                        <p className="error-label">{planeTicketError}</p>}
                </div>
            </div>
        </>}

    </>);
}

export default TravelByPlane;