// []
//Contact form validation rules
export function validationRules(values, translate) {
  const errors = {};

  if (values.firstName.trim() === "") {
    errors.firstName = translate("REQUIRED_FIELD");
  } else if (values.firstName.trim().length > 30) {
    errors.firstName = "Max. 30 ch.";
  }

  if (values.lastName.trim() === "") {
    errors.lastName = translate("REQUIRED_FIELD");
  } else if (values.firstName.trim().length > 30) {
    errors.firstName = "Max. 30 ch.";
  }

  /*
    Email validation
  */
  if (values.email.trim() === "") {
    errors.email = translate("REQUIRED_FIELD");
  } else if (
    !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      values.email
    )
  ) {
    errors.email = translate("INVALID_EMAIL");
  }

  /*
    Phone number validation
  */
  // if (values.phoneNumber.trim() === "") {
  //   errors.phoneNumber = translate("REQUIRED_FIELD");
  // } else if (values.phoneNumber.trim().length < 8) {
  //   errors.phoneNumber = "Min. 8 ch.";
  // }

  /*
    Subject validation
  */
  if (values.subject.trim() === "") {
    errors.subject = translate("REQUIRED_FIELD");
  }

  /*
    Message validation
  */
  if (values.message.trim() === "") {
    errors.message = translate("REQUIRED_FIELD");
  } else if (values.message.trim().length > 1000) {
    errors.message = "Max. 1000 ch.";
  }

  /*
    Data policy validation
  */
  if (values.hasCheckedAllInformation === false) {
    errors.hasCheckedAllInformation = translate("REQUIRED_FIELD");
  }

  if (Object.keys(errors).length !== 0) {
    // [MAGE.ALERT.WA.9 - 1.18]
    errors.formError = translate("FORM_ERROR");
  }

  return errors;
}
