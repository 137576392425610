import React from 'react';
import { Field, reduxForm, change } from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as validation from '../../utils/ValidationRules';
import validateLostResult from '../../utils/validation_forms/LostResultForm/validate_lost_result';
import { validationRules } from '../../utils/validation_forms/LostResultForm/validate_lost_result';
import BirthDateComponent from '../BirthDateComponent';

/**
 * This component is using to manage user identity for lost result form
 * [MAGE.COM.WA.69 - 1.18] to [MAGE.COM.WA.70 - 1.18] and [MAGE.ALERT.WA.1 - 1.18] and [MAGE.ALERT.WA.3 - 1.18]
 * @component
 */
class LostResult extends React.Component {
    constructor(props) {
        super(props);

        this.dateConfig = {
            'date': {
                format: 'DD',
                caption: props.translate("DATE_DAY_LABEL"),
                step: 1,
            },
            'month': {
                format: 'MM',
                caption: props.translate("DATE_MONTH_LABEL"),
                step: 1,
            },
            'year': {
                format: 'YYYY',
                caption: props.translate("DATE_YEAR_LABEL"),
                step: 1,
            }

        }

        this.state = props.forms.hasOwnProperty("wizardLost") && props.forms.wizardLost.hasOwnProperty("values") ? {
            gender: props.forms.wizardLost.values.gender ? props.forms.wizardLost.values.gender : "",
            isMan: props.forms.wizardLost.values.gender ? (props.forms.wizardLost.values.gender === "M" ? true : false) : "",
            firstName: props.forms.wizardLost.values.firstName ? props.forms.wizardLost.values.firstName : "",
            lastName: props.forms.wizardLost.values.lastName ? props.forms.wizardLost.values.lastName : "",
            useName: props.forms.wizardLost.values.useName ? props.forms.wizardLost.values.useName : "",
            dtDay: props.forms.wizardLost.values.dtDay ? props.forms.wizardLost.values.dtDay : "",
            dtMonth: props.forms.wizardLost.values.dtMonth ? props.forms.wizardLost.values.dtMonth : "",
            dtYear: props.forms.wizardLost.values.dtYear ? props.forms.wizardLost.values.dtYear : "",
            birthPlace: props.forms.wizardLost.values.birthPlace ? props.forms.wizardLost.values.birthPlace : "",
            isOpen: false,
            date: new Date(),
            dateConfig: this.dateConfig,
            errors: {}
        } : {
                gender: "",
                isMan: "",
                firstName: "",
                lastName: "",
                useName: "",
                dtDay: "",
                dtMonth: "",
                dtYear: "",
                birthPlace: "",
                isOpen: false,
                date: new Date(),
                dateConfig: this.dateConfig,
                errors: {}
            };
        this.handleGenderChange = this.handleGenderChange.bind(this);
        this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
        this.handleLastNameChange = this.handleLastNameChange.bind(this);
        this.handleUseNameChange = this.handleUseNameChange.bind(this);
        this.handleDtDayChange = this.handleDtDayChange.bind(this);
        this.handleDtMonthChange = this.handleDtMonthChange.bind(this);
        this.handleDtYearChange = this.handleDtYearChange.bind(this);
        this.handleBirthPlaceChange = this.handleBirthPlaceChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    /**
     * [MAGE.COM.WA.18 - 1.18], [MAGE.COM.WA.19 - 1.18]
     */
    handleGenderChange(e) {
        e.target.value === "M" ? this.setState({ isMan: true }) : this.setState({ isMan: false });
        var errors = this.state.errors;
        errors.gender = "";
        errors.formError = "";
        this.setState({ errors: errors, gender: e.target.value });

    }

    /**
     * [MAGE.COM.WA.20 - 1.18]
     */
    handleFirstNameChange(e) {
        var errors = this.state.errors;
        if (validation.charLengthValidation(e.target.value, 20)) {
            this.setState({ firstName: e.target.value });
            errors.firstName = "";
            errors.formError = "";
            this.setState({ errors: errors });
        }
    }
    /**
     * [MAGE.COM.WA.18 - 1.18], [MAGE.COM.WA.19 - 1.18]
     */
    handleLastNameChange(e) {
        var errors = this.state.errors;
        if (validation.charLengthValidation(e.target.value, 30)) {
            this.setState({ lastName: e.target.value });
            errors.lastName = "";
            errors.formError = "";
            this.setState({ errors: errors });
        }
    }
    /**
     * [MAGE.COM.WA.18 - 1.18], [MAGE.COM.WA.19 - 1.18]
     */
    handleUseNameChange(e) {
        var errors = this.state.errors;
        if (validation.charLengthValidation(e.target.value, 30)) {
            this.setState({ useName: e.target.value });
            errors.useName = "";
            errors.formError = "";
            this.setState({ errors: errors });
        }
    }

    /**
     * [MAGE.COM.WA.21 - 1.18]
     */
    handleDtDayChange(e) {
        var errors = this.state.errors;
        errors.dtDay = "";
        errors.birthDate = "";
        errors.formError = "";
        this.setState({ errors: errors, dtDay: e.target.value });
    }
    handleDtMonthChange(e) {
        var errors = this.state.errors;
        errors.dtMonth = "";
        errors.birthDate = "";
        errors.formError = "";
        this.setState({ errors: errors, dtMonth: e.target.value });
    }
    handleDtYearChange(e) {
        var errors = this.state.errors;
        errors.dtYear = "";
        errors.birthDate = "";
        errors.formError = "";
        this.setState({ errors: errors, dtYear: e.target.value });
    }

    /**
     * [MAGE.COM.WA.22 - 1.18]
     */
    handleBirthPlaceChange(e) {
        var errors = this.state.errors;
        if (validation.charLengthValidation(e.target.value, 30)) {
            this.setState({ birthPlace: e.target.value });
            errors.birthPlace = "";
            errors.formError = "";
            this.setState({ errors: errors });
        }

    }

    /**
     * [MAGE.COM.WA.69 - 1.18] and [MAGE.COM.WA.70 - 1.18] and [MAGE.ALERT.WA.1 - 1.18]
     */
    handleSubmit(e) {
        const dataToValidate = {
            gender: this.state.gender,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            useName: this.state.useName,
            dtDay: this.state.dtDay,
            dtMonth: this.state.dtMonth,
            dtYear: this.state.dtYear,
            birthPlace: this.state.birthPlace,
        };

        var errorList = validationRules(dataToValidate, this.props.translate);
        if (Object.keys(errorList).length !== 0) {
            // [MAGE.ALERT.WA.3 - 1.18] - TODO
            this.setState({ errors: errorList });
            e.preventDefault();
        } else {
            this.props.onSubmit();
        }


    }

    render() {

        const { translate, device } = this.props;

        return (
            <div>
                { device === "lap/desk" && <>
                    <p className="title">{translate("LOST_FORM_LABEL")}</p>
                    <div>
                        <form onSubmit={this.handleSubmit}>
                            <div className="fieldContainer">
                                <div className="input-container">
                                    <div>
                                        <p className="label">{translate("GENDER_FORM_LABEL")}<span className="required-tag">*</span></p>
                                        <div>
                                            <label className={this.state.isMan === true ? "radio-field-checked" : "radio-field"}><Field name="gender" component="input" type="radio"
                                                className="radioBtn" value="M"
                                                checked={this.state.isMan === true} onChange={this.handleGenderChange} /> {translate("GENDER_MALE_LABEL")}</label>
                                        </div>
                                        <div>
                                            <label className={this.state.isMan === false ? "radio-field-checked" : "radio-field"}><Field name="gender" component="input" type="radio" className="radioBtn" value="F"
                                                checked={this.state.isMan === false} onChange={this.handleGenderChange} /> {translate("GENDER_FEMALE_LABEL")}</label>
                                        </div>
                                        {this.state.errors.gender && this.state.errors.gender != "" &&
                                            <p className="error-label">{this.state.errors.gender}</p>}

                                    </div>
                                </div>
                                {/* [MAGE.COM.WA.20 - 1.18] */}
                                <div className="input-container">
                                    <div>
                                        <p className="label">{translate("FIRSTNAME_LABEL")}<span className="required-tag">*</span></p>
                                        <div>
                                            <Field name="firstName" component="input" type="text"
                                                className={this.state.errors.firstName && this.state.errors.firstName != "" ? "input-large-error" : "input-large"} placeholder={translate("FIRSTNAME_LABEL")}
                                                maxLength="20" value={this.state.firstName}
                                                onChange={this.handleFirstNameChange} />
                                            {this.state.errors.firstName && this.state.errors.firstName != "" &&
                                                <p className="error-label">{this.state.errors.firstName}</p>}
                                        </div>
                                    </div>
                                </div>
                                {/* [MAGE.COM.WA.18 - 1.18], [MAGE.COM.WA.19 - 1.18] */}
                                <div className="input-container">
                                    <p className="label">{this.state.isMan ? translate("LASTNAME_MALE_LABEL") : translate("LASTNAME_FEMALE_LABEL")}<span className="required-tag">*</span></p>
                                    <div>
                                        <Field name="lastName" component="input" type="text"
                                            className={this.state.errors.lastName && this.state.errors.lastName != "" ? "input-large-error" : "input-large"}
                                            placeholder={translate("LASTNAME_LABEL")} maxLength="30"
                                            value={this.state.lastName} onChange={this.handleLastNameChange} />
                                        {this.state.errors.lastName && this.state.errors.lastName != "" &&
                                            <p className="error-label">{this.state.errors.lastName}</p>}
                                    </div>
                                </div>
                                <div className="input-container">
                                    <p className="label">{this.state.isMan ? translate("USENAME_MALE_LABEL") : translate("USENAME_FEMALE_LABEL")}</p>
                                    <div>
                                        <Field name="useName" component="input" type="text"
                                            className={this.state.errors.useName && this.state.errors.useName != "" ? "input-large-error" : "input-large"}
                                            placeholder={translate("LASTNAME_LABEL")} maxLength="30" value={this.state.useName} onChange={this.handleUseNameChange} />
                                        {this.state.errors.useName && this.state.errors.useName != "" &&
                                            <p className="error-label">{this.state.errors.useName}</p>}
                                    </div>
                                </div>
                                <div className="row-input">
                                    <div className="zone-input">
                                        <p className="label">{translate("BIRTHDAY_LABEL")}<span className="required-tag">*</span></p>
                                        <BirthDateComponent
                                            tag={Field}
                                            translate={translate}
                                            dayName="dtDay"
                                            dayValue={this.state.dtDay}
                                            dayOnChange={this.handleDtDayChange}
                                            dayOnError={this.state.errors.dtDay}
                                            monthName="dtMonth"
                                            monthValue={this.state.dtMonth}
                                            monthOnChange={this.handleDtMonthChange}
                                            monthOnError={this.state.errors.dtMonth}
                                            yearName="dtYear"
                                            yearValue={this.state.dtYear}
                                            yearOnChange={this.handleDtYearChange}
                                            yearOnError={this.state.errors.dtYear}
                                            onFieldError={this.state.errors.birthDate} />
                                    </div>
                                </div>
                                {/* [MAGE.COM.WA.22 - 1.18] */}
                                <div className="input-container">
                                    <p className="label">{translate("BIRTHPLACE_LABEL")}</p>
                                    <div>
                                        <Field name="birthPlace" component="input" type="text"
                                            placeholder={translate("BIRTHPLACE_PLACEHOLDER_LABEL")}
                                            className={this.state.errors.birthPlace && this.state.errors.birthPlace != "" ? "input-large-error" : "input-large"}
                                            maxLength="30" value={this.state.birthPlace} onChange={this.handleBirthPlaceChange} />
                                        {this.state.errors.birthPlace && this.state.errors.birthPlace != "" &&
                                            <p className="error-label">{this.state.errors.birthPlace}</p>}
                                    </div>
                                </div>
                            </div>
                            {/* [MAGE.ALERT.WA.1 - 1.18] */}
                            {this.state.errors.formError && this.state.errors.formError && <div className="form-error-container">
                                <p className="form-error-label">{this.state.errors.formError}</p>
                            </div>}
                            <div>
                                <p className="requireLabel"><span className="required-tag">*</span> {translate("REQUIRE_LABEL")}</p>
                                <button type="submit" className="btnMain">{translate("BUTTON_NEXT_LABEL")}</button>
                            </div>

                        </form>
                    </div>
                </>}
                { device === "phone/tablet" && <>
                    <div className="container-min">
                        <p className="title-min">{translate("LOST_FORM_LABEL")}</p>
                        <form onSubmit={this.handleSubmit}>
                            <div className="fieldContainer">
                                <div className="input-container">
                                    <div>
                                        <p className="label">{translate("GENDER_FORM_LABEL")}<span className="required-tag">*</span></p>
                                        <div>
                                            <label className={this.state.isMan === true ? "radio-field-checked" : "radio-field"}><Field name="gender" component="input" type="radio"
                                                className="radioBtn" value="M"
                                                checked={this.state.isMan === true} onChange={this.handleGenderChange} /> {translate("GENDER_MALE_LABEL")}</label>
                                        </div>
                                        <div>
                                            <label className={this.state.isMan === false ? "radio-field-checked" : "radio-field"}><Field name="gender" component="input" type="radio" className="radioBtn" value="F"
                                                checked={this.state.isMan === false} onChange={this.handleGenderChange} /> {translate("GENDER_FEMALE_LABEL")}</label>
                                        </div>
                                        {this.state.errors.gender && this.state.errors.gender != "" &&
                                            <p className="error-label">{this.state.errors.gender}</p>}

                                    </div>
                                </div>
                                {/* [MAGE.COM.WA.20 - 1.18] */}
                                <div className="input-container">
                                    <div>
                                        <p className="label">{translate("FIRSTNAME_LABEL")}<span className="required-tag">*</span></p>
                                        <div>
                                            <Field name="firstName" component="input" type="text"
                                                className={this.state.errors.firstName && this.state.errors.firstName != "" ? "input-large-error" : "input-large"} placeholder={translate("FIRSTNAME_LABEL")}
                                                maxLength="20" value={this.state.firstName}
                                                onChange={this.handleFirstNameChange} />
                                            {this.state.errors.firstName && this.state.errors.firstName != "" &&
                                                <p className="error-label">{this.state.errors.firstName}</p>}
                                        </div>
                                    </div>
                                </div>
                                {/* [MAGE.COM.WA.18 - 1.18], [MAGE.COM.WA.19 - 1.18] */}
                                <div className="input-container">
                                    <p className="label">{this.state.isMan ? translate("LASTNAME_MALE_LABEL") : translate("LASTNAME_FEMALE_LABEL")}<span className="required-tag">*</span></p>
                                    <div>
                                        <Field name="lastName" component="input" type="text"
                                            className={this.state.errors.lastName && this.state.errors.lastName != "" ? "input-large-error" : "input-large"}
                                            placeholder={translate("LASTNAME_LABEL")} maxLength="30"
                                            value={this.state.lastName} onChange={this.handleLastNameChange} />
                                        {this.state.errors.lastName && this.state.errors.lastName != "" &&
                                            <p className="error-label">{this.state.errors.lastName}</p>}
                                    </div>
                                </div>
                                <div className="input-container">
                                    <p className="label">{this.state.isMan ? translate("USENAME_MALE_LABEL") : translate("USENAME_FEMALE_LABEL")}</p>
                                    <div>
                                        <Field name="useName" component="input" type="text"
                                            className={this.state.errors.useName && this.state.errors.useName != "" ? "input-large-error" : "input-large"}
                                            placeholder={translate("LASTNAME_LABEL")} maxLength="30" value={this.state.useName} onChange={this.handleUseNameChange} />
                                        {this.state.errors.useName && this.state.errors.useName != "" &&
                                            <p className="error-label">{this.state.errors.useName}</p>}
                                    </div>
                                </div>
                                <div className="row-input">
                                    <div className="zone-input">
                                        <p className="label">{translate("BIRTHDAY_LABEL")}<span className="required-tag">*</span></p>
                                        <BirthDateComponent
                                            tag={Field}
                                            translate={translate}
                                            dayName="dtDay"
                                            dayValue={this.state.dtDay}
                                            dayOnChange={this.handleDtDayChange}
                                            dayOnError={this.state.errors.dtDay}
                                            monthName="dtMonth"
                                            monthValue={this.state.dtMonth}
                                            monthOnChange={this.handleDtMonthChange}
                                            monthOnError={this.state.errors.dtMonth}
                                            yearName="dtYear"
                                            yearValue={this.state.dtYear}
                                            yearOnChange={this.handleDtYearChange}
                                            yearOnError={this.state.errors.dtYear}
                                            onFieldError={this.state.errors.birthDate} />
                                    </div>
                                </div>
                                {/* [MAGE.COM.WA.22 - 1.18] */}
                                <div className="input-container">
                                    <p className="label">{translate("BIRTHPLACE_LABEL")}</p>
                                    <div>
                                        <Field name="birthPlace" component="input" type="text"
                                            placeholder={translate("BIRTHPLACE_PLACEHOLDER_LABEL")}
                                            className={this.state.errors.birthPlace && this.state.errors.birthPlace != "" ? "input-large-error" : "input-large"}
                                            maxLength="30" value={this.state.birthPlace} onChange={this.handleBirthPlaceChange} />
                                        {this.state.errors.birthPlace && this.state.errors.birthPlace != "" &&
                                            <p className="error-label">{this.state.errors.birthPlace}</p>}
                                    </div>
                                </div>
                            </div>
                            {/* [MAGE.ALERT.WA.1 - 1.18] */}
                            {this.state.errors.formError && this.state.errors.formError && <div className="form-error-container">
                                <p className="form-error-label">{this.state.errors.formError}</p>
                            </div>}
                            <div>
                                <p className="requireLabel"><span className="required-tag">*</span> {translate("REQUIRE_LABEL")}</p>
                                <button type="submit" className="btnMain">{translate("BUTTON_NEXT_LABEL")}</button>
                            </div>

                        </form>
                    </div>
                </>}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        forms: state.form
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            change
        }, dispatch)
    };
}

export default reduxForm({
    form: 'wizardLost',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    validateLostResult
})(connect(
    mapStateToProps,
    mapDispatchToProps
)(LostResult))
