/**
* This file initialize internationalization for this app
*
* [MAGE.COM.WA.1 - TOUS]
*/

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getDefaultLanguage, setDefaultLanguage } from '../utils/language';

import Backend from 'i18next-xhr-backend';

const languages = ['fr', 'en', 'es', 'it', 'de']
var defaultLanguage = "fr";

/**
   * Store default language
   */
if (getDefaultLanguage() !== null) {
  if (languages.includes(getDefaultLanguage())) {
    defaultLanguage = getDefaultLanguage();
  } else {
    setDefaultLanguage(defaultLanguage);
  }
} else {
  setDefaultLanguage(defaultLanguage);
}

i18n
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // connect with React
  .use(initReactI18next)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    lng: defaultLanguage,
    fallbackLng: defaultLanguage,
    whitelist: languages,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    backend: {
      loadPath: '/locales/{{lng}}.json',
    },
  });

export default i18n;