import React from 'react';
import { withRouter } from 'react-router-dom';

class EmailConfirmation extends React.Component {

    constructor(props) {
        super(props);

        this.handlePrevious = this.handlePrevious.bind(this);
    }

    handlePrevious(event) {
        this.props.history.push({
            pathname: "/email-verification",
        });

    }

    render() {
        const { translate, device } = this.props;
        return (
            <span>
                {device === "lap/desk" && <>
                    <div className="container">
                        <div className="content">
                            <p className="title">{translate("EMAIL_CONFIRMATION_ERROR_LABEL")}</p>
                            <div>
                                <button type="button" className="btnMain" onClick={this.handlePrevious}>{translate("BUTTON_OK_LABEL")}</button>
                            </div>
                        </div>
                    </div></>}
                {device === "phone/tablet" && <>
                    <div className="row-input-min">
                        <div className="container-min">
                            <p className="title-min">{translate("EMAIL_CONFIRMATION_ERROR_LABEL")}</p>
                            <div className="btn-center-min">
                                <button type="button" className="btnMain" onClick={this.handlePrevious}>{translate("BUTTON_OK_LABEL")}</button>
                            </div>
                            <p className="test-rapide-link">{translate("WEBSITE_TEXT")}</p>
                        </div>
                    </div></>}
            </span>
        );
    }
}

export default withRouter(EmailConfirmation);