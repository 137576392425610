import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';


const MySwal = withReactContent(Swal)
function showAlert(dataToShow) {


  return MySwal.fire({
    width: 600,
    title: "<span class='abort-modal-title'>" + dataToShow.title + "</span>",
    text: dataToShow.description,
    icon: 'error',
    backdrop: 'rgba(255, 255, 255, 0.5)',
    showCancelButton: true,
    showConfirmButton: false,
    cancelButtonText: dataToShow.btnYesText
  })

}

export default showAlert;