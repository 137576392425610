import React from 'react';
import { Field, reduxForm, change } from 'redux-form'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getDefaultLanguage, setDefaultLanguage } from '../../utils/language';
import ModalErrorData from '../../components/Modals/modal_no_data';
import validateSecondStep from '../../utils/validation_forms/IdentityForm/validate_second_step'
import { validationRules } from '../../utils/validation_forms/IdentityForm/validate_second_step';
import config from '../../config/load_config';
import * as HttpRequest from '../../utils/HttpRequest';

/**
 * This component is using to manage test type
 * [MAGE.COM.WA.31 - 1.9] and [MAGE.COM.WA.32 - 1.9] and [MAGE.COM.WA.91 - 1.10] to [MAGE.COM.WA.93 - 1.10]
 * @component
 */
class Test extends React.Component {
    constructor(props) {
        super(props);

        this.state = props.forms.hasOwnProperty("wizard") ? {
            testType: props.forms.wizard.values.testType ? props.forms.wizard.values.testType : "",
            testList: [],
            defaultLanguage: props.translate("LANGUAGE_CODE"),
            hasGiveTestDeviceConsent: props.forms.wizard.values.hasGiveTestDeviceConsent ? props.forms.wizard.values.hasGiveTestDeviceConsent : false,
            showDetails: props.forms.wizard.values.hasGiveTestDeviceConsent ? true : false,
            prelevement: "",
            consentText: "",
            errors: {}
        } : {
            testType: "",
            testList: [],
            defaultLanguage: props.translate("LANGUAGE_CODE"),
            hasGiveTestDeviceConsent: false,
            showDetails: false,
            prelevement: "",
            consentText: "",
            errors: {}
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleHasGiveTestDeviceConsent = this.handleHasGiveTestDeviceConsent.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handlePreviousPage = this.handlePreviousPage.bind(this);
        this.openErrorDataModal = this.openErrorDataModal.bind(this);
        this.showTestDetails = this.showTestDetails.bind(this);
    }


    /**
     * [] Get test from api
     */
    async getTest() {
        console.log("API: " + process.env.REACT_APP_API_BASE_URL + config.API.TEST);
        const response = await HttpRequest.doGet(process.env.REACT_APP_API_BASE_URL + config.API.TEST, {
            "Access-Control-Allow-Origin": "*"
        });
        console.log(response);
        if (response.error) {
            this.setState({ testList: [] });
            this.openErrorDataModal();
        } else {
            this.setState({ testList: response.data });
            this.showTestDetails(this.state.testType, response.data);

        }

    }

    openErrorDataModal = () => {
        const dataToShow = {
            title: this.props.translate("ERROR_DATA_TITLE_LABEL"),
            description: this.props.translate("ERROR_DATA_SUBTITLE_LABEL"),
            btnYesText: this.props.translate("ERROR_DATA_BUTTON_OK_LABEL"),
        };
        ModalErrorData(dataToShow);
    }


    componentDidMount() {
        this.getTest();

    }

    /**
     * [] Show test details
     */
    showTestDetails(testType, list) {
        console.log("List : " + list)
        list = list.filter(test => parseInt(testType) === parseInt(test.id))
        if (list.length > 0) {
            const test = list[0]
            this.setState({
                showDetails: true,
                testType: test.detection[this.state.defaultLanguage] !== undefined ? test.detection[this.state.defaultLanguage] : test.detection[this.props.translate("DEFAULT_LANGUAGE_CODE")],
                prelevement: test.sample[this.state.defaultLanguage] !== undefined ? test.sample[this.state.defaultLanguage] : test.sample[this.props.translate("DEFAULT_LANGUAGE_CODE")],
                consentText: test.declaration[this.state.defaultLanguage] !== undefined ? test.declaration[this.state.defaultLanguage] : test.declaration[this.props.translate("DEFAULT_LANGUAGE_CODE")],
            });
        } else {
            this.setState({ showDetails: false });
        }
    }

    /**
     * [MAGE.COM.WA.31 - 1.9]
     */
    searchTest() {
        //TODO
    }

    handleChange(e) {
        var errors = this.state.errors;
        errors.testType = "";
        errors.formError = "";
        errors.hasGiveTestDeviceConsent = "";
        this.setState({ errors: errors, testType: e.target.value, hasGiveTestDeviceConsent: false });
        this.showTestDetails(e.target.value, this.state.testList);
    }

    /**
     * [MAGE.COM.WA.32 - 1.9] and [MAGE.COM.WA.91 - 1.10]
     */
    handleSubmit(e) {
        const dataToValidate = {
            testType: this.state.testType,
            hasGiveTestDeviceConsent: this.state.hasGiveTestDeviceConsent
        }
        var errorList = validationRules(dataToValidate, this.props.translate);
        if (Object.keys(errorList).length !== 0) {
            this.setState({ errors: errorList });
        } else {
            this.props.onSubmit();
        }

        e.preventDefault();
    }

    handlePreviousPage(e) {
        e.preventDefault();
        this.props.previousPage();
    }

    /**
     * [] Give consent to selected test
     */
    handleHasGiveTestDeviceConsent(e) {
        var errors = this.state.errors;
        errors.hasGiveTestDeviceConsent = "";
        errors.formError = "";
        this.setState({ errors: errors, hasGiveTestDeviceConsent: !this.state.hasGiveTestDeviceConsent });
        this.props.change("consentText", this.state.consentText);
    }

    render() {
        console.log(this.state);
        const { previousPage, translate, device } = this.props;
        return (
            <div>
                { device === "lap/desk" && <>
                    <p className="title">{translate("CHOOSE_TEST_LABEL")}</p>
                    <div>
                        <form onSubmit={this.handleSubmit}>
                            <div className="fieldContainer">
                                <div className="row-input">
                                    <div className="select-test-container">
                                        <p className="label">{translate("AVAILABLE_TEST_LABEL")}<span className="required-tag">*</span></p>
                                        <span>
                                            {/* [MAGE.COM.WA.32 - 1.9] */}
                                            <Field name="testType" component="select"
                                                className={this.state.errors.testType && this.state.errors.testType != "" ? "input-large-error select-test" : "input-large select-test"}
                                                value={this.state.testType} onChange={this.handleChange}>
                                                {
                                                    [
                                                        <option value="">{translate("AVAILABLE_TEST_CHOOSE_LABEL")}</option>,
                                                        this.state.testList.map(test => {
                                                            return (
                                                                <option value={test.id}>{test.label[this.state.defaultLanguage] !== undefined ? test.label[this.state.defaultLanguage] : test.label[this.props.translate("DEFAULT_LANGUAGE_CODE")]}</option>
                                                            );
                                                        })
                                                    ]
                                                }
                                            </Field>
                                            {this.state.errors.testType && this.state.errors.testType != "" &&
                                                <p className="error-label">{this.state.errors.testType}</p>}
                                        </span>
                                    </div>
                                </div>
                                {this.state.showDetails && <>
                                    <div className="input-container">
                                        {/* [MAGE.COM.WA.93 - 1.10] */}
                                        <table className="table-recap test-selection">
                                            <tr className="recap-row">
                                                <td><p className="recap-label">{translate("TEST_TYPE_LABEL")}</p></td>
                                                <td><p className="label">{this.state.testType}</p></td>
                                            </tr>
                                            <tr className="recap-row-none">
                                                <td><p className="recap-label">{translate("PRELEVEMENT_LABEL")}</p></td>
                                                <td><p className="label">{this.state.prelevement}</p></td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div className="input-container">
                                        {/* [MAGE.COM.WA.92 - 1.10] */}
                                        <label className={this.state.hasGiveTestDeviceConsent ? "checkbox-field-checked test-consent" : "checkbox-field test-consent"}>
                                            <div><Field name="hasGiveTestDeviceConsent" component="input" className="checkboxBtn" type="checkbox" value={this.state.hasGiveTestDeviceConsent}
                                                checked={this.state.hasGiveTestDeviceConsent} onChange={this.handleHasGiveTestDeviceConsent} /></div>
                                            {this.state.consentText}
                                        </label>
                                        {this.state.errors.hasGiveTestDeviceConsent && this.state.errors.hasGiveTestDeviceConsent != "" &&
                                            <p className="error-label">{this.state.errors.hasGiveTestDeviceConsent}</p>}
                                    </div>
                                    <Field component="text" type="hidden" name="consentText" value={this.state.consentText} />
                                </>}
                            </div>
                            <div className="buttons-zone">
                                {/* [MAGE.ALERT.WA.13 - 1.10] */}
                                {this.state.errors.formError && this.state.errors.formError && <div className="form-error-container">
                                    <p className="form-error-label">{this.state.errors.formError}</p>
                                </div>}
                                <p className="requireLabel"><span className="required-tag">*</span> {translate("REQUIRE_LABEL")}</p>
                                {/* [MAGE.COM.WA.84 - 1.9] */}
                                <button type="button" className="btnSecondary" onClick={this.handlePreviousPage}>{translate("BUTTON_BACK_LABEL")}</button>
                                <button type="submit" className="btnMain">{translate("BUTTON_NEXT_LABEL")}</button>
                            </div>
                        </form>
                    </div>
                </>
                }{device === "phone/tablet" && <>
                    <div className="container-min">
                        <p className="title-min">{translate("CHOOSE_TEST_LABEL")}</p>
                        <form onSubmit={this.handleSubmit}>
                            <div className="fieldContainer">
                                <div className="input-container">
                                    <p className="label">{translate("AVAILABLE_TEST_LABEL")}<span className="required-tag">*</span></p>
                                    {/* [MAGE.COM.WA.32 - 1.9] */}
                                    <Field name="testType" component="select"
                                        className={this.state.errors.testType && this.state.errors.testType != "" ? "input-large-error" : "input-large"}
                                        value={this.state.testType} onChange={this.handleChange}>
                                        {
                                            [
                                                <option value="">{translate("AVAILABLE_TEST_CHOOSE_LABEL")}</option>,
                                                this.state.testList.map(test => {
                                                    return (
                                                        <option value={test.id}>{test.label[this.state.defaultLanguage] !== undefined ? test.label[this.state.defaultLanguage] : test.label[this.props.translate("DEFAULT_LANGUAGE_CODE")]}</option>
                                                    );
                                                })
                                            ]
                                        }
                                    </Field>
                                    {this.state.errors.testType && this.state.errors.testType != "" &&
                                        <p className="error-label">{this.state.errors.testType}</p>}
                                </div>
                                {this.state.showDetails && <>
                                    <div className="input-container">
                                        {/* [MAGE.COM.WA.93 - 1.10] */}
                                        <table className="table-recap">
                                            <tr className="recap-row">
                                                <td><p className="recap-label">{translate("TEST_TYPE_LABEL")}</p></td>
                                                <td><p className="label">{this.state.testType}</p></td>
                                            </tr>
                                            <tr className="recap-row-none">
                                                <td><p className="recap-label">{translate("PRELEVEMENT_LABEL")}</p></td>
                                                <td><p className="label">{this.state.prelevement}</p></td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div className="input-container">
                                        {/* [MAGE.COM.WA.92 - 1.10] */}
                                        <label className={this.state.hasGiveTestDeviceConsent ? "checkbox-field-checked" : "checkbox-field"}>
                                            <div><Field name="hasGiveTestDeviceConsent" component="input" className="checkboxBtn" type="checkbox" value={this.state.hasGiveTestDeviceConsent}
                                                checked={this.state.hasGiveTestDeviceConsent} onChange={this.handleHasGiveTestDeviceConsent} /></div>
                                            {this.state.consentText}
                                        </label>
                                        {this.state.errors.hasGiveTestDeviceConsent && this.state.errors.hasGiveTestDeviceConsent != "" &&
                                            <p className="error-label">{this.state.errors.hasGiveTestDeviceConsent}</p>}
                                    </div>
                                    <Field component="text" type="hidden" name="consentText" value={this.state.consentText} />
                                </>}
                            </div>
                            <div className="buttons-zone-min">
                                {/* [MAGE.ALERT.WA.13 - 1.10] */}
                                {this.state.errors.formError && this.state.errors.formError && <div className="form-error-container">
                                    <p className="form-error-label">{this.state.errors.formError}</p>
                                </div>}
                                <p className="requireLabel"><span className="required-tag">*</span> {translate("REQUIRE_LABEL")}</p>
                                <div className="wizard-buttons-container">
                                    {/* [MAGE.COM.WA.84 - 1.9] */}
                                    <button type="button" className="btnSecondary" onClick={this.handlePreviousPage}>{translate("BUTTON_BACK_LABEL")}</button>
                                    <button type="submit" className="btnMain">{translate("BUTTON_NEXT_LABEL")}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </>
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        forms: state.form
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            change
        }, dispatch)
    };
}

export default reduxForm({
    form: 'wizard',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validateSecondStep
})(connect(
    mapStateToProps,
    mapDispatchToProps
)(Test))