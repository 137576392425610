import React from 'react';
import Header from '../../components/HeaderComponent';
import LeftCard from '../../components/LeftCardComponent';
import Responsive from '../../components/ResponsiveComponent';
import CookiesComponent from '../../components/Policies/CookiesComponent'
import infirmier from '../../assets/infirmier/group-10.png';
import infirmier2x from '../../assets/infirmier/group-10@2x.png';
import infirmier3x from '../../assets/infirmier/group-10@3x.png';
import '../../styles/buttons.scss';
import '../../styles/user_type.scss';
import '../../styles/standard_style.scss';

const CookiesPolicies = (props) => {

    const { translate, onChangeLanguage } = props
    return (
        <Responsive
            webView={<>
                <Header translate={translate} isForm={true} device="lap/desk" />
                <div className="row">
                    <LeftCard
                        stepTitle={translate("COOKIES_POLICY_TITLE")}
                        bottomImage={<img src={infirmier}
                            srcSet={infirmier2x, infirmier3x}
                            alt="Img Infirmier"
                            className="Group-10" />}
                        websiteLink={translate("WEBSITE_TEXT")}
                    />
                    <div className="zone scrollable-zone">
                        <CookiesComponent device="lap/desk" translate={translate} onChangeLanguage={onChangeLanguage} />
                    </div>
                </div>
            </>}
            mobileView={<>
                <Header translate={translate} isForm={false} device="phone/tablet" title={translate("COOKIES_POLICY_TITLE")} />
                <div className="row">
                    <div className="zone scrollable-zone">
                        <div className="header-title-container">
                            <p className="header-title">{translate("COOKIES_POLICY_TITLE")}</p>
                        </div>
                        <CookiesComponent device="phone/tablet" translate={translate} onChangeLanguage={onChangeLanguage} />
                    </div>
                </div>
            </>}
        />
    );
}

export default CookiesPolicies;