import React from 'react';
import '../styles/forms_style.scss';
import Menu from './MenuComponent';
import { withRouter } from 'react-router-dom';
import logo from '../assets/logo-blue/logo-blue.png';
import logo2x from '../assets/logo-blue/logo-blue@2x.png';
import logo3x from '../assets/logo-blue/logo-blue@3x.png';
import menu from '../assets/group-4-blue/group-4.png';
import menu2x from '../assets/group-4-blue/group-4@2x.png';
import menu3x from '../assets/group-4-blue/group-4@3x.png';
import logoWhite from '../assets/logo/logo.png';
import logoWhite2x from '../assets/logo/logo@2x.png';
import logoWhite3x from '../assets/logo/logo@3x.png';
import menuWhite from '../assets/group-4/group-4.png';
import menuWhite2x from '../assets/group-4/group-4@2x.png';
import menuWhite3x from '../assets/group-4/group-4@3x.png';
import ChooseLanguages from './ChooseLanguagesComponent'

/**
 * This component is the header of the application
 * [MAGE.COM.WA.85 - 1.1, 1.5 to 1.21] and [MAGE.COM.WA.94 - TOUS]
 * @component
 */
class Header extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isMenuShowing: false
        }
    }

    openModalHandler = () => {
        this.setState({
            isMenuShowing: true
        });
    }

    closeModalHandler = () => {
        this.setState({
            isMenuShowing: false
        });
    }

    goHome = () => {
        this.props.history.push({
            pathname: "/",
        });
    }

    render() {
        const { device, translate, title, subtitle, isStepForm } = this.props;
        return (
            <span>
                { device === "lap/desk" && <>
                    <div className="header">
                        <img className="logo" alt="Logo Magentine" src={this.props.isForm ? logo : logoWhite} onClick={this.goHome}
                            srcSet={`${this.props.isForm ? logo2x : logoWhite2x} 768w, ${this.props.isForm ? logo3x : logoWhite3x} 1280w`} />
                        <div className="menu-language-container">
                            {/* [MAGE.COM.WA.94 - TOUS] */}
                            <ChooseLanguages translate={translate} />
                            {/* [MAGE.COM.WA.85 - 1.1, 1.5 to 1.21] */}
                            <img className="menu" alt="Menu" src={this.props.isForm ? menu : menuWhite} onClick={this.openModalHandler}
                                srcSet={`${this.props.isForm ? menu2x : menuWhite2x} 768w, ${this.props.isForm ? menu3x : menuWhite3x} 1280w`} />
                        </div>

                    </div>
                    {this.state.isMenuShowing && <Menu device="lap/desk" translate={translate} onClose={this.closeModalHandler} />}
                </>}
                { device === "phone/tablet" && <>
                    <div className="header-min">
                        <div className="header-logos">
                            <div className="menu-min">
                                {/* [MAGE.COM.WA.85 - 1.1, 1.5 to 1.21] */}
                                <img className="menu-image-min" alt="Menu" src={this.props.isForm ? menu : menuWhite} onClick={this.openModalHandler}
                                    srcSet={`${this.props.isForm ? menu2x : menuWhite2x} 768w, ${this.props.isForm ? menu3x : menuWhite3x} 1280w`} />
                            </div>
                            <div className="logo-min">
                                <img className="logo-image-min" alt="Logo Magentine" src={this.props.isForm ? logo : logoWhite} onClick={this.goHome}
                                    srcSet={`${this.props.isForm ? logo2x : logoWhite2x} 768w, ${this.props.isForm ? logo3x : logoWhite3x} 1280w`} />
                            </div>
                            <div>
                                {/* [MAGE.COM.WA.94 - TOUS] */}
                                <ChooseLanguages translate={translate} />
                            </div>
                        </div>
                    </div>
                    {this.state.isMenuShowing && <Menu device="phone/tablet" translate={translate} onClose={this.closeModalHandler} />}
                </>}
            </span>
        );
    }
}

export default withRouter(Header);