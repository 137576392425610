import React from 'react';

/**
 * This component is using to manage checkbox field
 * @component
 */

const GenericCheckbox = ({ tag: Tag, translate, name, value, onChange, ...rest }) => {

    return (
        <div className="input-container">
            <label className={value ? "checkbox-field-checked" : "checkbox-field"}>
                <div><Tag name={name} component="input" className="checkboxBtn" type="checkbox" value={value}
                    checked={value} onChange={onChange} /></div>
                {translate}
            </label>
            {rest.subLabel && <p className="test-place-example">{rest.subLabel}</p>}
        </div>
    );
}

export default GenericCheckbox;