// [MAGE.COM.WA.15 - 1.6]
//Email verification 
//Used to validate form before move to next form
export function validationRules(values, translate) {

  const errors = {};

  /*
    Email validation
  */
  if (values.email.trim() === "") {
    errors.email = translate("REQUIRED_FIELD")
  } else if (!/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(values.email)) {
    // [MAGE.ALERT.WA.10 - 1.6]
    errors.email = translate("INVALID_EMAIL")
  }
  /*
    Confirmation email validation
  */
  if (values.confirmationEmail.trim() === "") {
    errors.confirmationEmail = translate("REQUIRED_FIELD")
  } else if (!/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(values.email)) {
    // [MAGE.ALERT.WA.10 - 1.6]
    errors.confirmationEmail = translate("INVALID_EMAIL")
  }

  if (Object.keys(errors).length === 0) {
    // [MAGE.ALERT.WA.7 - 1.6]
    if (values.email.trim() !== values.confirmationEmail.trim()) {
      errors.confirmationEmail = translate("NOT_IDENTICAL_EMAIL")
    }
  }


  return errors
}