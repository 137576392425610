import React from 'react';
import PropTypes from 'prop-types';
import FirstStep from './step1';
import SecondStep from './step2';
import ThirdStep from './step3';

class ResultWizard extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      page: 1,
      patientID: ""
    }

    this.nextPage = this.nextPage.bind(this)
    this.previousPage = this.previousPage.bind(this)
  }

  nextPage = values => {
    this.setState({ page: this.state.page + 1 })
  }

  previousPage = values => {
    this.setState({ page: this.state.page - 1 })
  }

  render() {
    const { onSubmit, translate, onChangeLanguage } = this.props
    const { page } = this.state
    return (
      <div>
        {page === 1 && <FirstStep onSubmit={this.nextPage} translate={translate} onChangeLanguage={onChangeLanguage} />}
        {page === 2 && <SecondStep onSubmit={this.nextPage} translate={translate} onChangeLanguage={onChangeLanguage} />}
        {page === 3 && <ThirdStep onSubmit={onSubmit} translate={translate} onChangeLanguage={onChangeLanguage} />}
      </div>
    )
  }

}

ResultWizard.propTypes = {
  onSubmit: PropTypes.func.isRequired
}

export default ResultWizard;