import React from 'react';
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux';
import validateFourthStep from '../../utils/validation_forms/IdentityForm/validate_fourth_step'
import { validationRules } from '../../utils/validation_forms/IdentityForm/validate_fourth_step';
import GenericCheckbox from '../GenericCheckboxComponent';
import MotivationWithTextField from '../TestMotivations/MotivationWithTextFieldComponent';
import TravelByPlane from '../TestMotivations/TravelByPlaneComponent';

/**
 * This component is using to manage test motivations
 * [MAGE.COM.WA.43 - 1.12] to [MAGE.COM.WA.46 - 1.12] and [MAGE.ALERT.WA.1 - 1.12] and [MAGE.ALERT.WA.5 - 1.12]
 * @component
 */
class Questions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasMedicalPrescription: props.forms.wizard.values.hasMedicalPrescription ? true : false,
            doctorName: props.forms.wizard.values.doctorName ? props.forms.wizard.values.doctorName : "",
            hasBeenInContactWithCovidPerson: props.forms.wizard.values.hasBeenInContactWithCovidPerson ? true : false,
            hasBeenInContactWithCluster: props.forms.wizard.values.hasBeenInContactWithCluster ? true : false,
            hasBeenContactedByHealthInvestigator: props.forms.wizard.values.hasBeenContactedByHealthInvestigator ? true : false,
            haveScreeningVoucher: props.forms.wizard.values.haveScreeningVoucher ? true : false,
            haveHautingPersonAtRisk: props.forms.wizard.values.haveHautingPersonAtRisk ? true : false,
            screeningForMySelf: props.forms.wizard.values.screeningForMySelf ? true : false,
            professionalNeeded: props.forms.wizard.values.professionalNeeded ? true : false,
            companyName: props.forms.wizard.values.companyName ? props.forms.wizard.values.companyName : "",
            willGoingToInstitution: props.forms.wizard.values.willGoingToInstitution ? true : false,
            travelByPlane: props.forms.wizard.values.travelByPlane ? true : false,
            flightNumber: props.forms.wizard.values.flightNumber ? props.forms.wizard.values.flightNumber : "",
            planeTicketNumber: props.forms.wizard.values.planeTicketNumber ? props.forms.wizard.values.planeTicketNumber : "",
            haveReceivedNotificationFromApplication: props.forms.wizard.values.haveReceivedNotificationFromApplication ? true : false,
            others: props.forms.wizard.values.others ? true : false,
            haveSymptomsFromDays: props.forms.wizard.values.haveSymptomsFromDays ? true : false,
            isModalFligthNumberShowing: false,
            isModalPlaneTicketNumberShowing: false,
            errors: {}

        };
        this.handleHasMedicalPrescriptionChange = this.handleHasMedicalPrescriptionChange.bind(this);
        this.handleDoctorNameChange = this.handleDoctorNameChange.bind(this);
        this.handleHasBeenInContactWithCovidPersonChange = this.handleHasBeenInContactWithCovidPersonChange.bind(this);
        this.handleHasBeenInContactWithClusterChange = this.handleHasBeenInContactWithClusterChange.bind(this);
        this.handleHasBeenContactedByHealthInvestigatorChange = this.handleHasBeenContactedByHealthInvestigatorChange.bind(this);
        this.handleHaveScreeningVoucherChange = this.handleHaveScreeningVoucherChange.bind(this);
        this.handleHaveHautingPersonAtRiskChange = this.handleHaveHautingPersonAtRiskChange.bind(this);
        this.handleScreeningForMySelfChange = this.handleScreeningForMySelfChange.bind(this);
        this.handleProfessionalNeededChange = this.handleProfessionalNeededChange.bind(this);
        this.handleCompanyNameChange = this.handleCompanyNameChange.bind(this);
        this.handleWillGoingToInstitutionChange = this.handleWillGoingToInstitutionChange.bind(this);
        this.handleTravelByPlaneChange = this.handleTravelByPlaneChange.bind(this);
        this.handleFlightNumberChange = this.handleFlightNumberChange.bind(this);
        this.handlePlaneTicketNumberChange = this.handlePlaneTicketNumberChange.bind(this);
        this.handleHaveReceivedNotificationFromApplicationChange = this.handleHaveReceivedNotificationFromApplicationChange.bind(this);
        this.handleOthersChange = this.handleOthersChange.bind(this);
        this.handleHaveSymptomsFromDaysChange = this.handleHaveSymptomsFromDaysChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handlePreviousPage = this.handlePreviousPage.bind(this);
    }


    handleDoctorNameChange(e) {
        var errors = this.state.errors;
        errors.doctorName = "";
        errors.formError = "";
        this.setState({ errors: errors, doctorName: e.target.value });
    }
    handleCompanyNameChange(e) {
        var errors = this.state.errors;
        errors.companyName = "";
        errors.formError = "";
        this.setState({ errors: errors, companyName: e.target.value });
    }
    handleFlightNumberChange(e) {
        var errors = this.state.errors;
        errors.flightNumber = "";
        errors.formError = "";
        this.setState({ errors: errors, flightNumber: e.target.value });
    }
    handlePlaneTicketNumberChange(e) {
        var errors = this.state.errors;
        errors.planeTicketNumber = "";
        errors.formError = "";
        this.setState({ errors: errors, planeTicketNumber: e.target.value });
    }
    handleHasMedicalPrescriptionChange(e) {
        var errors = this.state.errors;
        errors.reasonTest = "";
        errors.formError = "";
        this.setState({ errors: errors, hasMedicalPrescription: !this.state.hasMedicalPrescription });
    }
    handleHasBeenInContactWithCovidPersonChange(e) {
        var errors = this.state.errors;
        errors.reasonTest = "";
        errors.formError = "";
        this.setState({ errors: errors, hasBeenInContactWithCovidPerson: !this.state.hasBeenInContactWithCovidPerson });
    }
    handleHasBeenInContactWithClusterChange(e) {
        var errors = this.state.errors;
        errors.reasonTest = "";
        errors.formError = "";
        this.setState({ errors: errors, hasBeenInContactWithCluster: !this.state.hasBeenInContactWithCluster });
    }
    handleHasBeenContactedByHealthInvestigatorChange(e) {
        var errors = this.state.errors;
        errors.reasonTest = "";
        errors.formError = "";
        this.setState({ errors: errors, hasBeenContactedByHealthInvestigator: !this.state.hasBeenContactedByHealthInvestigator });
    }
    handleHaveScreeningVoucherChange(e) {
        var errors = this.state.errors;
        errors.reasonTest = "";
        errors.formError = "";
        this.setState({ errors: errors, haveScreeningVoucher: !this.state.haveScreeningVoucher });
    }
    handleHaveHautingPersonAtRiskChange(e) {
        var errors = this.state.errors;
        errors.reasonTest = "";
        errors.formError = "";
        this.setState({ errors: errors, haveHautingPersonAtRisk: !this.state.haveHautingPersonAtRisk });
    }
    handleScreeningForMySelfChange(e) {
        var errors = this.state.errors;
        errors.reasonTest = "";
        errors.formError = "";
        this.setState({ errors: errors, screeningForMySelf: !this.state.screeningForMySelf });
    }
    handleProfessionalNeededChange(e) {
        var errors = this.state.errors;
        errors.reasonTest = "";
        errors.formError = "";
        this.setState({ errors: errors, professionalNeeded: !this.state.professionalNeeded });
    }
    handleWillGoingToInstitutionChange(e) {
        var errors = this.state.errors;
        errors.reasonTest = "";
        errors.formError = "";
        this.setState({ errors: errors, willGoingToInstitution: !this.state.willGoingToInstitution });
    }
    handleTravelByPlaneChange(e) {
        var errors = this.state.errors;
        errors.reasonTest = "";
        errors.formError = "";
        this.setState({ errors: errors, travelByPlane: !this.state.travelByPlane });
    }
    handleHaveReceivedNotificationFromApplicationChange(e) {
        var errors = this.state.errors;
        errors.reasonTest = "";
        errors.formError = "";
        this.setState({ errors: errors, haveReceivedNotificationFromApplication: !this.state.haveReceivedNotificationFromApplication });
    }
    handleOthersChange(e) {
        var errors = this.state.errors;
        errors.reasonTest = "";
        errors.formError = "";
        this.setState({ errors: errors, others: !this.state.others });
    }

    /**
     * [MAGE.COM.WA.44 - 1.12]
     */
    handleHaveSymptomsFromDaysChange(e) {
        this.setState({ haveSymptomsFromDays: !this.state.haveSymptomsFromDays });
    }

    /**
     * [MAGE.COM.WA.46 - 1.12]
     */
    handleSubmit(e) {

        const dataToValidate = {
            hasBeenInContactWithCovidPerson: this.state.hasBeenInContactWithCovidPerson,
            screeningForMySelf: this.state.screeningForMySelf,
            professionalNeeded: this.state.professionalNeeded,
            companyName: this.state.companyName,
            willGoingToInstitution: this.state.willGoingToInstitution,
            travelByPlane: this.state.travelByPlane,
            flightNumber: this.state.flightNumber,
            planeTicketNumber: this.state.planeTicketNumber,
        };

        var errorList = validationRules(dataToValidate, this.props.translate);
        if (Object.keys(errorList).length !== 0) {
            this.setState({ errors: errorList });

        } else {
            this.props.onSubmit();
        }

        e.preventDefault();
    }

    /**
     * [MAGE.COM.WA.45 - 1.12]
     */
    handlePreviousPage(e) {
        e.preventDefault();
        this.props.previousPage();
    }

    render() {

        console.log(this.props.forms);
        const { previousPage, translate, device } = this.props;
        return (
            <div className={device === "lap/desk" ? "" : "container-min"}>
                <form onSubmit={this.handleSubmit}>
                    <div className="fieldContainer">
                        <p className={device === "lap/desk" ? "title" : "title-min"}>{translate("REASON_TEST_LABEL")}<span className="required-tag">*</span></p>
                        {this.state.errors.reasonTest && this.state.errors.reasonTest != "" &&
                            <p className="error-label">{this.state.errors.reasonTest}</p>}

                        <GenericCheckbox tag={Field} name="screeningForMySelf" translate={translate("SCREENING_MYSELF_LABEL")}
                                         value={this.state.screeningForMySelf} onChange={this.handleScreeningForMySelfChange} />

                        <GenericCheckbox tag={Field} name="hasBeenInContactWithCovidPerson" translate={translate("COVID_PERSON_LABEL")} value={this.state.hasBeenInContactWithCovidPerson}
                            onChange={this.handleHasBeenInContactWithCovidPersonChange} />

                        <MotivationWithTextField tag={Field} name="professionalNeeded" translate={translate("PROFESSIONAL_NEEDED_LABEL")}
                            value={this.state.professionalNeeded} onChange={this.handleProfessionalNeededChange}
                            subfieldName="companyName" subfieldPlaceholder={translate("QUESTION_COMPANY_NAME_LABEL")} subfieldValue={this.state.companyName}
                            subfieldChange={this.handleCompanyNameChange} errorData={this.state.errors.companyName} />

                        <GenericCheckbox tag={Field} name="willGoingToInstitution" translate={translate("GOING_TO_INSTITUTION_LABEL")}
                            value={this.state.willGoingToInstitution} onChange={this.handleWillGoingToInstitutionChange} />

                        <TravelByPlane tag={Field} name="travelByPlane" translate={translate("TRAVEL_BY_PLANE_LABEL")} value={this.state.travelByPlane}
                            onChange={this.handleTravelByPlaneChange} flightNumberName="flightNumber"
                            flightNumberValue={this.state.flightNumber} flightNumberPlaceholder={translate("FLIGHT_NUMBER_LABEL")}
                            flightNumberChange={this.handleFlightNumberChange} flightNumberError={this.state.errors.flightNumber}
                            planeTicketName="planeTicketNumber" planeTicketValue={this.state.planeTicketNumber} planeTicketPlaceholder={translate("PLANE_TICKET_LABEL")}
                            planeTicketChange={this.handlePlaneTicketNumberChange} planeTicketError={this.state.errors.planeTicketNumber} />

                    </div>
                    {/* [MAGE.ALERT.WA.1 - 1.12] and [MAGE.ALERT.WA.5 - 1.12] */}
                    {this.state.errors.formError && this.state.errors.formError && <div className="form-error-container">
                        <p className="form-error-label">{this.state.errors.formError}</p>
                    </div>}
                    <div>
                        <p className="requireLabel"><span className="required-tag">*</span> {translate("REQUIRE_LABEL")}</p>
                        {device === "lap/desk" && <>
                            <button type="button" className="btnSecondary" onClick={this.handlePreviousPage}>{translate("BUTTON_BACK_LABEL")}</button>
                            <button type="submit" className="btnMain">{translate("BUTTON_NEXT_LABEL")}</button></>}
                        {device === "phone/tablet" && <><div className="wizard-buttons-container">
                            <button type="button" className="btnSecondary" onClick={this.handlePreviousPage}>{translate("BUTTON_BACK_LABEL")}</button>
                            <button type="submit" className="btnMain">{translate("BUTTON_NEXT_LABEL")}</button>
                        </div></>}
                    </div>
                </form>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        forms: state.form
    }
}

export default reduxForm({
    form: 'wizard',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validateFourthStep
})(connect(
    mapStateToProps,
    null
)(Questions));