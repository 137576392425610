import React, { useState } from 'react';
import ContactFormComponenent from '../../components/ContactForm/ContactFormComponent';
import PropTypes from 'prop-types';
import Header from '../../components/HeaderComponent';
import LeftCard from '../../components/LeftCardComponent';
import Responsive from '../../components/ResponsiveComponent';
import infirmiere from '../../assets/infirmiere/group-10.png';
import infirmiere2x from '../../assets/infirmiere/group-10@2x.png';
import infirmiere3x from '../../assets/infirmiere/group-10@3x.png';
import '../../styles/identity.scss';
import '../../styles/forms_style.scss';

const ContactForm = (props) => {

    const { onSubmit, translate, onChangeLanguage } = props
    return (
        <Responsive
            webView={<>
                <Header translate={translate} isForm={true} device="lap/desk" />
                <div className="row">
                    <LeftCard
                        stepTitle={translate("CONTACT_FORM_TITLE")}
                        bottomImage={<img src={infirmiere}
                            srcSet={infirmiere2x, infirmiere3x}
                            alt="Img Doctor"
                            className="Group-10" />}
                        websiteLink={translate("WEBSITE_TEXT")}
                    />
                    <div className="zone scrollable-zone">
                        <ContactFormComponenent onSubmit={onSubmit} device="lap/desk" translate={translate} onChangeLanguage={onChangeLanguage} />
                    </div>
                </div>
            </>}
            mobileView={<>
                <Header translate={translate} isForm={false} device="phone/tablet" title={translate("CONTACT_FORM_TITLE")} />
                <div className="row">
                    <div className="zone scrollable-zone">
                        <div className="header-title-container">
                            <p className="header-title">{translate("CONTACT_FORM_TITLE")}</p>
                        </div>
                        <ContactFormComponenent onSubmit={onSubmit} device="phone/tablet" translate={translate} onChangeLanguage={onChangeLanguage} />
                    </div>
                </div>
            </>}
        />
    );
}

ContactForm.propTypes = {
    onSubmit: PropTypes.func.isRequired
}

export default ContactForm;