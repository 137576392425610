import React from 'react';
import Finish from '../../components/IdentityForm/FinishComponent';
import PropTypes from 'prop-types';
import Header from '../../components/HeaderComponent';
import LeftCard from '../../components/LeftCardComponent';
import Responsive from '../../components/ResponsiveComponent';
import group10 from '../../assets/group-10/group-10.png';
import group10_2x from '../../assets/group-10/group-10@2x.png';
import group10_3x from '../../assets/group-10/group-10@3x.png';
import progressStep6 from '../../assets/mtr_images/Etape 6.svg';
import '../../styles/identity.scss';
import '../../styles/forms_style.scss';

/**
 * This component is the eighth step of Identity Form
 * [MAGE.COM.WA.29 - 1.16]
 * @component
 */
const SixthStep = (props) => {

    const { onSubmit, previousPage, translate, patientID } = props

    return (
        <Responsive
            webView={<>
                <Header translate={translate} isForm={true} device="lap/desk" />,
                <div className="row">
                    <LeftCard
                        stepTitle={translate("STEP_TITLE_LABEL")}
                        stepSubTitle={translate("STEP_8_TITLE_LABEL")}
                        /* [MAGE.COM.WA.29 - 1.16] */
                        stepImage={<img alt="Step 8" src={progressStep6} className="step-image" />}
                        bottomImage={<img src={group10}
                            srcSet={group10_2x, group10_3x}
                            alt="Img Doctor"
                            className="Group-10" />}
                        websiteLink={translate("WEBSITE_TEXT")}
                    />
                    <div className="zone scrollable-zone">
                        <Finish onSubmit={onSubmit} device="lap/desk" previousPage={previousPage} translate={translate} patientID={patientID} />
                    </div>
                </div>
            </>}
            mobileView={<>
                <Header translate={translate} isForm={false} device="phone/tablet" isStepForm={true} subtitle={translate("STEP_TITLE_LABEL")} title={translate("STEP") + " 8/8 : " + translate("STEP_8_TITLE_LABEL")} />
                <div className="row">
                    <div className="zone scrollable-zone">
                        <div className="header-title-container">
                            <p className="header-subtitle">{translate("STEP_TITLE_LABEL")}</p>
                            <p className="header-title">{translate("STEP") + " 6/6 : " + translate("STEP_8_TITLE_LABEL")}</p>
                        </div>
                        <Finish onSubmit={onSubmit} device="phone/tablet" previousPage={previousPage} translate={translate} patientID={patientID} />
                    </div>
                </div>
            </>}
        />
    );
}

SixthStep.propTypes = {
    onSubmit: PropTypes.func.isRequired
}

export default SixthStep;