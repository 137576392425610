import React from "react";
import CustomLink from "../CustomLink";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { validationRules } from "../../utils/validation_forms/ContactForm/validate_contact_form";
import * as validation from "../../utils/ValidationRules";
import config from "../../config/load_config";
import * as HttpRequest from "../../utils/HttpRequest";

/**
 * This component is using to manage contact form
 * [MAGE.COM.WA.75 - 1.21] to [MAGE.COM.WA.79 - 1.21] and [MAGE.COM.WA.88 - 1.22] and [MAGE.ALERT.WA.6 - 1.21]
 * @component
 */
class ContactForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      subject: "",
      company: "",
      message: "",
      hasCheckedAllInformation: false,
      msgSent: false,
      errors: {},
    };
    this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
    this.handleLastNameChange = this.handleLastNameChange.bind(this);
    this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleSubjectChange = this.handleSubjectChange.bind(this);
    this.handleCompanyChange = this.handleCompanyChange.bind(this);
    this.handleMessageChange = this.handleMessageChange.bind(this);
    this.handleHasCheckedAllInformationChange =
      this.handleHasCheckedAllInformationChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  /**
   * [MAGE.COM.WA.20 - 1.21]
   */
  handleFirstNameChange(e) {
    var errors = this.state.errors;
    if (validation.charLengthValidation(e.target.value, 20)) {
      this.setState({ firstName: e.target.value });
      errors.firstName = "";
      errors.formError = "";
      this.setState({ errors: errors });
    }
  }
  /**
   * [MAGE.COM.WA.18 - 1.21]
   */
  handleLastNameChange(e) {
    var errors = this.state.errors;
    if (validation.charLengthValidation(e.target.value, 30)) {
      this.setState({ lastName: e.target.value });
      errors.lastName = "";
      errors.formError = "";
      this.setState({ errors: errors });
    }
  }

  /**
   * [MAGE.COM.WA.24 - 1.21]
   */
  handlePhoneNumberChange(value, data, event, formattedValue) {
    var errors = this.state.errors;
    errors.phoneNumber = "";
    errors.formError = "";
    this.setState({ errors: errors, phoneNumber: formattedValue });
  }

  /**
   * [MAGE.COM.WA.23 - 1.21]
   */
  handleEmailChange(e) {
    var errors = this.state.errors;
    if (validation.charLengthValidation(e.target.value, 40)) {
      this.setState({ email: e.target.value });
      errors.email = "";
      errors.formError = "";
      this.setState({ errors: errors });
    }
  }

  /**
   * [MAGE.COM.WA.76 - 1.21]
   */
  handleSubjectChange(e) {
    var errors = this.state.errors;
    errors.subject = "";
    errors.formError = "";
    this.setState({ errors: errors, subject: e.target.value });
  }

  /**
   * [MAGE.COM.WA.75 - 1.21]
   */
  handleCompanyChange(e) {
    var errors = this.state.errors;
    if (validation.charLengthValidation(e.target.value, 40)) {
      this.setState({ company: e.target.value });
      errors.company = "";
      errors.formError = "";
      this.setState({ errors: errors });
    }
  }

  /**
   * [MAGE.COM.WA.77 - 1.21]
   */
  handleMessageChange(e) {
    var errors = this.state.errors;
    if (validation.charLengthValidation(e.target.value, 1000)) {
      this.setState({ message: e.target.value });
      errors.message = "";
      errors.formError = "";
      this.setState({ errors: errors });
    }
  }

  /**
   * [MAGE.COM.WA.78 - 1.21]
   */
  handleHasCheckedAllInformationChange(e) {
    var errors = this.state.errors;
    errors.hasCheckedAllInformation = "";
    errors.formError = "";
    this.setState({
      errors: errors,
      hasCheckedAllInformation: !this.state.hasCheckedAllInformation,
    });
  }

  /**
   * [MAGE.COM.WA.79 - 1.21]
   */
  async handleSubmit(e) {
    e.preventDefault();
    const dataToValidate = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      telephone: this.state.phoneNumber,
      subject: this.state.subject,
      company: this.state.company,
      message: this.state.message,
      hasCheckedAllInformation: this.state.hasCheckedAllInformation,
    };

    const response = await HttpRequest.doPost(
      process.env.REACT_APP_API_BASE_URL + config.API.CONTACT,
      dataToValidate,
      {
        "Access-Control-Allow-Origin": "*",
        "content-type": "application/json",
        // "source-country": "sp",
      }
    );

    var errorList = validationRules(dataToValidate, this.props.translate);
    if (Object.keys(errorList).length !== 0) {
      this.setState({ errors: errorList });
      e.preventDefault();
    } else {
      this.setState({ msgSent: true });
    }
  }

  render() {
    const { translate, device } = this.props;

    return (
      <div>
        {device === "lap/desk" && (
          <>
            {!this.state.msgSent && (
              <div>
                <p className="title">{translate("CONTACT_FORM_LABEL")}</p>
                <div>
                  <form onSubmit={this.handleSubmit}>
                    <div className="fieldContainer">
                      <div className="input-container">
                        <p className="label">
                          {translate("CONTACT_FORM_LASTNAME_LABEL")}
                          <span className="required-tag">*</span>
                        </p>
                        <div>
                          <input
                            type="text"
                            className={
                              this.state.errors.lastName &&
                              this.state.errors.lastName != ""
                                ? "input-large-error"
                                : "input-large"
                            }
                            placeholder={translate(
                              "CONTACT_FORM_LASTNAME_LABEL"
                            )}
                            maxLength="30"
                            value={this.state.lastName}
                            onChange={this.handleLastNameChange}
                          />
                          {this.state.errors.lastName &&
                            this.state.errors.lastName != "" && (
                              <p className="error-label">
                                {this.state.errors.lastName}
                              </p>
                            )}
                        </div>
                      </div>
                      <div className="input-container">
                        <div>
                          <p className="label">
                            {translate("CONTACT_FORM_FIRSTNAME_LABEL")}
                            <span className="required-tag">*</span>
                          </p>
                          <div>
                            <input
                              type="text"
                              className={
                                this.state.errors.firstName &&
                                this.state.errors.firstName != ""
                                  ? "input-large-error"
                                  : "input-large"
                              }
                              placeholder={translate(
                                "CONTACT_FORM_FIRSTNAME_LABEL"
                              )}
                              maxLength="20"
                              value={this.state.firstName}
                              onChange={this.handleFirstNameChange}
                            />
                            {this.state.errors.firstName &&
                              this.state.errors.firstName != "" && (
                                <p className="error-label">
                                  {this.state.errors.firstName}
                                </p>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="input-container">
                        <p className="label">
                          {translate("CONTACT_FORM_EMAIL_LABEL")}
                          <span className="required-tag">*</span>
                        </p>
                        <input
                          type="email"
                          className={
                            this.state.errors.email &&
                            this.state.errors.email != ""
                              ? "input-large-error"
                              : "input-large"
                          }
                          placeholder={translate("CONTACT_FORM_EMAIL_LABEL")}
                          maxLength="40"
                          value={this.state.email}
                          onChange={this.handleEmailChange}
                        />
                        {this.state.errors.email &&
                          this.state.errors.email != "" && (
                            <p className="error-label">
                              {this.state.errors.email}
                            </p>
                          )}
                      </div>
                      <div className="input-container">
                        <p className="label">
                          {translate("CONTACT_FORM_PHONE_LABEL")}
                          <span className="required-tag">*</span>
                        </p>
                        <PhoneInput
                          enableLongNumbers={true}
                          country={"fr"}
                          value={this.state.phoneNumber}
                          onChange={this.handlePhoneNumberChange}
                          enableAreaCodes={true}
                          enableAreaCodeStretch
                          countryCodeEditable={false}
                          enableSearch={true}
                          searchPlaceholder=""
                          inputClass={
                            this.state.errors.phoneNumber &&
                            this.state.errors.phoneNumber !== ""
                              ? "input-large-error"
                              : "input-large"
                          }
                        />
                        {this.state.errors.phoneNumber &&
                          this.state.errors.phoneNumber != "" && (
                            <p className="error-label">
                              {this.state.errors.phoneNumber}
                            </p>
                          )}
                      </div>
                      <div className="input-container">
                        <p className="label">
                          {translate("CONTACT_FORM_SUBJECT_LABEL")}
                          <span className="required-tag">*</span>
                        </p>
                        <select
                          className={
                            this.state.errors.subject &&
                            this.state.errors.subject != ""
                              ? "input-large-error"
                              : "input-large"
                          }
                          value={this.state.subject}
                          onChange={this.handleSubjectChange}
                        >
                          <option value="">
                            {translate("CONTACT_FORM_SUBJECT_CHOICE_LABEL")}
                          </option>
                          <option
                            value={translate(
                              "CONTACT_FORM_SUBJECT_INFORMATIONS_REQUEST_LABEL"
                            )}
                          >
                            {translate(
                              "CONTACT_FORM_SUBJECT_INFORMATIONS_REQUEST_LABEL"
                            )}
                          </option>
                          <option
                            value={translate(
                              "CONTACT_FORM_SUBJECT_COMMERCIAL_CONTACT_LABEL"
                            )}
                          >
                            {translate(
                              "CONTACT_FORM_SUBJECT_COMMERCIAL_CONTACT_LABEL"
                            )}
                          </option>
                          <option
                            value={translate(
                              "CONTACT_FORM_SUBJECT_PRESSE_LABEL"
                            )}
                          >
                            {translate("CONTACT_FORM_SUBJECT_PRESSE_LABEL")}
                          </option>
                          <option
                            value={translate(
                              "CONTACT_FORM_SUBJECT_OTHERS_LABEL"
                            )}
                          >
                            {translate("CONTACT_FORM_SUBJECT_OTHERS_LABEL")}
                          </option>
                        </select>
                        {this.state.errors.subject &&
                          this.state.errors.subject != "" && (
                            <p className="error-label">
                              {this.state.errors.subject}
                            </p>
                          )}
                      </div>
                      <div className="input-container">
                        <p className="label">
                          {translate("CONTACT_FORM_COMPANY_LABEL")}
                        </p>
                        <input
                          className={
                            this.state.errors.company &&
                            this.state.errors.company != ""
                              ? "input-large-error"
                              : "input-large"
                          }
                          type="text"
                          value={this.state.company}
                          onChange={this.handleCompanyChange}
                          placeholder={translate("CONTACT_FORM_COMPANY_LABEL")}
                        />
                        {this.state.errors.company &&
                          this.state.errors.company != "" && (
                            <p className="error-label">
                              {this.state.errors.company}
                            </p>
                          )}
                      </div>
                      <div className="input-container">
                        <p className="label">
                          {translate("CONTACT_FORM_MESSAGE_LABEL")}
                          <span className="required-tag">*</span>
                        </p>
                        <textarea
                          className={
                            this.state.errors.message &&
                            this.state.errors.message != ""
                              ? "input-large-error area"
                              : "input-large area"
                          }
                          value={this.state.message}
                          onChange={this.handleMessageChange}
                          placeholder={translate("MESSAGE_PLACEHOLDER")}
                        ></textarea>
                        {this.state.errors.message &&
                          this.state.errors.message != "" && (
                            <p className="error-label">
                              {this.state.errors.message}
                            </p>
                          )}
                      </div>
                      <div className="input-container">
                        <label
                          className={
                            this.state.hasCheckedAllInformation
                              ? "radio-field-small-padding-checked"
                              : "radio-field-small-padding"
                          }
                        >
                          <div>
                            <input
                              className="checkboxBtn"
                              type="checkbox"
                              value={this.state.hasCheckedAllInformation}
                              checked={this.state.hasCheckedAllInformation}
                              onChange={
                                this.handleHasCheckedAllInformationChange
                              }
                            />
                          </div>
                          <span>
                            {translate("LAST_STEP_BEFORE_END_CHECK_INFO_LABEL")}{" "}
                            <a
                              className="magentine-link"
                              href="/data-policy"
                              target="_blank"
                            >
                              {translate("LAST_STEP_BEFORE_END_RGPD_LABEL")}
                            </a>
                          </span>
                        </label>
                        {this.state.errors.hasCheckedAllInformation &&
                          this.state.errors.hasCheckedAllInformation != "" && (
                            <p className="error-label">
                              {this.state.errors.hasCheckedAllInformation}
                            </p>
                          )}
                      </div>
                    </div>
                    {/* [MAGE.ALERT.WA.6 - 1.21] */}
                    {this.state.errors.formError &&
                      this.state.errors.formError && (
                        <div className="form-error-container">
                          <p className="form-error-label">
                            {this.state.errors.formError}
                          </p>
                        </div>
                      )}
                    <div>
                      <p className="requireLabel">
                        <span className="required-tag">*</span>{" "}
                        {translate("REQUIRE_LABEL")}
                      </p>
                      <button type="submit" className="btnMain">
                        {translate("BUTTON_SEND_LABEL")}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            )}
            {this.state.msgSent && (
              <div>
                <p className="title">{translate("MESSAGE_SENT_LABEL")}</p>
                <div>
                  {/* [MAGE.COM.WA.88 - 1.22] */}
                  <CustomLink
                    className="btnMain"
                    tag="button"
                    to={{
                      pathname: "/",
                    }}
                  >
                    {translate("BUTTON_HOME_LABEL")}
                  </CustomLink>
                </div>
              </div>
            )}
          </>
        )}
        {device === "phone/tablet" && (
          <>
            {!this.state.msgSent && (
              <div>
                <div className="container-min">
                  <p className="title-min">{translate("CONTACT_FORM_LABEL")}</p>
                  <form onSubmit={this.handleSubmit}>
                    <div className="fieldContainer">
                      <div className="input-container">
                        <p className="label">
                          {translate("CONTACT_FORM_LASTNAME_LABEL")}
                          <span className="required-tag">*</span>
                        </p>
                        <div>
                          <input
                            type="text"
                            className={
                              this.state.errors.lastName &&
                              this.state.errors.lastName != ""
                                ? "input-large-error"
                                : "input-large"
                            }
                            placeholder={translate(
                              "CONTACT_FORM_LASTNAME_LABEL"
                            )}
                            maxLength="30"
                            value={this.state.lastName}
                            onChange={this.handleLastNameChange}
                          />
                          {this.state.errors.lastName &&
                            this.state.errors.lastName != "" && (
                              <p className="error-label">
                                {this.state.errors.lastName}
                              </p>
                            )}
                        </div>
                      </div>
                      <div className="input-container">
                        <div>
                          <p className="label">
                            {translate("CONTACT_FORM_FIRSTNAME_LABEL")}
                            <span className="required-tag">*</span>
                          </p>
                          <div>
                            <input
                              type="text"
                              className={
                                this.state.errors.firstName &&
                                this.state.errors.firstName != ""
                                  ? "input-large-error"
                                  : "input-large"
                              }
                              placeholder={translate(
                                "CONTACT_FORM_FIRSTNAME_LABEL"
                              )}
                              maxLength="20"
                              value={this.state.firstName}
                              onChange={this.handleFirstNameChange}
                            />
                            {this.state.errors.firstName &&
                              this.state.errors.firstName != "" && (
                                <p className="error-label">
                                  {this.state.errors.firstName}
                                </p>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="input-container">
                        <p className="label">
                          {translate("CONTACT_FORM_EMAIL_LABEL")}
                          <span className="required-tag">*</span>
                        </p>
                        <input
                          type="email"
                          className={
                            this.state.errors.email &&
                            this.state.errors.email != ""
                              ? "input-large-error"
                              : "input-large"
                          }
                          placeholder={translate("CONTACT_FORM_EMAIL_LABEL")}
                          maxLength="40"
                          value={this.state.email}
                          onChange={this.handleEmailChange}
                        />
                        {this.state.errors.email &&
                          this.state.errors.email != "" && (
                            <p className="error-label">
                              {this.state.errors.email}
                            </p>
                          )}
                      </div>
                      <div className="input-container">
                        <p className="label">
                          {translate("CONTACT_FORM_PHONE_LABEL")}
                          <span className="required-tag">*</span>
                        </p>
                        <PhoneInput
                          country={"fr"}
                          value={this.state.phoneNumber}
                          onChange={this.handlePhoneNumberChange}
                          enableAreaCodes={true}
                          enableAreaCodeStretch
                          countryCodeEditable={false}
                          enableSearch={true}
                          searchPlaceholder=""
                          inputClass={
                            this.state.errors.phoneNumber &&
                            this.state.errors.phoneNumber !== ""
                              ? "input-large-error"
                              : "input-large"
                          }
                        />
                        {this.state.errors.phoneNumber &&
                          this.state.errors.phoneNumber != "" && (
                            <p className="error-label">
                              {this.state.errors.phoneNumber}
                            </p>
                          )}
                      </div>
                      <div className="input-container">
                        <p className="label">
                          {translate("CONTACT_FORM_SUBJECT_LABEL")}
                          <span className="required-tag">*</span>
                        </p>
                        <select
                          className={
                            this.state.errors.subject &&
                            this.state.errors.subject != ""
                              ? "input-large-error"
                              : "input-large"
                          }
                          value={this.state.subject}
                          onChange={this.handleSubjectChange}
                        >
                          <option value="">
                            {translate("CONTACT_FORM_SUBJECT_CHOICE_LABEL")}
                          </option>
                          <option
                            value={translate(
                              "CONTACT_FORM_SUBJECT_INFORMATIONS_REQUEST_LABEL"
                            )}
                          >
                            {translate(
                              "CONTACT_FORM_SUBJECT_INFORMATIONS_REQUEST_LABEL"
                            )}
                          </option>
                          <option
                            value={translate(
                              "CONTACT_FORM_SUBJECT_COMMERCIAL_CONTACT_LABEL"
                            )}
                          >
                            {translate(
                              "CONTACT_FORM_SUBJECT_COMMERCIAL_CONTACT_LABEL"
                            )}
                          </option>
                          <option
                            value={translate(
                              "CONTACT_FORM_SUBJECT_PRESSE_LABEL"
                            )}
                          >
                            {translate("CONTACT_FORM_SUBJECT_PRESSE_LABEL")}
                          </option>
                          <option
                            value={translate(
                              "CONTACT_FORM_SUBJECT_OTHERS_LABEL"
                            )}
                          >
                            {translate("CONTACT_FORM_SUBJECT_OTHERS_LABEL")}
                          </option>
                        </select>
                        {this.state.errors.subject &&
                          this.state.errors.subject != "" && (
                            <p className="error-label">
                              {this.state.errors.subject}
                            </p>
                          )}
                      </div>
                      <div className="input-container">
                        <p className="label">
                          {translate("CONTACT_FORM_COMPANY_LABEL")}
                        </p>
                        <input
                          className={
                            this.state.errors.company &&
                            this.state.errors.company != ""
                              ? "input-large-error"
                              : "input-large"
                          }
                          type="text"
                          value={this.state.company}
                          onChange={this.handleCompanyChange}
                          placeholder={translate("CONTACT_FORM_COMPANY_LABEL")}
                        />
                        {this.state.errors.company &&
                          this.state.errors.company != "" && (
                            <p className="error-label">
                              {this.state.errors.company}
                            </p>
                          )}
                      </div>
                      <div className="input-container">
                        <p className="label">
                          {translate("CONTACT_FORM_MESSAGE_LABEL")}
                          <span className="required-tag">*</span>
                        </p>
                        <textarea
                          className={
                            this.state.errors.message &&
                            this.state.errors.message != ""
                              ? "input-large-error area"
                              : "input-large area"
                          }
                          value={this.state.message}
                          onChange={this.handleMessageChange}
                          placeholder={translate("MESSAGE_PLACEHOLDER")}
                        ></textarea>
                        {this.state.errors.message &&
                          this.state.errors.message != "" && (
                            <p className="error-label">
                              {this.state.errors.message}
                            </p>
                          )}
                      </div>
                      <div className="input-container">
                        <label
                          className={
                            this.state.hasCheckedAllInformation
                              ? "checkbox-field-small-padding-checked"
                              : "checkbox-field-small-padding"
                          }
                        >
                          <div>
                            <input
                              className="checkboxBtn"
                              type="checkbox"
                              value={this.state.hasCheckedAllInformation}
                              checked={this.state.hasCheckedAllInformation}
                              onChange={
                                this.handleHasCheckedAllInformationChange
                              }
                            />
                          </div>
                          <span>
                            {translate("LAST_STEP_BEFORE_END_CHECK_INFO_LABEL")}{" "}
                            <a
                              className="magentine-link"
                              href="/data-policy"
                              target="_blank"
                            >
                              {translate("LAST_STEP_BEFORE_END_RGPD_LABEL")}
                            </a>
                          </span>
                        </label>
                        {this.state.errors.hasCheckedAllInformation &&
                          this.state.errors.hasCheckedAllInformation != "" && (
                            <p className="error-label">
                              {this.state.errors.hasCheckedAllInformation}
                            </p>
                          )}
                      </div>
                    </div>
                    {/* [MAGE.ALERT.WA.6 - 1.21] */}
                    {this.state.errors.formError &&
                      this.state.errors.formError && (
                        <div className="form-error-container">
                          <p className="form-error-label">
                            {this.state.errors.formError}
                          </p>
                        </div>
                      )}
                    <div>
                      <p className="requireLabel">
                        <span className="required-tag">*</span>{" "}
                        {translate("REQUIRE_LABEL")}
                      </p>
                      <button type="submit" className="btnMain">
                        {translate("BUTTON_SEND_LABEL")}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            )}
            {this.state.msgSent && (
              <div>
                <p className="title-min">{translate("MESSAGE_SENT_LABEL")}</p>
                <div className="btn-center-min">
                  {/* [MAGE.COM.WA.88 - 1.22] */}
                  <CustomLink
                    className="btnMain"
                    tag="button"
                    to={{
                      pathname: "/",
                    }}
                  >
                    {translate("BUTTON_HOME_LABEL")}
                  </CustomLink>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}

export default ContactForm;
