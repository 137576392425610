export function format_ssn(ssn) {
    var secuPart = [];

    secuPart.push(
        ssn.substring(0, 1),
        ssn.substring(1, 3),
        ssn.substring(3, 5),
        ssn.substring(5, 7),
        ssn.substring(7, 10),
        ssn.substring(10, 13),
        ssn.substring(13, 15)
    );

    return secuPart.join(" ");
}

export function format_patientID(patientID) {
    var idPart = [];

    idPart.push(
        patientID.substring(0, 4),
        patientID.substring(4, 8),
        patientID.substring(8, 11)
    );

    return idPart.join(" ");
}