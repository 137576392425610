// [MAGE.COM.WA.41 - 1.11]
//Identity Form Step 3 validation rules
//Used to validate form before move to next form
export function validationRules(values, translate) {

  const errors = {};

  /* 
    [MAGE.COM.WA.33 - 1.11]
    Accommodation type validation
  */
  if (values.accommodationType.trim() === "") {
    errors.accommodationType = translate("REQUIRED_FIELD")
  }

  /* 
    [MAGE.COM.WA.36 - 1.11]
    Accommodation Street Name validation
  */
  if (values.secondStreetNumber.trim() === "") {
    errors.secondStreetNumber = translate("REQUIRED_FIELD")
  } else if (values.secondStreetNumber.trim().length > 255) {
    errors.secondStreetNumber = 'Max. 255 ch.'
  }

  /* 
    [MAGE.COM.WA.37 - 1.11]
    Accommodation City Zip Code validation
  */
  if (values.zipCode.trim() === "") {
    errors.zipCode = translate("REQUIRED_FIELD")
  } else if (values.zipCode.trim().length > 10) {
    errors.zipCode = 'Max. 10 ch.'
  }

  /* 
    [MAGE.COM.WA.38 - 1.11]
    Accommodation City validation
  */
  if (values.city.trim() === "") {
    errors.city = translate("REQUIRED_FIELD")
  } else if (values.city.trim() !== "" && values.city.trim().length > 30) {
    errors.city = 'Max. 30 ch.'
  }

  /* 
    [MAGE.COM.WA.39 - 1.11]
    Accommodation Temporary Residence validation
  */
  if (values.livingOut) {
    if (values.outZipCode.trim() === "") {
      errors.outZipCode = translate("REQUIRED_FIELD")
    } else if (values.outZipCode.trim().length > 10) {
      errors.outZipCode = 'Max. 10 ch.'
    }
  }

  /*
    [MAGE.COM.WA.28 - 1.8]
    Last Days Country validation
  */
  if (values.hasBeenInOtherCountry === "") {
    errors.hasBeenInOtherCountry = translate("REQUIRED_FIELD");
  } else {
    if (values.hasBeenInOtherCountry) {
      if (values.lastVisitedCountry.trim() === "") {
        errors.lastVisitedCountry = translate("REQUIRED_FIELD");
      }
    }
  }

  /* 
    []
    Accommodation Country validation
  */
  if (values.accommodationContry.trim() === "") {
    errors.accommodationContry = translate("REQUIRED_FIELD")
  } else if(values.accommodationContry === "FR" && (values.zipCode.length !== 5 || !values.zipCode.match(/^[0-9]*$/))) {
    errors.zipCode = translate("INVALID_FRANCE_ZIP_CODE");
  }

  if (Object.keys(errors).length !== 0) {
    // [MAGE.ALERT.WA.9 - 1.11]
    errors.formError = translate("FORM_ERROR");
  }

  return errors
}

//Used for redux form validation
const validateThirdStep = values => {
  return validationRules(values);
}

export default validateThirdStep