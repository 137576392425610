/*
    This file is used to perform all http request
*/

export function doGet(url, headers = {}) {
    return fetch(url,
        {
            method: 'GET',
            headers: headers,
        })
        .then(response => {
            if (!response.ok) throw new Error(response.status)
            else return response.json()
        })
        .then(
            (result) => {
                return { "error": false, "data": result };
            },
            (error) => {
                return { "error": true, "data": error }
            }
        )
}

export function doPost(url, body, headers = null) {
    return fetch(url,
        {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        })
        .then(response => {
            if (!response.ok) throw new Error(response.status)
            else return response.json()
        })
        .then(
            (result) => {
                console.log("Res: " + result)
                return { "error": false, "data": result };
            },
            (error) => {
                console.log("Err: " + error)
                return { "error": true, "data": error }
            }
        )
}

export function download(url) {
    return fetch(url,
        {
            method: 'GET',
        })
        // 1. Convert the data into 'blob'
        .then((response) => response.blob())
        .then((blob) => {
            // 2. Create blob link to download
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `MTR-PATIENT-CODE.pdf`);  // 3. Append to html page
            document.body.appendChild(link);  // 4. Force download
            link.click();  // 5. Clean up and remove the link
            link.parentNode.removeChild(link);
        })
}