import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)
function showAlert(dataToShow) {


  return MySwal.fire({
    width: 600,
    showCloseButton: true,
    title: dataToShow.title,
    html: "<img alt='Custom image' class='image-modal' src='" + dataToShow.image + "'>",
    showConfirmButton: false,
    backdrop: 'rgba(255, 255, 255, 0.5)',
    closeButtonHtml: "<span className='close-modal-btn'>×</span>"
  })

}

export default showAlert;