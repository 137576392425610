import React from 'react';
import { withRouter } from 'react-router-dom';
import parse from 'html-react-parser';
import * as validation from '../../utils/ValidationRules';
import { validationRules } from '../../utils/validation_forms/IdentityForm/email_verification_validation';
import { getDefaultLanguage } from '../../utils/language';
import infoIcon from '../../assets/mtr_images/Icone_info.png';
import * as HttpRequest from '../../utils/HttpRequest';
import config from '../../config/load_config';
import ModalErrorData from '../../components/Modals/modal_no_data';

/**
 * This component is the user email verification page
 * [MAGE.COM.WA.15 - 1.6] and [MAGE.COM.WA.17 - 1.6] and [MAGE.COM.WA.81 - 1.6] and [MAGE.COM.WA.89 - 1.6] and [MAGE.ALERT.WA.7 - 1.6]
 * @component
 */
class EmailVerification extends React.Component {
    constructor(props) {
        super(props);

        this.textKey = "";

        this.state = {
            email: "",
            confirmationEmail: "",
            isSent: false,
            isNoRouteError: false,
            userType: "",
            errors: {}
        };
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleConfirmationEmailChange = this.handleConfirmationEmailChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.verifyEmail = this.verifyEmail.bind(this);
        this.resendEmail = this.resendEmail.bind(this);
        this.openErrorDataModal = this.openErrorDataModal.bind(this);
    }

    componentDidMount() {
        if (this.props.history.location['state'] && this.props.history.location['state'] != undefined) {
            this.setState({ userType: this.props.history.location.state.userType })

            switch(this.props.history.location.state.userType){
                case "self":
                    this.textKey = "EMAIL_VERIFICATION_SELF_LABEL"
                break;
                case "other":
                    this.textKey = "EMAIL_VERIFICATION_OTHER_LABEL"
                break;
                case "specialist":
                    this.textKey = "EMAIL_VERIFICATION_SPECIALIST_LABEL"
                break;
                default:
                    this.textKey = "EMAIL_VERIFICATION_LABEL"
                break;
            }
        } else {
            this.props.history.push({
                pathname: "/error"
            });
        }
    }

    openErrorDataModal = () => {
        const dataToShow = {
            title: this.props.translate("ERROR_DATA_TITLE_LABEL"),
            description: this.props.translate("ERROR_DATA_SUBTITLE_LABEL"),
            btnYesText: this.props.translate("ERROR_DATA_BUTTON_OK_LABEL"),
        };
        ModalErrorData(dataToShow);
    }

    async verifyEmail() {
        var errors = this.state.errors;

        const toSend = {
            email: this.state.email,
            targetPage: window.location.origin + config.EMAIL_CONFIRMATION_TARGET,
            language: getDefaultLanguage()
        }

        //Construct JSON Object to send
        const response = await HttpRequest.doPost(process.env.REACT_APP_API_BASE_URL + config.API.EMAIL_VERIFICATION, toSend, {
            "Access-Control-Allow-Origin": "*",
            "content-type": "application/json"
        });

        console.log(response);
        if (response.error) {
            this.setState({ isSent: false });
            this.openErrorDataModal();
        } else {
            this.setState({ isSent: true });
        }
    }

    /**
     * [MAGE.COM.WA.17 - 1.6]
     */
    resendEmail() {
        //TODO
    }

    /**
     * [MAGE.COM.WA.15 - 1.6]
     */
    handleEmailChange(e) {
        var errors = this.state.errors;
        if (validation.charLengthValidation(e.target.value, 40)) {
            errors.email = "";
            this.setState({ errors: errors, email: e.target.value });
        }

    }

    handleConfirmationEmailChange(e) {
        var errors = this.state.errors;
        if (validation.charLengthValidation(e.target.value, 40)) {
            errors.confirmationEmail = "";
            this.setState({ errors: errors, confirmationEmail: e.target.value });
        }
    }

    handlePaste(e) {
        e.preventDefault();
        e.nativeEvent.stopImmediatePropagation();
    }

    /**
     * [MAGE.COM.WA.16 - 1.6] and [MAGE.COM.WA.81 - 1.6]
     */
    handleSubmit(event) {
        //send email
        const dataToValidate = {
            email: this.state.email,
            confirmationEmail: this.state.confirmationEmail,
        };
        // [MAGE.ALERT.WA.7 - 1.6]
        var errorList = validationRules(dataToValidate, this.props.translate);
        if (Object.keys(errorList).length !== 0) {
            this.setState({ errors: errorList });
        } else {
            if (this.state.userType == "specialist") {
                this.props.history.push({
                    pathname: "/patient-register",
                    state: { email: this.state.email }
                });
            } else {
                this.verifyEmail();
            }

        }

    }

    render() {
        const { translate, device } = this.props;
        return (
            <span>
                {!this.state.isSent && device === "lap/desk" && <>
                    <div className="container">
                        <div className="content">
                            <p className="title">{translate(this.textKey)}</p>
                            <div>
                                <div className="input-zone">
                                    <p className="label">{translate("EMAIL_VERIFICATION_FIELD_LABEL")}<span className="required-tag">*</span></p>
                                    {/* [MAGE.ALERT.WA.10 - 1.6] */}
                                    {this.state.errors.email && this.state.errors.email !== "" &&
                                        <p className="error-label">{this.state.errors.email}</p>}
                                    <input type="email" onCopy={this.handlePaste} onDrag={this.handlePaste} onDrop={this.handlePaste} onPaste={this.handlePaste} autocomplete="off"
                                        className={this.state.errors.email && this.state.errors.email !== "" ? "input-field-error" : "input-field"}
                                        placeholder={translate("EMAIL_VERIFICATION_PLACEHOLDER_LABEL")} maxLength="40" value={this.state.email}
                                        onChange={this.handleEmailChange} />
                                </div>
                                <div className="input-zone">
                                    <p className="label">{translate("EMAIL_VERIFICATION_CONFIRMATION_FIELD_LABEL")}<span className="required-tag">*</span></p>
                                    {/* [MAGE.ALERT.WA.10 - 1.6] */}
                                    {this.state.errors.confirmationEmail && this.state.errors.confirmationEmail !== "" &&
                                        <p className="error-label">{this.state.errors.confirmationEmail}</p>}
                                    {/* [MAGE.COM.WA.89 - 1.6] */}
                                    <input type="email" onCopy={this.handlePaste} onDrag={this.handlePaste} onDrop={this.handlePaste} onPaste={this.handlePaste} autocomplete="off"
                                        className={this.state.errors.confirmationEmail && this.state.errors.confirmationEmail !== "" ? "input-field-error" : "input-field"}
                                        placeholder={translate("EMAIL_VERIFICATION_PLACEHOLDER_LABEL")} maxLength="40" value={this.state.confirmationEmail}
                                        onChange={this.handleConfirmationEmailChange} />
                                </div>
                            </div>
                            <div>
                                <button type="button" className="btnMain" onClick={this.handleSubmit}>{translate("BUTTON_OK_LABEL")}</button>
                            </div>
                            {this.state.userType !== "specialist" && <div className="row-input">
                                <p className="normal-text">{translate("SEND_VERIFICATION_EMAIL_LABEL")}</p>
                            </div>}
                            <div className="row-input">
                                <img className="notice-icon" src={infoIcon} alt="Notice Icon" />
                                <p className="colored-text">{translate("EMAIL_VERIFICATION_INFORMATION_LABEL")}</p>
                            </div>
                        </div>
                    </div></>}
                {this.state.isSent && device === "lap/desk" && <>
                    <div className="container">
                        <div className="content">
                            <p className="title">{translate("EMAIL_VERIFICATION_SENT_LABEL").replace("{EMAIL_ADDRESS}", this.state.email)}</p>
                            <div>
                                <p className="normal-text">{parse(translate("EMAIL_VERIFICATION_NOT_RECEIVED_LABEL"))}</p>
                            </div>
                        </div>
                    </div></>}
                {!this.state.isSent && device === "phone/tablet" && <>
                    <div className="row-input-min">
                        <div className="container-min">

                            <p className="title-min">{translate(this.textKey)}</p>
                            <div className="container-min">
                                <div>
                                    <p className="label">{translate("EMAIL_VERIFICATION_FIELD_LABEL")}<span className="required-tag">*</span></p>
                                    {/* [MAGE.ALERT.WA.10 - 1.6] */}
                                    {this.state.errors.email && this.state.errors.email !== "" &&
                                        <p className="error-label">{this.state.errors.email}</p>}
                                    <span>
                                        <input name="email" type="email" onCopy={this.handlePaste} onDrag={this.handlePaste} onDrop={this.handlePaste} onPaste={this.handlePaste} autocomplete="off"
                                            className={this.state.errors.email && this.state.errors.email !== "" ? "input-large-error" : "input-large"}
                                            placeholder={translate("EMAIL_VERIFICATION_PLACEHOLDER_LABEL")} maxLength="40" value={this.state.email}
                                            onChange={this.handleEmailChange} />
                                    </span>
                                </div>
                                <div>
                                    <p className="label">{translate("EMAIL_VERIFICATION_CONFIRMATION_FIELD_LABEL")}<span className="required-tag">*</span></p>
                                    {/* [MAGE.ALERT.WA.10 - 1.6] */}
                                    {this.state.errors.confirmationEmail && this.state.errors.confirmationEmail !== "" &&
                                        <p className="error-label">{this.state.errors.confirmationEmail}</p>}
                                    <span>
                                        {/* [MAGE.COM.WA.89 - 1.6] */}
                                        <input type="email" onCopy={this.handlePaste} onDrag={this.handlePaste} onDrop={this.handlePaste} onPaste={this.handlePaste} autocomplete="off"
                                            className={this.state.errors.confirmationEmail && this.state.errors.confirmationEmail !== "" ? "input-large-error" : "input-large"}
                                            placeholder={translate("EMAIL_VERIFICATION_PLACEHOLDER_LABEL")} maxLength="40" value={this.state.confirmationEmail}
                                            onChange={this.handleConfirmationEmailChange} />
                                    </span>
                                </div>
                            </div>
                            {this.state.userType !== "specialist" && <p className="normal-text-min">{translate("SEND_VERIFICATION_EMAIL_LABEL")}</p>}
                            <div className="btn-center-min">
                                <button type="button" className="btnMain" onClick={this.handleSubmit}>{translate("BUTTON_OK_LABEL")}</button>
                            </div>
                            <div className="notice-icon-container-min">
                                <img className="notice-icon-min" src={infoIcon} alt="Notice Icon" />
                            </div>
                            <p className="colored-text-min">{translate("EMAIL_VERIFICATION_INFORMATION_LABEL")}</p>


                            <p className="test-rapide-link">{translate("WEBSITE_TEXT")}</p>
                        </div>
                    </div></>}
                {this.state.isSent && device === "phone/tablet" && <>
                    <div className="row-input-min">
                        <div className="content-min">
                            <div className="container-min">
                                <p className="title-min">{translate("EMAIL_VERIFICATION_SENT_LABEL").replace("{EMAIL_ADDRESS}", this.state.email)}</p>
                                <p className="normal-text-min">{parse(translate("EMAIL_VERIFICATION_NOT_RECEIVED_LABEL"))}</p>
                            </div>
                            <p className="test-rapide-link">{translate("WEBSITE_TEXT")}</p>
                        </div>
                    </div></>}

            </span>
        );
    }
}

export default withRouter(EmailVerification);