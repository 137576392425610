import React from 'react';
import '../styles/home.scss';
import CustomLink from './CustomLink';

/**
 * This component is the footer of Home Page
 * [MAGE.COM.WA.7 - 1.1] and [MAGE.COM.WA.8 - 1.1]
 * @component
 */
class Footer extends React.Component {

    render() {
        const { device, translate } = this.props;
        return (
            <span>
                { device === "lap/desk" &&
                    <div className="footer">
                        <div className="footer-row-content">
                            <CustomLink className="footer-text" tag="a" to="#">{translate("FOOTER_COPYRIGHT_TEXT") + " " + new Date().getFullYear()}</CustomLink>
                            {/**
                            * [MAGE.COM.WA.7 - 1.1]
                            */}
                            <CustomLink className="footer-text" tag="a" to="/legal-informations-policy">
                                {translate("FOOTER_MENTIONS_LEGAL_TEXT")}
                            </CustomLink>
                            {/**
                            * [MAGE.COM.WA.8 - 1.1]
                            */}
                            <CustomLink className="footer-text" tag="a" to="/data-policy">
                                {translate("FOOTER_DATA_POLICY_TEXT")}
                            </CustomLink>
                            <CustomLink className="footer-text" tag="a" to="/cookies-policy">
                                {translate("FOOTER_COOKIES_TEXT")}
                            </CustomLink>
                        </div>
                    </div>
                }
                { device === "phone/tablet" &&
                    <div className="footer-min">
                        <div className="container-min">
                            <CustomLink className="footer-text" tag="p" to="#">{translate("FOOTER_COPYRIGHT_TEXT") + " " + new Date().getFullYear()}</CustomLink>
                            {/**
                            * [MAGE.COM.WA.7 - 1.1]
                            */}
                            <CustomLink className="footer-text" tag="p" to="/legal-informations-policy">
                                {translate("FOOTER_MENTIONS_LEGAL_TEXT")}
                            </CustomLink>
                            {/**
                            * [MAGE.COM.WA.8 - 1.1]
                            */}
                            <CustomLink className="footer-text" tag="p" to="/data-policy">
                                {translate("FOOTER_DATA_POLICY_TEXT")}
                            </CustomLink>
                            <CustomLink className="footer-text" tag="p" to="/cookies-policy">
                                {translate("FOOTER_COOKIES_TEXT")}
                            </CustomLink>
                        </div>
                    </div>
                }
            </span>
        );
    }
}

export default Footer