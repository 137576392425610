import React, { useState } from 'react';

const BirthDateComponent = ({ tag: Tag, 
    translate, 
    dayName, 
    dayValue, 
    dayOnError,
    dayOnChange,
    monthName, 
    monthValue, 
    monthOnError,
    monthOnChange,
    yearName, 
    yearValue, 
    yearOnError,
    yearOnChange,
    onFieldError, 
    ...rest }) => {

    const [isDayClicked, setIsDayClicked] = useState(false);
    const [isYearClicked, setIsYearClicked] = useState(false);

    const handleDayFocused = () => {
        setIsDayClicked(true);
    }

    const handleDayBlur = () => {
        setIsDayClicked(false);
    }

    const handleYearFocused = () => {
        setIsYearClicked(true);
    }

    const handleYearBlur = () => {
        setIsYearClicked(false);
    }

    return (<>
        <div className="date-field">
            <Tag name={dayName} component="input" type="tel" onFocus={handleDayFocused} onBlur={handleDayBlur} maxLength="2"
                className={dayOnError && dayOnError !== "" ? "input-large-error day-field" : "input-large day-field"}
                placeholder={isDayClicked ? translate("DATE_FORMAT_DAY_MINIFIED") : translate("DATE_FORMAT_DAY")} value={dayValue} onChange={dayOnChange} />
            <Tag name={monthName} component="select"
                className={monthOnError && monthOnError !== "" ? "input-large-error month-field" : "input-large month-field"}
                placeholder={translate("DATE_FORMAT_MONTH")} value={monthValue} onChange={monthOnChange}>
                <option value="">{translate("DATE_FORMAT_MONTH")}</option>
                <option value="01">{translate("MONTH_JANUARY")}</option>
                <option value="02">{translate("MONTH_FEBRUARY")}</option>
                <option value="03">{translate("MONTH_MARCH")}</option>
                <option value="04">{translate("MONTH_APRIL")}</option>
                <option value="05">{translate("MONTH_MAY")}</option>
                <option value="06">{translate("MONTH_JUNE")}</option>
                <option value="07">{translate("MONTH_JULY")}</option>
                <option value="08">{translate("MONTH_AUGUST")}</option>
                <option value="09">{translate("MONTH_SEPTEMBER")}</option>
                <option value="10">{translate("MONTH_OCTOBER")}</option>
                <option value="11">{translate("MONTH_NOVEMBER")}</option>
                <option value="12">{translate("MONTH_DECEMBER")}</option>
            </Tag>
            <Tag name={yearName} component="input" type="tel" onFocus={handleYearFocused} onBlur={handleYearBlur} maxLength="4"
                className={yearOnError && yearOnError !== "" ? "input-large-error year-field" : "input-large year-field"}
                placeholder={isYearClicked ? translate("DATE_FORMAT_YEAR_MINIFIED") : translate("DATE_FORMAT_YEAR")} value={yearValue} onChange={yearOnChange} />
        </div>
        {
            onFieldError && onFieldError !== "" &&
            <p className="error-label">{onFieldError}</p>
        }
    </>)
}

export default BirthDateComponent;