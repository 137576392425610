// [MAGE.COM.WA.56 - 1.13]
//Identity Form Step 6 validation rules
//Used to validate form before move to next form
export function validationRules(values, translate) {

  const errors = {};

  /*
    Identity recap validation
  */

  //[MAGE.COM.WA.55 - 1.13]
  if (!values.giveConsent) {
    errors.giveConsent = translate("REQUIRED_FIELD")
  }

  if (Object.keys(errors).length !== 0) {
    errors.formError = translate("FORM_ERROR");
  }


  return errors
}

const validateSixthStep = values => {
  return validationRules(values);
}

export default validateSixthStep;