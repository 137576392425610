export function formatDate(day, month, year, format) {
    if (format.split("-").length !== 1) {
        console.log("Intl Format");
        return year + "-" + month + "-" + (day.length < 2 ? "0" + day : day);
    } else {
        return (day.length < 2 ? "0" + day : day) + "/" + month + "/" + year;
    }
}

export function formatDateToString(date) {
    // 01, 02, 03, ... 29, 30, 31
    var dd = (date.getDate() < 10 ? '0' : '') + date.getDate();
    // 01, 02, 03, ... 10, 11, 12
    var MM = ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1);
    // 1970, 1971, ... 2015, 2016, ...
    var yyyy = date.getFullYear();

    // create the format you want
    return (dd + "/" + MM + "/" + yyyy);
}

export function parseDate(date) {
    var parts = date.split("/");
    var date = new Date(parts[1] + "/" + parts[0] + "/" + parts[2]);
    return date.getTime();
}