// [MAGE.COM.WA.30 - 1.8]
//Identity Form Step 1 validation rules
//Used to validate form before move to next form
import SSN from "french-ssn";
import { parseDate } from "../../Date";

Date.prototype.isValid = function () {
  // An invalid date object returns NaN for getTime() and NaN is the only
  // object not strictly equal to itself.
  return this.getTime() === this.getTime();
};

export function validationRules(values, translate) {
  const errors = {};
  if (values.gender.trim() === "") {
    errors.gender = translate("REQUIRED_FIELD");
  }

  /*
    [MAGE.COM.WA.20 - 1.8]
    First name validation  
  */
  if (values.firstName.trim() === "") {
    errors.firstName = translate("REQUIRED_FIELD");
  } else if (values.firstName.trim().length > 30) {
    errors.firstName = "Max. 30 ch.";
  }

  /*
    [MAGE.COM.WA.18 - 1.8]
    [MAGE.COM.WA.19 - 1.8]
    Last name validation  
  */
  if (values.lastName.trim() === "") {
    errors.lastName = translate("REQUIRED_FIELD");
  } else if (values.firstName.trim().length > 30) {
    errors.firstName = "Max. 30 ch.";
  }

  /*
    [MAGE.COM.WA.18 - 1.8]
    [MAGE.COM.WA.19 - 1.8]
    Birth name validation  
  */
  if (values.birthName.trim() !== "" && values.birthName.trim().length > 30) {
    errors.birthName = "Max. 30 ch.";
  }

  if (values.dtDay.trim() === "") {
    errors.birthDate = errors.dtDay = translate("REQUIRED_FIELD_DAY");
  } else if (
    !/[0-9]+/g.test(values.dtDay.trim()) ||
    values.dtDay.trim().length > 2
  ) {
    errors.birthDate = errors.dtDay = translate("INVALID_BIRTHDATE_DAY");
  }

  if (values.dtMonth.trim() === "") {
    errors.birthDate = errors.dtMonth = translate("REQUIRED_FIELD_MONTH");
  } else if (
    !/[0-9]+/g.test(values.dtMonth.trim()) ||
    values.dtMonth.trim().length !== 2
  ) {
    errors.birthDate = errors.dtMonth = translate("INVALID_BIRTHDATE_MONTH");
  }

  if (values.dtYear.trim() === "") {
    errors.birthDate = errors.dtYear = translate("REQUIRED_FIELD_YEAR");
  } else if (
    !/[0-9]+/g.test(values.dtYear.trim()) ||
    values.dtYear.trim().length !== 4
  ) {
    errors.birthDate = errors.dtYear = translate("INVALID_BIRTHDATE_YEAR");
  }

  if (
    values.dtDay.trim() !== "" &&
    values.dtMonth.trim() !== "" &&
    values.dtYear.trim() !== ""
  ) {
    const date = values.dtYear + "-" + values.dtMonth + "-" + values.dtDay;
    if (
      new Date(date).getTime() >= new Date().getTime() ||
      new Date(date).getTime() < new Date("1900-01-01").getTime()
    ) {
      errors.birthDate =
        errors.dtDay =
        errors.dtMonth =
        errors.dtYear =
          translate("INVALID_BIRTHDATE_INTERVAL");
    } else if (!new Date(date).isValid()) {
      errors.birthDate =
        errors.dtDay =
        errors.dtMonth =
        errors.dtYear =
          translate("INVALID_BIRTHDATE");
    }
  }

  /*
    [MAGE.COM.WA.27 - 1.8] and [MAGE.COM.WA.82 - 1.8]
    Security Social Number validation  
  */
  if (values.hasNir === "") {
    errors.hasNir = translate("REQUIRED_FIELD");
  } else {
    if (values.hasNir) {
      if (values.nir.trim() === "") {
        errors.nir = translate("REQUIRED_FIELD");
      } else if (values.nir.trim().length > 15) {
        // [MAGE.ALERT.WA.11 - 1.8]
        errors.nir = "Max. 15 ch.";
      } else if (!SSN.validate(values.nir.trim())) {
        errors.nir = translate("INVALID_SECURITY_SOCIAL_NUMBER");
      } else if (values.nir.trim().substr(0, 5) === "15555") {
        errors.nir = translate("INVALID_SECURITY_SOCIAL_NUMBER");
      }
    }
  }

  /*
    Email validation
  */
  if (values.email.trim() === "") {
    errors.email = translate("REQUIRED_FIELD");
  } else if (
    !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      values.email
    )
  ) {
    errors.email = translate("INVALID_EMAIL");
  }

  /*
    Phone number validation
  */
  if (!values.phoneNumber || values.phoneNumber?.trim() === "") {
    errors.phoneNumber = translate("REQUIRED_FIELD");
  } else if (values.phoneNumber?.trim().length < 8) {
    errors.phoneNumber = "Min. 8 ch.";
  }

  if (Object.keys(errors).length !== 0) {
    // [MAGE.ALERT.WA.9 - 1.8]
    errors.formError = translate("FORM_ERROR");
  }

  return errors;
}

//Used for redux form validation
const validateFirstStep = (values) => {
  return validationRules(values);
};

export default validateFirstStep;
