import React from 'react';
import accommodationConfig from '../../config_component/accomodation_config.json';
import AccommodationTypeWithBorder from './AccommodationTypeWithBorderComponent';

/**
 * This component is using to manage accommodation type
 * [MAGE.COM.WA.33 - 1.11]
 * @component
 */

const AccommodationType = ({ tag: Tag, translate, name, value,  onChange, textValue, ...rest }) => {

    //Get accommodationList
    const accommodationTypeList = accommodationConfig;

    return (
        <>{
            accommodationTypeList.map(acc => {
                let accKey = acc.id;
                let translationKey = acc.value;
                let accValue = translate(acc.value);

                return (
                    <div>
                        <AccommodationTypeWithBorder tag={Tag} accKey={accKey} accType={value} accValue={accValue} textValue={translationKey} fieldKey={name} onChange={onChange} />
                    </div>
                );
            })
        } </>
    );
}

export default AccommodationType;