// [MAGE.COM.WA.32 - 1.8]
//Identity Form Step 2 validation rules
//Used to validate form before move to next form
export function validationRules(values, translate) {

  const errors = {};

  /*
    [MAGE.COM.WA.32 - 1.8]
    Test field validation  
  */
  if (values.testType.trim() === "") {
    errors.testType = translate("REQUIRED_FIELD")
  }

  if (values.hasGiveTestDeviceConsent === false) {
    errors.hasGiveTestDeviceConsent = translate("REQUIRED_FIELD")
  }

  if (Object.keys(errors).length !== 0) {
    {/* [MAGE.ALERT.WA.13 - 1.10] */}
    errors.formError = translate("FORM_ERROR");
  }

  return errors
}

//Used for redux form validation
const validateSecondStep = values => {
  return validationRules(values);
}

export default validateSecondStep