import React from 'react';
const Cookies = (props) => {

    const { device, translate } = props;
    return (
        <span>
            { device === "lap/desk" && <>
                <div className="content-container">
                    <p className="title">{translate("COOKIES_POLICY_INFORMATION_TITLE")}</p>
                    <div className="text-container">{translate("COOKIES_POLICY_INFORMATION_TEXT")}</div>
                    <div className="separator"></div>
                    <p className="title">{translate("COOKIES_POLICY_UTILITY_TITLE")}</p>
                    <div className="text-container">{translate("COOKIES_POLICY_UTILITY_TEXT")}</div>
                    <div className="separator"></div>
                    <p className="title">{translate("COOKIES_POLICY_TECHNIQUES_TITLE")}</p>
                    <div className="text-container">{translate("COOKIES_POLICY_TECHNIQUES_TEXT")}</div>
                    <div className="separator"></div>
                    <p className="title">{translate("COOKIES_POLICY_MEASURES_TITLE")}</p>
                    <div className="text-container">{translate("COOKIES_POLICY_MEASURES_TEXT")}</div>
                </div>
            </>}
            { device === "phone/tablet" && <>
                <div className="content-container">
                    <p className="title-min">{translate("COOKIES_POLICY_INFORMATION_TITLE")}</p>
                    <div className="text-container">{translate("COOKIES_POLICY_INFORMATION_TEXT")}</div>
                    <div className="separator"></div>
                    <p className="title-min">{translate("COOKIES_POLICY_UTILITY_TITLE")}</p>
                    <div className="text-container">{translate("COOKIES_POLICY_UTILITY_TEXT")}</div>
                    <div className="separator"></div>
                    <p className="title-min">{translate("COOKIES_POLICY_TECHNIQUES_TITLE")}</p>
                    <div className="text-container">{translate("COOKIES_POLICY_TECHNIQUES_TEXT")}</div>
                    <div className="separator"></div>
                    <p className="title-min">{translate("COOKIES_POLICY_MEASURES_TITLE")}</p>
                    <div className="text-container">{translate("COOKIES_POLICY_MEASURES_TEXT")}</div>
                </div>
            </>}
        </span>
    );
}

export default Cookies;