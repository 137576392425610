import React from 'react';
import { withRouter } from 'react-router-dom';
import parse from 'html-react-parser';
import infoIcon from '../assets/info-icon/group-7@3x.png';
import imgDoctors from '../assets/doctors/group-5.png';
import CustomLink from './CustomLink';

/**
 * This component is the home page of the application
 * [MAGE.COM.WA.9 - 1.1] and [MAGE.ALERT.WA.8 - 1.4]
 * @component
 */
class Home extends React.Component {
    constructor(props) {
        super(props);
    }

    // [MAGE.ALERT.WA.8 - 1.4] - TODO

    render() {
        const { translate, device } = this.props;
        return (
            <span>
                {device === "lap/desk" && <>
                    <div className="home-container">
                        <div className="home-content">
                            <p className="home-title">{parse(translate("HOME_TITLE"))}</p>
                            <div>
                                <ul className="list-description">
                                    <li className="list-element">{parse(translate("HOME_DESCRIPTION_TEXT_1"))}</li>
                                    <li className="list-element">{parse(translate("HOME_DESCRIPTION_TEXT_2"))}</li>
                                    <li className="list-element">{parse(translate("HOME_DESCRIPTION_TEXT_3"))}</li>
                                </ul>
                            </div>
                            <div className="btn-go-container">
                                <CustomLink tag="button" className="home-btnSecondary" to="/patient">{translate("BUTTON_GO_LABEL")}</CustomLink>
                            </div>
                            <div className="row-content">
                                <img className="home-notice-icon" src={infoIcon} alt="Notice Icon" />
                                <p className="text-title">{translate("HOME_IMPORTANT_TIPS_LABEL")}</p>
                            </div>
                            <p className="home-normal-text">{translate("HOME_IMPORTANT_TIPS_1")}</p>
                            <p className="home-normal-text">{translate("HOME_IMPORTANT_TIPS_2")}</p>
                        </div>
                    </div></>}
                {device === "phone/tablet" && <>
                    <div className="home-row-input-min">
                        <div className="container-min">
                            <div className="home-img-zone">
                                <img src={imgDoctors}
                                    alt="Img Doctors"
                                    className="home-img" />
                            </div>
                            <p className="home-title">{parse(translate("HOME_TITLE"))}</p>
                            <div className="btn-go-container">
                                <CustomLink tag="button" className="home-btnSecondary" to="/patient">{translate("BUTTON_GO_LABEL")}</CustomLink>
                            </div>
                            <div>
                                <ul className="list-description">
                                    <li className="list-element">{parse(translate("HOME_DESCRIPTION_TEXT_1"))}</li>
                                    <li className="list-element">{parse(translate("HOME_DESCRIPTION_TEXT_2"))}</li>
                                    <li className="list-element">{parse(translate("HOME_DESCRIPTION_TEXT_3"))}</li>
                                </ul>
                            </div>
                            <div className="home-row-content">
                                <img className="home-notice-icon" src={infoIcon} alt="Notice Icon" />
                                <p className="text-title">{translate("HOME_IMPORTANT_TIPS_LABEL")}</p>
                            </div>
                            <p className="home-normal-text">{translate("HOME_IMPORTANT_TIPS_1")}</p>
                            <p className="home-normal-text">{translate("HOME_IMPORTANT_TIPS_2")}</p>

                        </div>
                    </div></>}
            </span>
        );
    }
}

export default withRouter(Home);