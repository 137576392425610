import React from 'react';
import { Field, reduxForm, change } from 'redux-form'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import countryList from 'react-select-country-list';
import validateThirdStep from '../../utils/validation_forms/IdentityForm/validate_third_step'
import { validationRules } from '../../utils/validation_forms/IdentityForm/validate_third_step';
import * as validation from '../../utils/ValidationRules'
import help from '../../assets/group-5/group-5.png'
import help2x from '../../assets/group-5/group-5@2x.png'
import help3x from '../../assets/group-5/group-5@3x.png'
import streetNumberImage from '../../assets/mtr_images/Adresse_num.png'
import complementImage from '../../assets/mtr_images/Adresse_complement.png'
import streetNameImage from '../../assets/mtr_images/Adresse_voie.png'
import AccommodationTypeComponent from '../AccommodationType/AccommodationTypeCompnent'
import ModalImage from '../Modals/modal_image';


/**
 * This component is using to manage user accommodation informations
 * [MAGE.COM.WA.33 - 1.11] to [MAGE.COM.WA.42 - 1.11] and [MAGE.COM.WA.90 - 1.10] and [MAGE.ALERT.WA.1 - 1.11]
 * @component
 */
class Accommodation extends React.Component {
    constructor(props) {
        super(props);

        //Get list of country
        this.listOfCountry = countryList().getData();

        this.state = props.forms.hasOwnProperty("wizard") ? {
            accommodationType: props.forms.wizard.values.accommodationType ? props.forms.wizard.values.accommodationType : "I",
            accommodationTypeValue: props.forms.wizard.values.accommodationTypeValue ? props.forms.wizard.values.accommodationTypeValue : "",
            firstStreetNumber: props.forms.wizard.values.firstStreetNumber ? props.forms.wizard.values.firstStreetNumber : "",
            secondStreetNumber: props.forms.wizard.values.secondStreetNumber ? props.forms.wizard.values.secondStreetNumber : "",
            complement: props.forms.wizard.values.complement ? props.forms.wizard.values.complement : "",
            city: props.forms.wizard.values.city ? props.forms.wizard.values.city : "",
            accommodationContry: props.forms.wizard.values.accommodationCountry ? props.forms.wizard.values.accommodationCountry : "",
            zipCode: props.forms.wizard.values.zipCode ? props.forms.wizard.values.zipCode : "",
            livingOut: props.forms.wizard.values.livingOut ? true : false,
            outZipCode: props.forms.wizard.values.outZipCode ? props.forms.wizard.values.outZipCode : "",
            isModalStreetNumberShowing: false,
            isModalComplementShowing: false,
            isModalStreetNameShowing: false,
            hasBeenInOtherCountry: props.forms.wizard.values.hasBeenInOtherCountry ? (props.forms.wizard.values.hasBeenInOtherCountry === "true" ? true : false) : "",
            lastVisitedCountry: props.forms.wizard.values.lastVisitedCountry ? props.forms.wizard.values.lastVisitedCountry : "",
            listOfCountry: this.listOfCountry,
            errors: {}
        } : {
                accommodationType: "I",
                accommodationTypeValue: "",
                firstStreetNumber: "",
                secondStreetNumber: "",
                complement: "",
                city: "",
                accommodationContry: "",
                zipCode: "",
                livingOut: false,
                outZipCode: "",
                isModalStreetNumberShowing: false,
                isModalComplementShowing: false,
                isModalStreetNameShowing: false,
                hasBeenInOtherCountry: "",
                lastVisitedCountry: "",
                listOfCountry: this.listOfCountry,
                errors: {}
            };
        this.handleAccommodationChange = this.handleAccommodationChange.bind(this);
        this.handleFirstStreetNumberChange = this.handleFirstStreetNumberChange.bind(this);
        this.handleSecondStreetNumberChange = this.handleSecondStreetNumberChange.bind(this);
        this.handleComplementChange = this.handleComplementChange.bind(this);
        this.handleCityChange = this.handleCityChange.bind(this);
        this.handleAccommodationCountryChange = this.handleAccommodationCountryChange.bind(this);
        this.handleLastVisitedCountryChange = this.handleLastVisitedCountryChange.bind(this);
        this.handleOtherCountryChange = this.handleOtherCountryChange.bind(this);
        this.handleZipCodeChange = this.handleZipCodeChange.bind(this);
        this.handleOutZipCodeChange = this.handleOutZipCodeChange.bind(this);
        this.handleLivingOutChange = this.handleLivingOutChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handlePreviousPage = this.handlePreviousPage.bind(this);
    }

    componentDidMount() {
        this.props.change("accommodationTypeValue", this.props.translate("ACCOMMODATION_TYPE_INDIVIDUAL_LABEL"));
    }

    openModalHandler(values) {
        ModalImage(values);
    }

    /**
     * [MAGE.COM.WA.33 - 1.11]
     */
    handleAccommodationChange(e) {
        var errors = this.state.errors;
        errors.accommodationType = "";
        errors.formError = "";
        this.setState({ errors: errors, accommodationType: e.target.value, accommodationTypeValue: e.target.getAttribute("textValue") });
        this.props.change("accommodationTypeValue", e.target.getAttribute("textValue"));
    }

    /**
     * [MAGE.COM.WA.34 - 1.11]
     */
    handleFirstStreetNumberChange(e) {
        var errors = this.state.errors;
        if (validation.charLengthValidation(e.target.value, 4)) {
            errors.firstStreetNumber = "";
            errors.formError = "";
            this.setState({ errors: errors, firstStreetNumber: e.target.value });
        }
    }

    /**
     * [MAGE.COM.WA.36 - 1.11]
     */
    handleSecondStreetNumberChange(e) {
        var errors = this.state.errors;
        if (validation.charLengthValidation(e.target.value, 255)) {
            errors.secondStreetNumber = "";
            errors.formError = "";
            this.setState({ errors: errors, secondStreetNumber: e.target.value });
        }
    }

    /**
     * [MAGE.COM.WA.35 - 1.11]
     */
    handleComplementChange(e) {
        var errors = this.state.errors;
        errors.complement = "";
        errors.formError = "";
        this.setState({ errors: errors, complement: e.target.value });
    }

    /**
     * [MAGE.COM.WA.38 - 1.11]
     */
    handleCityChange(e) {
        var errors = this.state.errors;
        if (validation.charLengthValidation(e.target.value, 30) && e.target.value.match(/^[A-Za-z\s]*$/)) {
            errors.city = "";
            errors.formError = "";
            this.setState({ errors: errors, city: e.target.value });
            this.props.change("city", e.target.value);
        }
    }

    /**
     * [MAGE.COM.WA.37 - 1.11]
     */
    handleZipCodeChange(e) {
        var errors = this.state.errors;
        if (validation.charLengthValidation(e.target.value, 10)) {
            errors.zipCode = "";
            errors.formError = "";
            this.setState({ errors: errors, zipCode: e.target.value });
        }
    }

    /**
     * [MAGE.COM.WA.40 - 1.11]
     */
    handleOutZipCodeChange(e) {
        var errors = this.state.errors;
        if (validation.charLengthValidation(e.target.value, 10)) {
            errors.outZipCode = "";
            errors.formError = "";
            this.setState({errors: errors, outZipCode: e.target.value});
        }
    }

    /**
     * [MAGE.COM.WA.39 - 1.11]
     */
    handleLivingOutChange(e) {
        this.setState({ livingOut: !this.state.livingOut });
    }


    handleAccommodationCountryChange(e) {
        var errors = this.state.errors;
        errors.accommodationContry = "";
        errors.formError = "";
        this.setState({ errors: errors, accommodationContry: e.target.value });

    }

    /**
     * [MAGE.COM.WA.25 - 1.8]
     */
    handleLastVisitedCountryChange(e) {
        var errors = this.state.errors;
        errors.lastVisitedCountry = "";
        errors.formError = "";
        this.setState({ errors: errors, lastVisitedCountry: e.target.value });
    }

    /**
     * [MAGE.COM.WA.25 - 1.8]
     */
    handleOtherCountryChange(e) {

        var errors = this.state.errors;
        var choice = "";

        if (e.target.value === "true")
            choice = true;
        else
            choice = false;

        errors.hasBeenInOtherCountry = "";
        errors.formError = "";
        this.setState({ hasBeenInOtherCountry: choice, errors: errors });
    }

    /**
     * [MAGE.COM.WA.41 - 1.11]
     */
    handleSubmit(e) {
        const dataToValidate = {
            accommodationType: this.state.accommodationType,
            secondStreetNumber: this.state.secondStreetNumber,
            complement: this.state.complement,
            city: this.state.city,
            accommodationContry: this.state.accommodationContry,
            hasBeenInOtherCountry: this.state.hasBeenInOtherCountry,
            lastVisitedCountry: this.state.lastVisitedCountry,
            zipCode: this.state.zipCode,
            livingOut: this.state.livingOut,
            outZipCode: this.state.outZipCode,
        };

        var errorList = validationRules(dataToValidate, this.props.translate);
        if (Object.keys(errorList).length !== 0) {
            this.setState({ errors: errorList });

        } else {
            this.props.onSubmit();
        }

        e.preventDefault();
    }

    /**
     * [MAGE.COM.WA.42 - 1.11] and [MAGE.COM.WA.90 - 1.10]
     */
    handlePreviousPage(e) {
        e.preventDefault();
        this.props.previousPage();
    }

    render() {
        const { previousPage, translate, device } = this.props;
        let countryList = this.state.listOfCountry;
        const toReplace = {value: "ES", label: translate("ES")};
        countryList = countryList.map(country => country.value !== toReplace.value ? country : toReplace);
        countryList.sort(function(a, b) {
            return a.label.localeCompare(b.label);
        });
        return (
            <div>
                {device === "lap/desk" && <>
                    <p className="title">{translate("ACCOMMODATION_LABEL")}</p>
                    <div>
                        <form onSubmit={this.handleSubmit}>
                            <div className="fieldContainer">
                                <div className="input-container">
                                    {/* [MAGE.COM.WA.33 - 1.11] */}
                                    <p className="label">{translate("ACCOMMODATION_TYPE_LABEL")}<span className="required-tag">*</span></p>
                                    {this.state.errors.accommodationType && this.state.errors.accommodationType != "" &&
                                        <p className="error-label">{this.state.errors.accommodationType}</p>}
                                    <AccommodationTypeComponent name="accommodationType" tag={Field} value={this.state.accommodationType} onChange={this.handleAccommodationChange} translate={translate} textValue={this.state.accommodationTypeValue} />
                                    <Field name="accommodationTypeValue" component="input" type="hidden" value="" />
                                </div>
                            </div>
                            {/* [MAGE.COM.WA.36 - 1.11] */}
                            <div className="row-input">
                                <div className="zone-input">
                                    <p className="label">{translate("ACCOMMODATION_ADDRESS_LABEL")}<span className="required-tag">*</span></p>
                                    <div className="input-with-icon">
                                        <Field name="secondStreetNumber" component="input" placeholder={translate("ACCOMMODATION_ADDRESS_LABEL")}
                                            className={this.state.errors.secondStreetNumber && this.state.errors.secondStreetNumber != "" ? "input-large-error" : "input-large"}
                                            type="text" value={this.state.secondStreetNumber} onChange={this.handleSecondStreetNumberChange}
                                            maxLength="255" />
                                    </div>
                                    {this.state.errors.secondStreetNumber && this.state.errors.secondStreetNumber != "" &&
                                        <p className="error-label">{this.state.errors.secondStreetNumber}</p>}
                                </div>
                            </div>
                            <div className="input-container">
                                {/* [MAGE.COM.WA.38 - 1.11] */}
                                <p className="label">{translate("ACCOMMODATION_CITY_LABEL")}<span className="required-tag">*</span></p>
                                <input
                                    type={"text"}
                                    placeholder={translate("ACCOMMODATION_CITY_LABEL")}
                                    className={this.state.errors.city && this.state.errors.city != "" ? "input-large-error" : "input-large"}
                                    value={this.state.city}
                                    onChange={this.handleCityChange}
                                    maxLength="30" />
                                <Field name="city" component="input" type="hidden"
                                       value={""} />
                                {this.state.errors.city && this.state.errors.city != "" &&
                                    <p className="error-label">{this.state.errors.city}</p>}
                            </div>
                            {/* [MAGE.COM.WA.37 - 1.11] */}
                            <div className="input-container">
                                <p className="label">{translate("ACCOMMODATION_ZIP_CODE_LABEL")}<span className="required-tag">*</span></p>
                                <Field
                                    className={this.state.errors.zipCode && this.state.errors.zipCode != "" ? "input-large-error" : "input-large"}
                                    name="zipCode"
                                    component="input"
                                    placeholder={translate("ACCOMMODATION_ZIP_CODE_LABEL")}
                                    type="text"
                                    value={this.state.zipCode}
                                    onChange={this.handleZipCodeChange}
                                    maxLength="10" />
                                {this.state.errors.zipCode && this.state.errors.zipCode != "" &&
                                    <p className="error-label">{this.state.errors.zipCode}</p>}
                            </div>
                            <div className="input-container">
                                <p className="label">{translate("ACCOMMODATION_COUNTRY_LABEL")}<span className="required-tag">*</span></p>
                                <Field name="accommodationCountry" component="select"
                                    className={this.state.errors.accommodationContry && this.state.errors.accommodationContry != "" ? "input-large-error" : "input-large"}
                                    value={this.state.accommodationContry} onChange={this.handleAccommodationCountryChange}>
                                    {
                                        [
                                            <option value="">{translate("NATIONALITY_CHOOSE_LABEL")}</option>,
                                            countryList.map(cty => {
                                                if (cty.value == "FR") {
                                                    return (
                                                        <option value={cty.value} selected>{cty.label}</option>
                                                    );
                                                } else {
                                                    return (
                                                        <option value={cty.value}>{cty.label}</option>
                                                    );
                                                }
                                            })
                                        ]
                                    }
                                </Field>
                                {this.state.errors.accommodationContry && this.state.errors.accommodationContry != "" &&
                                    <p className="error-label">{this.state.errors.accommodationContry}</p>}
                            </div>
                            <div className="input-container">
                                    <p className="label">{translate("QUESTION_LAST_COUNTRY_LABEL")}<span className="required-tag">*</span></p>
                                    <div>
                                        <label className={this.state.hasBeenInOtherCountry === true ? "radio-field-checked" : "radio-field"}><Field name="hasBeenInOtherCountry" component="input" type="radio" className="radioBtn" value={true}
                                            checked={this.state.hasBeenInOtherCountry === true} onChange={this.handleOtherCountryChange} /> {translate("RESPONSE_YES_TO_LAST_COUNTRY_LABEL")}</label>
                                    </div>
                                    <div>
                                        <label className={this.state.hasNir === false ? "radio-field-checked" : "radio-field"}><Field name="hasBeenInOtherCountry" component="input" type="radio" className="radioBtn" value={false}
                                            checked={this.state.hasBeenInOtherCountry === false} onChange={this.handleOtherCountryChange} /> {translate("RESPONSE_NO_TO_LAST_COUNTRY_LABEL")}</label>
                                    </div>
                                    {this.state.errors.hasBeenInOtherCountry && this.state.errors.hasBeenInOtherCountry !== "" &&
                                        <p className="error-label">{this.state.errors.hasBeenInOtherCountry}</p>}
                                </div>
                                {this.state.hasBeenInOtherCountry === true &&
                                    <div className="input-container">
                                        <p className="label">{translate("LAST_COUNTRY_LABEL")}<span className="required-tag">*</span></p>

                                        <Field name="lastVisitedCountry" component="select"
                                            className={this.state.errors.lastVisitedCountry && this.state.errors.lastVisitedCountry !== "" ? "input-large-error" : "input-large"}
                                            value={this.state.lastVisitedCountry} onChange={this.handleLastVisitedCountryChange}>
                                            {
                                                [
                                                    <option value="">{translate("NATIONALITY_CHOOSE_LABEL")}</option>,
                                                    countryList.map(cty => {
                                                        if (cty.value == "FR") {
                                                            return (
                                                                <option value={cty.value} selected>{cty.label}</option>
                                                            );
                                                        } else {
                                                            return (
                                                                <option value={cty.value}>{cty.label}</option>
                                                            );
                                                        }
                                                    })
                                                ]
                                            }
                                        </Field>
                                        {this.state.errors.lastVisitedCountry && this.state.errors.lastVisitedCountry !== "" &&
                                            <p className="error-label">{this.state.errors.lastVisitedCountry}</p>}
                                    </div>}

                            <div className="input-container mid-box">
                                <label className={this.state.livingOut ? "radio-field-small-padding-checked" : "radio-field-small-padding"}>
                                    <div><Field name="livingOut" component="input"
                                        className="checkboxBtn" type="checkbox" value={this.state.livingOut}
                                        checked={this.state.livingOut} onChange={this.handleLivingOutChange} /></div>
                                    {translate("ACCOMMODATION_LIVING_OUT_LABEL")}
                                </label>
                            </div>
                            {this.state.livingOut &&
                                <div className="input-container">
                                    <p className="label">{translate("ACCOMMODATION_ZIP_CODE_LABEL")}<span className="required-tag">*</span></p>
                                    <Field name="outZipCode" component="input" placeholder={translate("ACCOMMODATION_ZIP_CODE_LABEL")}
                                        className={this.state.errors.outZipCode && this.state.errors.outZipCode != "" ? "input-large-error" : "input-large"}
                                        type="text" value={this.state.outZipCode} onChange={this.handleOutZipCodeChange} />
                                    {this.state.errors.outZipCode && this.state.errors.outZipCode != "" &&
                                        <p className="error-label">{this.state.errors.outZipCode}</p>}
                                </div>}
                            {/* [MAGE.ALERT.WA.1 - 1.11] */}
                            {this.state.errors.formError && this.state.errors.formError && <div className="form-error-container">
                                <p className="form-error-label">{this.state.errors.formError}</p>
                            </div>}
                            <div>
                                <p className="requireLabel"><span className="required-tag">*</span> {translate("REQUIRE_LABEL")}</p>
                                <button type="button" className="btnSecondary" onClick={this.handlePreviousPage}>{translate("BUTTON_BACK_LABEL")}</button>
                                <button type="submit" className="btnMain">{translate("BUTTON_NEXT_LABEL")}</button>
                            </div>

                        </form>
                    </div>
                </>}
                {device === "phone/tablet" && <>
                    <div className="container-min">
                        <p className="title-min">{translate("ACCOMMODATION_LABEL")}</p>
                        <form onSubmit={this.handleSubmit}>
                            <div className="fieldContainer">
                                <div className="input-container">
                                    {/* [MAGE.COM.WA.33 - 1.11] */}
                                    <p className="label">{translate("ACCOMMODATION_TYPE_LABEL")}<span className="required-tag">*</span></p>
                                    {this.state.errors.accommodationType && this.state.errors.accommodationType != "" &&
                                        <p className="error-label">{this.state.errors.accommodationType}</p>}
                                    <AccommodationTypeComponent name="accommodationType" tag={Field} value={this.state.accommodationType} onChange={this.handleAccommodationChange} translate={translate} textValue={this.state.accommodationTypeValue} />
                                    <Field name="accommodationTypeValue" component="input" type="hidden" value="" />
                                </div>
                            </div>
                            {/* [MAGE.COM.WA.36 - 1.11] */}
                            <div className="row-input">
                                <div className="zone-input">
                                    <p className="label">{translate("ACCOMMODATION_ADDRESS_LABEL")}<span className="required-tag">*</span></p>
                                    <div className="input-with-icon">
                                        <Field name="secondStreetNumber" component="input" placeholder={translate("ACCOMMODATION_ADDRESS_LABEL")}
                                            className={this.state.errors.secondStreetNumber && this.state.errors.secondStreetNumber != "" ? "input-large-error" : "input-large"}
                                            type="text" value={this.state.secondStreetNumber} onChange={this.handleSecondStreetNumberChange}
                                            maxLength="255" />
                                    </div>
                                    {this.state.errors.secondStreetNumber && this.state.errors.secondStreetNumber != "" &&
                                        <p className="error-label">{this.state.errors.secondStreetNumber}</p>}
                                </div>
                            </div>
                            <div className="input-container">
                                {/* [MAGE.COM.WA.38 - 1.11] */}
                                <p className="label">{translate("ACCOMMODATION_CITY_LABEL")}<span className="required-tag">*</span></p>
                                <input
                                    type={"text"}
                                    placeholder={translate("ACCOMMODATION_CITY_LABEL")}
                                    className={this.state.errors.city && this.state.errors.city != "" ? "input-large-error" : "input-large"}
                                    value={this.state.city}
                                    onChange={this.handleCityChange}
                                    maxLength="30" />
                                <Field name="city" component="input" type="hidden"
                                       value={""} />
                                {this.state.errors.city && this.state.errors.city != "" &&
                                    <p className="error-label">{this.state.errors.city}</p>}
                            </div>
                            {/* [MAGE.COM.WA.37 - 1.11] */}
                            <div className="input-container">
                                <p className="label">{translate("ACCOMMODATION_ZIP_CODE_LABEL")}<span className="required-tag">*</span></p>
                                <Field
                                    className={this.state.errors.zipCode && this.state.errors.zipCode != "" ? "input-large-error" : "input-large"}
                                    name="zipCode"
                                    component="input"
                                    placeholder={translate("ACCOMMODATION_ZIP_CODE_LABEL")}
                                    type="text"
                                    value={this.state.zipCode}
                                    onChange={this.handleZipCodeChange}
                                    maxLength="10" />
                                {this.state.errors.zipCode && this.state.errors.zipCode != "" &&
                                    <p className="error-label">{this.state.errors.zipCode}</p>}
                            </div>
                            <div className="input-container">
                                <p className="label">{translate("ACCOMMODATION_COUNTRY_LABEL")}<span className="required-tag">*</span></p>
                                <Field name="accommodationCountry" component="select"
                                    className={this.state.errors.accommodationContry && this.state.errors.accommodationContry != "" ? "input-large-error" : "input-large"}
                                    value={this.state.accommodationContry} onChange={this.handleAccommodationCountryChange}>
                                    {
                                        [
                                            <option value="">{translate("NATIONALITY_CHOOSE_LABEL")}</option>,
                                            countryList.map(cty => {
                                                if (cty.value == "FR") {
                                                    return (
                                                        <option value={cty.value} selected>{cty.label}</option>
                                                    );
                                                } else {
                                                    return (
                                                        <option value={cty.value}>{cty.label}</option>
                                                    );
                                                }
                                            })
                                        ]
                                    }
                                </Field>
                                {this.state.errors.accommodationContry && this.state.errors.accommodationContry != "" &&
                                    <p className="error-label">{this.state.errors.accommodationContry}</p>}
                            </div>
                            <div className="input-container">
                                    <p className="label">{translate("QUESTION_LAST_COUNTRY_LABEL")}<span className="required-tag"><span className="required-tag">*</span></span></p>
                                    <div>
                                        <label className={this.state.hasBeenInOtherCountry === true ? "radio-field-checked" : "radio-field"}><Field name="hasBeenInOtherCountry" component="input" type="radio" className="radioBtn" value={true}
                                            checked={this.state.hasBeenInOtherCountry === true} onChange={this.handleOtherCountryChange} /> {translate("RESPONSE_YES_TO_LAST_COUNTRY_LABEL")}</label>
                                    </div>
                                    <div>
                                        <label className={this.state.hasNir === false ? "radio-field-checked" : "radio-field"}><Field name="hasBeenInOtherCountry" component="input" type="radio" className="radioBtn" value={false}
                                            checked={this.state.hasBeenInOtherCountry === false} onChange={this.handleOtherCountryChange} /> {translate("RESPONSE_NO_TO_LAST_COUNTRY_LABEL")}</label>
                                    </div>
                                    {this.state.errors.hasBeenInOtherCountry && this.state.errors.hasBeenInOtherCountry !== "" &&
                                        <p className="error-label">{this.state.errors.hasBeenInOtherCountry}</p>}
                                </div>
                                {this.state.hasBeenInOtherCountry === true &&
                                    <div className="input-container">
                                        <p className="label">{translate("LAST_COUNTRY_LABEL")}<span className="required-tag">*</span></p>

                                        <Field name="lastVisitedCountry" component="select"
                                            className={this.state.errors.lastVisitedCountry && this.state.errors.lastVisitedCountry !== "" ? "input-large-error" : "input-large"}
                                            value={this.state.lastVisitedCountry} onChange={this.handleLastVisitedCountryChange}>
                                            {
                                                [
                                                    <option value="">{translate("NATIONALITY_CHOOSE_LABEL")}</option>,
                                                    countryList.map(cty => {
                                                        if (cty.value == "FR") {
                                                            return (
                                                                <option value={cty.value} selected>{cty.label}</option>
                                                            );
                                                        } else {
                                                            return (
                                                                <option value={cty.value}>{cty.label}</option>
                                                            );
                                                        }
                                                    })
                                                ]
                                            }
                                        </Field>
                                        {this.state.errors.lastVisitedCountry && this.state.errors.lastVisitedCountry !== "" &&
                                            <p className="error-label">{this.state.errors.lastVisitedCountry}</p>}
                                    </div>}
                            
                            <div className="input-container">
                                <label className={this.state.livingOut ? "radio-field-checked" : "radio-field"}>
                                    <div><Field name="livingOut" component="input" className="checkboxBtn" type="checkbox" value={this.state.livingOut}
                                        checked={this.state.livingOut} onChange={this.handleLivingOutChange} /></div>
                                    {translate("ACCOMMODATION_LIVING_OUT_LABEL")}
                                </label>
                            </div>
                            {this.state.livingOut &&
                                <div className="input-container">
                                    <p className="label">{translate("ACCOMMODATION_ZIP_CODE_LABEL")}<span className="required-tag">*</span></p>
                                    <Field name="outZipCode" component="input" placeholder={translate("ACCOMMODATION_ZIP_CODE_LABEL")}
                                        className={this.state.errors.outZipCode && this.state.errors.outZipCode != "" ? "input-large-error" : "input-large"}
                                        type="text" value={this.state.outZipCode} onChange={this.handleOutZipCodeChange} />
                                    {this.state.errors.outZipCode && this.state.errors.outZipCode != "" &&
                                        <p className="error-label">{this.state.errors.outZipCode}</p>}
                                </div>}
                                
                            {/* [MAGE.ALERT.WA.1 - 1.11] */}
                            {this.state.errors.formError && this.state.errors.formError && <div className="form-error-container">
                                <p className="form-error-label">{this.state.errors.formError}</p>
                            </div>}
                            <div>
                                <p className="requireLabel"><span className="required-tag"><span className="required-tag">*</span></span> {translate("REQUIRE_LABEL")}</p>
                                <div className="wizard-buttons-container">
                                    <button type="button" className="btnSecondary" onClick={this.handlePreviousPage}>{translate("BUTTON_BACK_LABEL")}</button>
                                    <button type="submit" className="btnMain">{translate("BUTTON_NEXT_LABEL")}</button>
                                </div>
                            </div>

                        </form>
                    </div>
                </>}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        forms: state.form
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            change
        }, dispatch)
    };
}

export default reduxForm({
    form: 'wizard',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validateThirdStep
})(connect(
    mapStateToProps,
    null
)(Accommodation));