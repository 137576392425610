import React from 'react';
import CustomLink from '../CustomLink';
import imgPatient from '../../assets/group-9/group-9.png';
import imgDoctor from '../../assets/group-15/group-15.png';

/**
 * This component is the user type selection page
 * [MAGE.COM.WA.11 - 1.5] and [MAGE.COM.WA.14 - 1.5]
 * @component
 */
const UserType = (props) => {

    const { translate, device } = props
    return (
        <span>
            {device === "lap/desk" && <>
                <div className="row-input">
                    <span className="content">
                        <p className="title">{translate("USER_TYPE_LABEL")}</p>
                        <div className="row-input">
                            {/* [MAGE.COM.WA.11 - 1.5] */}
                            <div className="zone-input">
                                <CustomLink className="whoBtn" tag="div" to={{
                                    pathname: "/selection"
                                }}>
                                    <img className="whoBtnContent btn-image" alt="Icone User" src={imgPatient} />
                                    <p className="whoBtnContent btn-title">{translate("USER_TYPE_PATIENT_LABEL")}</p>
                                    <p className="whoBtnContent btn-sub-title">{translate("USER_TYPE_PATIENT_DESCRIPTION_LABEL")}</p>
                                </CustomLink>
                            </div>
                            {/* [MAGE.COM.WA.14 - 1.5] */}
                            <div className="zone-input">
                                <CustomLink className="whoBtn" tag="div" to={{
                                    pathname: "/email-verification",
                                    state: {userType: "specialist"}
                                }}>
                                    <img className="whoBtnContent btn-image" alt="Icone Doctor" src={imgDoctor} />
                                    <p className="whoBtnContent btn-title">{translate("USER_TYPE_PROFESSIONAL_LABEL")}</p>
                                    <p className="whoBtnContent btn-sub-title">{translate("USER_TYPE_PROFESSIONAL_DESCRIPTION_LABEL")}</p>
                                </CustomLink>
                            </div>
                        </div>
                    </span>
                </div></>}
            {device === "phone/tablet" && <>
                <div className="row-input-min">
                    <div className="content-min">
                        <p className="title-min">{translate("USER_TYPE_LABEL")}</p>
                        {/* [MAGE.COM.WA.11 - 1.5] */}
                        <CustomLink className="whoBtn-min" tag="div" to={{
                            pathname: "/selection",
                        }}>
                            <img className="whoBtnContent btn-image" alt="Icone User" src={imgPatient} />
                            <p className="whoBtnContent btn-title">{translate("USER_TYPE_PATIENT_LABEL")}</p>
                            <p className="whoBtnContent btn-sub-title">{translate("USER_TYPE_PATIENT_DESCRIPTION_LABEL")}</p>
                        </CustomLink>
                        {/* [MAGE.COM.WA.14 - 1.5] */}
                        <CustomLink className="whoBtn-min" tag="div" to={{
                            pathname: "/email-verification",
                            state: {userType: "specialist"}
                        }}>
                            <img className="whoBtnContent btn-image" alt="Icone Doctor" src={imgDoctor} />
                            <p className="whoBtnContent btn-title">{translate("USER_TYPE_PROFESSIONAL_LABEL")}</p>
                            <p className="whoBtnContent btn-sub-title">{translate("USER_TYPE_PROFESSIONAL_DESCRIPTION_LABEL")}</p>
                        </CustomLink>
                        <p className="test-rapide-link">{translate("WEBSITE_TEXT")}</p>
                    </div>
                </div></>}
        </span>
    );
}

export default UserType;