import React from 'react';
import config from '../config/load_config';
import ReactFlagsSelect from 'react-flags-select';
import { getDefaultLanguage, setDefaultLanguage } from '../utils/language';
import { useTranslation } from "react-i18next";
import 'react-flags-select/css/react-flags-select.css';
import 'react-flags-select/scss/react-flags-select.scss';

/**
 * This component allow user to switch language over the application
 * [MAGE.COM.WA.94 - TOUS]
 * @component
 */
const ChooseLanguages = (props) => {

    const { i18n } = useTranslation('common');

    var defaultLanguage = "";
    const countriesList = config.LANGUAGES.countriesCode;
    const customLabels = config.LANGUAGES.customLabels;
    const labels = {};

    if (countriesList.length === customLabels.length) {
        customLabels.forEach((label) => {
            labels[label.key] = props.translate(label.translationKey);
        })
    }

    customLabels.forEach((label) => {
        if (label.languageKey === getDefaultLanguage()) {
            defaultLanguage = label.key;
        }
    })

    const handleOnSelect = (countryCode) => {
        customLabels.forEach((label) => {
            if (label.key === countryCode) {
                setDefaultLanguage(label.languageKey);
                i18n.changeLanguage(label.languageKey);
            }
        });
    }

    return (
        <ReactFlagsSelect
            countries={countriesList}
            customLabels={labels}
            defaultCountry={defaultLanguage}
            onSelect={handleOnSelect}
            className={props.className ? props.className : ""}
        />
    );
}

export default ChooseLanguages;
