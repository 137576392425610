import React from 'react';
import User from '../../components/IdentityForm/UserComponent';
import Header from '../../components/HeaderComponent';
import LeftCard from '../../components/LeftCardComponent';
import Responsive from '../../components/ResponsiveComponent';
import imgDoctors from '../../assets/doctors/group-5@3x.png';
import '../../styles/buttons.scss';
import '../../styles/user_type.scss';
import '../../styles/standard_style.scss';

const UserType = (props) => {

    const { translate, onChangeLanguage } = props
    return (
        <Responsive
            webView={<>
                <Header translate={translate} isForm={true} device="lap/desk" />
                <div className="row">
                    <LeftCard
                        centeredImage={<img src={imgDoctors}
                            alt="Img Doctors"
                            className="left-image" />}
                    />
                    <div className="zone scrollable-zone">
                        <User translate={translate} device="lap/desk" onChangeLanguage={onChangeLanguage} />

                    </div>
                </div>
            </>}
            mobileView={<>
                <Header translate={translate} isForm={false} device="phone/tablet" />
                <div className="row-min">
                    <div className="zone-min">
                        <User translate={translate} device="phone/tablet" onChangeLanguage={onChangeLanguage} />
                    </div>
                </div>
            </>}
        />
    );
}

export default UserType;