import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

// [MAGE.ALERT.WA.2 - 1.6]
// [MAGE.ALERT.WA.2 - 1.8]
// [MAGE.ALERT.WA.2 - 1.9]
// [MAGE.ALERT.WA.2 - 1.11]
// [MAGE.ALERT.WA.2 - 1.12]
// [MAGE.ALERT.WA.2 - 1.13]
// [MAGE.ALERT.WA.2 - 1.14]
// [MAGE.ALERT.WA.2 - 1.15]
// [MAGE.ALERT.WA.2 - 1.18]
// [MAGE.ALERT.WA.2 - 1.19]
// [MAGE.ALERT.WA.2 - 1.21]
const MySwal = withReactContent(Swal)
function showAlert(dataToShow) {


  return MySwal.fire({
    width: 600,
    title: "<span class='abort-modal-title'>" + dataToShow.title + "</span>",
    text: dataToShow.description,
    icon: 'error',
    backdrop: 'rgba(255, 255, 255, 0.5)',
    showCancelButton: true,
    cancelButtonText: dataToShow.btnNoText,
    confirmButtonText: dataToShow.btnYesText
  }).then((result) => {
    if (result.isConfirmed) {
      window.location.href = '/';
    }
  })

}

export default showAlert;