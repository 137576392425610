import React from 'react';
import HomeComponent from '../components/HomeComponent';
import Header from '../components/HeaderComponent';
import LeftCard from '../components/LeftCardComponent';
import Responsive from '../components/ResponsiveComponent';
import Footer from '../components/FooterComponent';
import imgDoctors from '../assets/doctors/group-5@3x.png';
import '../styles/buttons.scss';
import '../styles/user_type.scss';
import '../styles/home.scss';

const Home = (props) => {

    const { translate, onChangeLanguage } = props
    return (
        <div className="body">
            <Responsive
                webView={<>
                    <Header translate={translate} isForm={false} device="lap/desk" />
                    <div className="row">
                        <LeftCard
                            centeredImage={<img src={imgDoctors}
                                alt="Img Doctors"
                                className="left-image" />}
                        />
                        <div className="zone scrollable-zone">
                            <HomeComponent translate={translate} device="lap/desk" onChangeLanguage={onChangeLanguage} />

                        </div>
                    </div>
                    <Footer translate={translate} isForm={false} device="lap/desk" />
                </>}
                mobileView={<>
                    <Header translate={translate} isForm={false} device="phone/tablet" />
                    <div className="row-min">
                        <div className="zone-min scrollable-zone">
                            <HomeComponent translate={translate} device="phone/tablet" onChangeLanguage={onChangeLanguage} />
                        </div>
                    </div>
                    <Footer translate={translate} isForm={false} device="phone/tablet" />
                </>}
            />
        </div>
    );
}

export default Home;