import React, {useEffect} from 'react';
import './styles/App.scss'
import {useTranslation} from 'react-i18next';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import IdentityWizard from './pages/IdentityForm/identity_wizard'
import GeneralUserType from './pages/IdentityForm/general_user_type'
import User from './pages/IdentityForm/patient_type';
import VerificationEmail from './pages/IdentityForm/email_verification';
import ConfirmationEmail from './pages/IdentityForm/email_confirmation';
import CookiesPolicy from './pages/Policies/cookies_policies';
import DataPolicy from './pages/Policies/data_policies';
import LegalInformationsPolicy from './pages/Policies/legal_informations_policies';
import LostResult from './pages/LostResultForm/result_wizard';
import ContactForm from './pages/ContactForm/contact_form';
import Home from './pages/home';
import Error from './pages/error';

function App() {

    //Initialize translate
    const {t, i18n} = useTranslation();

    const changeLanguage = code => {
        //i18n.changeLanguage(code);
    };

    useEffect(() => {
        if(process.env.NODE_ENV === "production") {
            if (
                !window.__ALLOW_REACT_DEVTOOLS__ &&
                window.__REACT_DEVTOOLS_GLOBAL_HOOK__ &&
                typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === "object"
            ) {
                for (let [key, value] of Object.entries(window.__REACT_DEVTOOLS_GLOBAL_HOOK__)) {
                    window.__REACT_DEVTOOLS_GLOBAL_HOOK__[key] = typeof value == "function" ? () => {
                    } : null;
                }
            }

            delete window.__ALLOW_REACT_DEVTOOLS__;
        }
    })

    return (
        <Router>
            <Switch>
                <Route exact path="/"
                       render={(props) => <Home translate={t} onChangeLanguage={changeLanguage} {...props} />}/>
                <Route exact path="/error"
                       render={(props) => <Error translate={t} onChangeLanguage={changeLanguage} {...props} />}/>
                <Route path="/patient" render={(props) => <GeneralUserType translate={t}
                                                                           onChangeLanguage={changeLanguage} {...props} />}/>
                <Route path="/selection"
                       render={(props) => <User translate={t} onChangeLanguage={changeLanguage} {...props} />}/>
                <Route path="/email-verification" render={(props) => <VerificationEmail translate={t}
                                                                                        onChangeLanguage={changeLanguage} {...props} />}/>
                <Route path="/email-confirmation" render={(props) => <ConfirmationEmail translate={t}
                                                                                        onChangeLanguage={changeLanguage} {...props} />}/>
                <Route path="/patient-register" render={(props) => <IdentityWizard translate={t}
                                                                                   onChangeLanguage={changeLanguage} {...props} />}/>
                <Route path="/cookies-policy" render={(props) => <CookiesPolicy translate={t}
                                                                                onChangeLanguage={changeLanguage} {...props} />}/>
                <Route path="/data-policy"
                       render={(props) => <DataPolicy translate={t} onChangeLanguage={changeLanguage} {...props} />}/>
                <Route path="/legal-informations-policy" render={(props) => <LegalInformationsPolicy translate={t}
                                                                                                     onChangeLanguage={changeLanguage} {...props} />}/>
                <Route path="/contact-us"
                       render={(props) => <ContactForm translate={t} onChangeLanguage={changeLanguage} {...props} />}/>
            </Switch>
        </Router>
    );
}

export default App;
