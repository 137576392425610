import React from 'react';
import Accommodation from '../../components/IdentityForm/AccommodationComponent';
import PropTypes from 'prop-types';
import ModalAbort from '../../components/Modals/modal_abort';
import Header from '../../components/HeaderComponent';
import Responsive from '../../components/ResponsiveComponent';
import LeftCard from '../../components/LeftCardComponent';
import group10 from '../../assets/group-10/group-10.png';
import group10_2x from '../../assets/group-10/group-10@2x.png';
import group10_3x from '../../assets/group-10/group-10@3x.png';
import progressStep3 from '../../assets/mtr_images/Etape 3.svg';
import '../../styles/identity.scss';
import '../../styles/forms_style.scss';

/**
 * This component is the third step of Identity Form
 * [MAGE.COM.WA.29 - 1.11]
 * @component
 */
const ThirdStep = (props) => {

    const { onSubmit, previousPage, translate } = props

    const openAbortModal = () => {
        const dataToShow = {
            title: translate("ABORT_TITLE_LABEL"),
            description: translate("ABORT_SUBTITLE_LABEL"),
            btnYesText: translate("ABORT_BUTTON_YES_LABEL"),
            btnNoText: translate("ABORT_BUTTON_NO_LABEL"),
        };
        ModalAbort(dataToShow);
    }

    // [MAGE.COM.WA.83 - 1.11] - TODO

    return (
        <Responsive
            webView={<>
                <Header translate={translate} isForm={true} device="lap/desk" />
                <div className="row">
                    <LeftCard
                        stepTitle={translate("STEP_TITLE_LABEL")}
                        stepSubTitle={translate("STEP_3_TITLE_LABEL")}
                        /* [MAGE.COM.WA.29 - 1.11] */
                        stepImage={<img alt="Step 3" src={progressStep3} className="step-image" />}
                        bottomImage={<img src={group10}
                            srcSet={group10_2x, group10_3x}
                            alt="Img Doctor"
                            className="Group-10" />}
                        websiteLink={translate("WEBSITE_TEXT")}
                    />
                    <div className="zone scrollable-zone">
                        <div className="abort-button-zone">
                            {/* [MAGE.COM.WA.80 - 1.11] */}
                            <div className="abort-button" onClick={openAbortModal}>x {translate("ABORT_LABEL")}</div>
                        </div>
                        <Accommodation onSubmit={onSubmit} device="lap/desk" previousPage={previousPage} translate={translate} />
                    </div>
                </div>
            </>}
            mobileView={<>
                <Header translate={translate} isForm={false} device="phone/tablet" isStepForm={true} subtitle={translate("STEP_TITLE_LABEL")} title={translate("STEP") + " 3/8 : " + translate("STEP_3_TITLE_LABEL")} />
                <div className="row">
                    <div className="zone scrollable-zone">
                        <div className="header-title-container">
                            <p className="header-subtitle">{translate("STEP_TITLE_LABEL")}</p>
                            <p className="header-title">{translate("STEP") + " 3/6 : " + translate("STEP_3_TITLE_LABEL")}</p>
                        </div>
                        <div className="abort-button-zone">
                            {/* [MAGE.COM.WA.80 - 1.11] */}
                            <div className="abort-button" onClick={openAbortModal}>x {translate("ABORT_LABEL")}</div>
                        </div>
                        <Accommodation onSubmit={onSubmit} device="phone/tablet" previousPage={previousPage} translate={translate} />

                    </div>
                </div>
            </>}
        />
    );
}

ThirdStep.propTypes = {
    onSubmit: PropTypes.func.isRequired
}

export default ThirdStep;