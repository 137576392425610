import React from 'react';
import { reset, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import parse from 'html-react-parser';
import qrImage from '../../assets/qrcode/group-10@3x.png';
import validateSecondStep from '../../utils/validation_forms/IdentityForm/validate_second_step';
import CustomLink from '../CustomLink';
import config from '../../config/load_config';
import * as HttpRequest from '../../utils/HttpRequest';
import { format_patientID } from '../../utils/format_ssn_id';

/**
 * This component is using to show user crendentials and download ID document
 * [MAGE.COM.WA.65 - 1.16] to [MAGE.COM.WA.68 - 1.16]
 * @component
 */
class Finish extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isDownloading: false,
            isDownloaded: false
        }

        this.handleDownload = this.handleDownload.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const { reset } = this.props;
        reset('wizard');
    }

    /**
     * [MAGE.COM.WA.67 - 1.16]
     * 
     * TODO
     */ 

    /**
     * [MAGE.COM.WA.65 - 1.16] and [MAGE.COM.WA.66 - 1.16]
     */ 
    handleDownload(e) {

        this.setState({ isDownloading: true, isDownloaded: false });
        const pdfEndPoint = config.API.PDF.replace(":id", this.props.patientID);

        //Download PDF
        HttpRequest.download(process.env.REACT_APP_API_BASE_URL + pdfEndPoint).then((value) => {
            this.setState({ isDownloading: false, isDownloaded: true });
        });

    }

    handleSubmit(event) {
        this.openModalHandler();
        //alert('Test sélectionné: ' + this.state.testType);
        event.preventDefault();
    }

    render() {
        var QRCode = require('qrcode.react');
        console.log(this.props.forms);
        const { translate, patientID, forms, device } = this.props;

        console.log("Patient ID: " + patientID);
        return (
            <div>
                {device === "lap/desk" && <>
                    <div className="row-input">
                        <p className="title">{translate("SIGN_UP_FINISHED")}</p>
                    </div>
                    <div className="row-input">
                        <p className="normal-text">{translate("YOUR_QRCODE_LABEL")}</p>
                    </div>
                    <div className="row-input">
                        <div className="zone-input qr-box">
                            <div className="qr-zone">
                                <div className="qr">
                                    <QRCode className="qr-image" value={patientID.toUpperCase()} size="80" />
                                </div>
                            </div>
                        </div>
                        <div className="zone-input">
                            <div className="id-zone">
                                <p className="label">{translate("PATIENT_ID_LABEL")} : {format_patientID(patientID.toUpperCase())}</p>
                                <button type="button" className="btnMain" onClick={this.handleDownload}>{!this.state.isDownloading ? translate("BUTTON_DOWNLOAD_LABEL") : translate("BUTTON_LAST_STEP_BEFORE_END_PENDING_LABEL")}</button>
                                {this.state.isDownloaded &&
                                    <p className="title">{translate("FILE_DOWNLOADED")}</p>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row-input">
                        <p className="normal-text">{parse(translate("QRCODE_NOTICE"))}</p>
                    </div>
                    <div>
                        {/* [MAGE.COM.WA.68 - 1.16] */}
                        <CustomLink tag="button" className="btnSecondary" to="/patient">{translate("BUTTON_NEW_PROFILE_LABEL")}</CustomLink>
                        <CustomLink tag="button" className="btnNormal" to="/">{translate("BUTTON_GO_HOME_LABEL")}</CustomLink>
                    </div>
                    <div className="buttons-zone">
                        {/* [MAGE.COM.WA.6 - 1.16] */}
                        <p className="normal-text">{translate("VISIT_MAGENTINE_HEALTH_CARE")} <a className="magentine-link" target="_blank" href={translate("MAGENTINE_HEALTH_CARE_URL")}>{translate("MAGENTINE_HEALTH_CARE")}</a></p>
                    </div>
                </>}
                {device === "phone/tablet" && <>
                    <div className="container-min">
                        <div className="row-input">
                            <p className="title-finish-min">{translate("SIGN_UP_FINISHED")}</p>
                        </div>
                        <div className="input-container">
                            <p className="normal-text-finish-min">{translate("YOUR_QRCODE_LABEL")}</p>
                        </div>
                        <div className="row-input qr-id-zone">
                            <div className="zone-input qr-box">
                                <div className="qr-zone">
                                    <div className="qr-min">
                                        <QRCode className="qr-image" value={patientID.toUpperCase()} size="40" />
                                    </div>
                                </div>
                            </div>
                            <div className="zone-input id-zone">
                                <p className="label">{translate("PATIENT_ID_LABEL")} : {format_patientID(patientID.toUpperCase())}</p>
                                <button type="button" className="btnMain" onClick={this.handleDownload}>{!this.state.isDownloading ? translate("BUTTON_DOWNLOAD_LABEL") : translate("BUTTON_LAST_STEP_BEFORE_END_PENDING_LABEL")}</button>
                                {this.state.isDownloaded &&
                                    <p className="title">{translate("FILE_DOWNLOADED")}</p>
                                }
                            </div>
                        </div>
                        <div className="input-container">
                            <p className="normal-text-finish-min">{parse(translate("QRCODE_NOTICE"))}</p>
                        </div>
                        <div className="row-input finish-buttons">
                            {/* [MAGE.COM.WA.68 - 1.16] */}
                            <CustomLink tag="button" className="btnSecondary" to="/patient">{translate("BUTTON_NEW_PROFILE_LABEL")}</CustomLink>
                            <CustomLink tag="button" className="btnNormal" to="/">{translate("BUTTON_GO_HOME_LABEL")}</CustomLink>
                        </div>
                        <div className="finish-footer">
                            {/* [MAGE.COM.WA.6 - 1.16] */}
                            <p className="normal-text">{translate("VISIT_MAGENTINE_HEALTH_CARE")} <a className="magentine-link" target="_blank" href={translate("MAGENTINE_HEALTH_CARE_URL")}>{translate("MAGENTINE_HEALTH_CARE")}</a></p>
                        </div>
                    </div>
                </>}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        forms: state.form
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            reset
        }, dispatch)
    };
}

export default reduxForm({
    form: 'wizard',
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    validateSecondStep
})(connect(
    mapStateToProps,
    mapDispatchToProps
)(Finish))