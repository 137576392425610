import React from 'react';
import { useMediaQuery } from 'react-responsive';

const Responsive = (props) => {
    // [MAGE.COM.WA.3 - TOUS]
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-device-width: 1224px)'
    })
    const isBigScreen = useMediaQuery({ query: '(min-device-width: 1824px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' })
    const isTabletOrMobileDevice = useMediaQuery({
        query: '(max-device-width: 1024px)'
    })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

    console.log(isBigScreen);
    console.log(isDesktopOrLaptop);
    console.log(isTabletOrMobile);
    console.log(isPortrait);
    console.log(isTabletOrMobileDevice);
    console.log(isRetina);

    const { webView, mobileView } = props;
    return (
        <>
            { isDesktopOrLaptop && (isTabletOrMobile ? mobileView : webView)}
            { isTabletOrMobileDevice && mobileView}
        </>
    );
}

export default Responsive;