import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import countryList from "react-select-country-list";
import validateSixthStep from "../../utils/validation_forms/IdentityForm/validate_sixth_step";
import { validationRules } from "../../utils/validation_forms/IdentityForm/validate_sixth_step";
import { formatDate } from "../../utils/Date";
import ModalErrorData from "../Modals/modal_no_data";
import { getDefaultLanguage } from "../../utils/language";
import * as HttpRequest from "../../utils/HttpRequest";
import config from "../../config/load_config";
import parseHTML from "html-react-parser";

/**
 * This component is using to manage user identity recap and accommodation recap
 * [MAGE.COM.WA.52 - 1.14] to [MAGE.COM.WA.57 - 1.14] and [MAGE.ALERT.WA.4 - 1.14]
 * @component
 */
class IdentityRecap extends React.Component {
  constructor(props) {
    super(props);

    //Get list of country
    this.listOfCountry = countryList().getData();

    this.state = {
      certifiedInformationsIdentityRecap: false,
      giveConsent: false,
      haveBeenInformedOfDevices: false,
      hasCheckedAllInformation: false,
      acceptToReceivedMyResultByEmail: false,
      isModalSubmissionShowing: false,
      sendingData: false,
      listOfCountry: this.listOfCountry,
      consentText: this.props.forms.wizard.values.consentText,
      errors: {},
    };
    this.handleCertifiedInformationsIdentityRecapChange =
      this.handleCertifiedInformationsIdentityRecapChange.bind(this);
    this.handleHaveBeenInformedOfDevicesChange =
      this.handleHaveBeenInformedOfDevicesChange.bind(this);
    this.handleHasCheckedAllInformationChange =
      this.handleHasCheckedAllInformationChange.bind(this);
    this.handleAcceptToReceivedMyResultByEmailChange =
      this.handleAcceptToReceivedMyResultByEmailChange.bind(this);
    this.sendAllData = this.sendAllData.bind(this);
    this.handleGiveConsentChange = this.handleGiveConsentChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.openErrorDataModal = this.openErrorDataModal.bind(this);
    this.handlePreviousPage = this.handlePreviousPage.bind(this);
  }

  /**
   * [MAGE.COM.WA.54 - 1.14]
   */
  handleCertifiedInformationsIdentityRecapChange(e) {
    var errors = this.state.errors;
    errors.certifiedInformationsIdentityRecap = "";
    errors.formError = "";
    this.setState({
      errors: errors,
      certifiedInformationsIdentityRecap:
        !this.state.certifiedInformationsIdentityRecap,
    });
  }

  /**
   * [MAGE.COM.WA.55 - 1.14]
   */
  handleHaveBeenInformedOfDevicesChange(e) {
    var errors = this.state.errors;
    errors.haveBeenInformedOfDevices = "";
    errors.formError = "";
    this.setState({
      errors: errors,
      haveBeenInformedOfDevices: !this.state.haveBeenInformedOfDevices,
    });
  }

  handleGiveConsentChange(e) {
    var errors = this.state.errors;
    errors.giveConsent = "";
    errors.formError = "";
    this.setState({ errors: errors, giveConsent: !this.state.giveConsent });
  }

  openModalHandler = () => {
    this.setState({
      isModalSubmissionShowing: true,
    });
  };

  // [MAGE.COM.WA.87 - 1.15]
  closeModalHandler = () => {
    this.setState({
      isModalSubmissionShowing: false,
    });
  };

  handleHasCheckedAllInformationChange(e) {
    var errors = this.state.errors;
    errors.allNotChecked = "";
    errors.formError = "";
    this.setState({
      errors: errors,
      hasCheckedAllInformation: !this.state.hasCheckedAllInformation,
    });
  }

  handleAcceptToReceivedMyResultByEmailChange(e) {
    var errors = this.state.errors;
    errors.allNotChecked = "";
    errors.formError = "";
    this.setState({
      errors: errors,
      acceptToReceivedMyResultByEmail:
        !this.state.acceptToReceivedMyResultByEmail,
    });
  }

  /**
   * [MAGE.COM.WA.56 - 1.14]
   */
  handleSubmit(e) {
    const dataToValidate = {
      giveConsent: this.state.giveConsent,
    };

    var errorList = validationRules(dataToValidate, this.props.translate);
    if (Object.keys(errorList).length !== 0) {
      this.setState({ errors: errorList });
    } else {
      this.sendAllData();
    }

    e.preventDefault();
  }

  /**
   * [MAGE.COM.WA.57 - 1.14]
   */
  handlePreviousPage(e) {
    e.preventDefault();
    this.props.previousPage();
  }

  openErrorDataModal = () => {
    const dataToShow = {
      title: this.props.translate("ERROR_DATA_TITLE_LABEL"),
      description: this.props.translate("ERROR_DATA_SUBTITLE_LABEL"),
      btnYesText: this.props.translate("ERROR_DATA_BUTTON_OK_LABEL"),
    };
    ModalErrorData(dataToShow);
  };

  /**
   * [MAGE.COM.WA.62 - 1.15] and [MAGE.COM.WA.64 - 1.15] and [MAGE.COM.WA.86 - 1.15]
   */
  async sendAllData() {
    var errors = this.state.errors;
    errors.allNotChecked = "";
    errors.formError = "";
    this.state.sendingData = true;
    const birthDate = formatDate(
      this.props.forms.wizard.values.dtDay,
      this.props.forms.wizard.values.dtMonth,
      this.props.forms.wizard.values.dtYear,
      this.props.translate("DATE_FORMAT")
    );

    const dataToSend = {
      patientSource: "France",
      language: getDefaultLanguage(),
      gender: this.props.forms.wizard.values.gender,
      email: this.props.forms.wizard.values.email,
      firstName: this.props.forms.wizard.values.firstName,
      lastName: this.props.forms.wizard.values.lastName,
      birthName: this.props.forms.wizard.values.birthName || null,
      birthDate: birthDate,
      dateOfBirth: new Date(
        formatDate(
          this.props.forms.wizard.values.dtDay,
          this.props.forms.wizard.values.dtMonth,
          this.props.forms.wizard.values.dtYear,
          "YYYY-MM-DD"
        )
      ),
      birthPlace: this.props.forms.wizard.values.birthPlace,
      nir: this.props.forms.wizard.values.hasNir
        ? this.props.forms.wizard.values.nir
        : null,
      phoneNumber:
        this.props.forms.wizard.values.phoneNumber.split(" ").join("") || null,
      testType: this.props.forms.wizard.values.testType || null,
      address: {
        streetNumber: this.props.forms.wizard.values.firstStreetNumber || null,
        streetNumberAddon: this.props.forms.wizard.values.complement || null,
        street: this.props.forms.wizard.values.secondStreetNumber,
        city: this.props.forms.wizard.values.city,
        zip: this.props.forms.wizard.values.zipCode,
        country: this.props.forms.wizard.values.accommodationCountry,
        complement: this.props.forms.wizard.values.outZipCode || null,
      },
      accommodationType:
        this.props.forms.wizard.values.accommodationType || null,
      testPlace: {
        institution: this.props.forms.wizard.values.institution || null,
        testPlaceCity: this.props.forms.wizard.values.testPlaceCity || null,
        testPlaceCountry:
          this.props.forms.wizard.values.testPlaceCountry || null,
        testPlaceZipCode:
          this.props.forms.wizard.values.testPlaceZipCode || null,
      },
      testMotivations: {
        professionalNeeded:
          this.props.forms.wizard.values.professionalNeeded || null,
        travelByPlane: this.props.forms.wizard.values.travelByPlane || null,
        companyName: this.props.forms.wizard.values.companyName || null,
        hasBeenInContactWithCovidPerson:
          this.props.forms.wizard.values.hasBeenInContactWithCovidPerson ||
          null,
        doctorName: this.props.forms.wizard.values.doctorName || null,
        flightNumber: this.props.forms.wizard.values.flightNumber || null,
        hasBeenContactedByHealthInvestigator:
          this.props.forms.wizard.values.hasBeenContactedByHealthInvestigator ||
          null,
        hasBeenInContactWithCluster:
          this.props.forms.wizard.values.hasBeenInContactWithCluster || null,
        hasMedicalPrescription:
          this.props.forms.wizard.values.hasMedicalPrescription || null,
        haveHautingPersonAtRisk:
          this.props.forms.wizard.values.haveHautingPersonAtRisk || null,
        haveScreeningVoucher:
          this.props.forms.wizard.values.haveScreeningVoucher || null,
        others: this.props.forms.wizard.values.others || null,
        planeTicketNumber:
          this.props.forms.wizard.values.planeTicketNumber || null,
        screeningForMySelf:
          this.props.forms.wizard.values.screeningForMySelf || null,
        willGoingToInstitution:
          this.props.forms.wizard.values.willGoingToInstitution || null,
      },
      infoSupp: {
        giveConsent: this.props.forms.wizard.values.giveConsent || null,
        lastVisitedCountry:
          this.props.forms.wizard.values.lastVisitedCountry || null,
        livingOut: this.props.forms.wizard.values.livingOut || null,
        hasBeenInOtherCountry:
          this.props.forms.wizard.values.hasBeenInOtherCountry || null,
        nationality: this.props.forms.wizard.values.nationality || null,
        haveBeenInformedOfDevices:
          this.props.forms.wizard.values.haveBeenInformedOfDevices || null,
        haveReceivedNotificationFromApplication:
          this.props.forms.wizard.values
            .haveReceivedNotificationFromApplication || null,
      },
    };

    //Construct JSON Object to send
    const response = await HttpRequest.doPost(
      process.env.REACT_APP_API_BASE_URL + config.API.PATIENT,
      dataToSend,
      {
        "Access-Control-Allow-Origin": "*",
        "content-type": "application/json",
      }
    );

    console.log(response);
    if (response.error) {
      this.setState({ sendingData: false });
      this.openErrorDataModal();
    } else {
      this.setState({ sendingData: false });
      this.closeModalHandler();
      this.props.onSubmit(response.data.id);
    }

    this.setState({ errors: errors });
  }

  render() {
    /**
     * [MAGE.COM.WA.53 - 1.14]
     * To perfom edit, step parameter is passed props previousPage function like that () => previousPage(step)
     */
    const { previousPage, translate, forms, device, consentText } = this.props;
    return (
      <div>
        {device === "lap/desk" && (
          <>
            <p className="title">{translate("IDENTITY_VERIFICATION_LABEL")}</p>
            <div>
              <form onSubmit={this.handleSubmit}>
                {/* [MAGE.COM.WA.52 - 1.13] */}
                <div className="fieldContainer">
                  <div className="edit-link-container">
                    <a className="edit-link" onClick={() => previousPage(1)}>
                      {translate("EDIT_LINK_TEXT")}
                    </a>
                  </div>
                  <div className="row-input">
                    <table className="table-recap">
                      <tr className="recap-row">
                        <td>
                          <p className="recap-label">
                            {translate("FIRSTNAME_LABEL")}
                          </p>
                        </td>
                        <td>
                          <p className="label">
                            {forms.wizard.values.firstName}
                          </p>
                        </td>
                      </tr>
                      <tr className="recap-row">
                        <td>
                          <p className="recap-label">
                            {translate("LASTNAME_LABEL")}
                          </p>
                        </td>
                        <td>
                          <p className="label">
                            {forms.wizard.values.lastName}
                          </p>
                        </td>
                      </tr>
                      <tr className="recap-row">
                        <td>
                          <p className="recap-label">
                            {translate("BIRTHNAME_LABEL")}
                          </p>
                        </td>
                        <td>
                          <p className="label">
                            {forms.wizard.values.birthName
                              ? forms.wizard.values.birthName
                              : translate("NOT_DEFINED_VALUE")}
                          </p>
                        </td>
                      </tr>
                      <tr className="recap-row">
                        <td>
                          <p className="recap-label">
                            {translate("GENDER_FORM_LABEL")}
                          </p>
                        </td>
                        <td>
                          <p className="label">
                            {forms.wizard.values.gender === "M"
                              ? translate("GENDER_MALE_LABEL")
                              : translate("GENDER_FEMALE_LABEL")}
                          </p>
                        </td>
                      </tr>
                      <tr className="recap-row">
                        <td>
                          <p className="recap-label">
                            {translate("BIRTHDAY_LABEL")}
                          </p>
                        </td>
                        <td>
                          <p className="label">
                            {formatDate(
                              forms.wizard.values.dtDay,
                              forms.wizard.values.dtMonth,
                              forms.wizard.values.dtYear,
                              translate("DATE_FORMAT")
                            )}
                          </p>
                        </td>
                      </tr>
                      <tr className="recap-row">
                        <td>
                          <p className="recap-label">
                            {translate("BIRTHPLACE_LABEL")}
                          </p>
                        </td>
                        <td>
                          <p className="label">
                            {forms.wizard.values.birthPlace
                              ? forms.wizard.values.birthPlace
                              : translate("NOT_DEFINED_VALUE")}
                          </p>
                        </td>
                      </tr>
                      <tr className="recap-row">
                        <td>
                          <p className="recap-label">
                            {translate("NATIONALITY_LABEL")}
                          </p>
                        </td>
                        <td>
                          {this.state.listOfCountry.map((country) => {
                            if (
                              forms.wizard.values.nationality == country.value
                            ) {
                              return <p className="label">{country.label}</p>;
                            }
                          })}
                        </td>
                      </tr>
                      <tr className="recap-row">
                        <td>
                          <p className="recap-label">
                            {translate("SECU_NUMBER_LABEL")}
                          </p>
                        </td>
                        <td>
                          <p className="label">
                            {forms.wizard.values.hasNir == "true"
                              ? forms.wizard.values.nir
                              : translate("NO_SECURITY_SOCIAL_LABEL")}
                          </p>
                        </td>
                      </tr>
                      <tr className="recap-row">
                        <td>
                          <p className="recap-label">
                            {translate("EMAIL_ADDRESS_LABEL")}
                          </p>
                        </td>
                        <td>
                          <p className="label">{forms.wizard.values.email}</p>
                        </td>
                      </tr>
                      <tr className="recap-row-none">
                        <td>
                          <p className="recap-label">
                            {translate("PHONE_NUMBER_LABEL")}
                          </p>
                        </td>
                        <td>
                          <p className="label">
                            {forms.wizard.values.phoneNumber}
                          </p>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <p className="title">
                    {translate("ACCOMMODATION_VERIFICATION_LABEL")}
                  </p>
                  <div className="edit-link-container">
                    <a className="edit-link" onClick={() => previousPage(3)}>
                      {translate("EDIT_LINK_TEXT")}
                    </a>
                  </div>
                  <div className="row-input">
                    <table className="table-recap">
                      <tr className="recap-row">
                        <td>
                          <p className="recap-label">
                            {translate("ACCOMMODATION_TYPE_LABEL")}
                          </p>
                        </td>
                        <td>
                          <p className="label">
                            {translate(
                              forms.wizard.values.accommodationTypeValue
                            )}
                          </p>
                        </td>
                      </tr>
                      <tr className="recap-row">
                        <td>
                          <p className="recap-label">
                            {translate("ADDRESS_LABEL")}
                          </p>
                        </td>
                        <td>
                          <p className="label">
                            {forms.wizard.values.secondStreetNumber
                              ? forms.wizard.values.secondStreetNumber
                              : translate("NOT_DEFINED_VALUE")}
                          </p>
                        </td>
                      </tr>
                      <tr className="recap-row">
                        <td>
                          <p className="recap-label">
                            {translate("ACCOMMODATION_CITY_LABEL")}
                          </p>
                        </td>
                        <td>
                          <p className="label">
                            {forms.wizard.values.city
                              ? forms.wizard.values.city
                              : translate("NOT_DEFINED_VALUE")}
                          </p>
                        </td>
                      </tr>
                      <tr className="recap-row">
                        <td>
                          <p className="recap-label">
                            {translate("ACCOMMODATION_COUNTRY_LABEL")}
                          </p>
                        </td>
                        <td>
                          {this.state.listOfCountry.map((country) => {
                            if (
                              forms.wizard.values.accommodationCountry ==
                              country.value
                            ) {
                              return <p className="label">{country.label}</p>;
                            }
                          })}
                        </td>
                      </tr>
                      <tr className="recap-row">
                        <td>
                          <p className="recap-label">
                            {translate("QUESTION_LAST_COUNTRY_LABEL")}
                          </p>
                        </td>
                        <td>
                          <p className="label">
                            {forms.wizard.values.hasBeenInOtherCountry == "true"
                              ? translate("RESPONSE_YES_TO_LAST_COUNTRY_LABEL")
                              : translate("RESPONSE_NO_TO_LAST_COUNTRY_LABEL")}
                          </p>
                        </td>
                      </tr>
                      {forms.wizard.values.hasBeenInOtherCountry == "true" && (
                        <tr className="recap-row">
                          <td>
                            <p className="recap-sub-label">
                              {translate("LAST_COUNTRY_LABEL")}
                            </p>
                          </td>
                          <td>
                            {this.state.listOfCountry.map((country) => {
                              if (
                                forms.wizard.values.lastVisitedCountry ==
                                country.value
                              ) {
                                return <p className="label">{country.label}</p>;
                              }
                            })}
                          </td>
                        </tr>
                      )}
                      <tr
                        className={
                          forms.wizard.values.livingOut
                            ? "recap-row"
                            : "recap-row-none"
                        }
                      >
                        <td>
                          <p className="recap-label">
                            {translate("TEMPORARY_RESIDENCE_LABEL")}
                          </p>
                        </td>
                        <td>
                          <p className="label">
                            {forms.wizard.values.livingOut
                              ? translate("RESPONSE_YES_LABEL")
                              : translate("RESPONSE_NO_LABEL")}
                          </p>
                        </td>
                      </tr>
                      {forms.wizard.values.livingOut && (
                        <tr className="recap-row-none">
                          <td>
                            <p className="recap-sub-label">
                              {translate("ACCOMMODATION_ZIP_CODE_LABEL")}
                            </p>
                          </td>
                          <td>
                            <p className="label">
                              {forms.wizard.values.outZipCode}
                            </p>
                          </td>
                        </tr>
                      )}
                    </table>
                  </div>

                  <p className="title">
                    {translate("TEST_REASONS_RECAP_LABEL")}
                  </p>
                  <div className="edit-link-container">
                    <a className="edit-link" onClick={() => previousPage(4)}>
                      {translate("EDIT_LINK_TEXT")}
                    </a>
                  </div>
                  <div className="row-input">
                    <table className="table-recap">
                      {forms.wizard.values.hasMedicalPrescription && [
                        <tr className="one-column">
                          <td colSpan="2">
                            <p className="recap-label">
                              {translate("MEDICAL_PRESCRIPTION_LABEL")}
                            </p>
                          </td>
                        </tr>,
                        <tr className="recap-row">
                          <td>
                            <p className="recap-sub-label">
                              {translate("DOCTOR_NAME_LABEL")}
                            </p>
                          </td>
                          <td>
                            <p className="label">
                              {forms.wizard.values.doctorName
                                ? forms.wizard.values.doctorName
                                : translate("NOT_DEFINED_VALUE")}
                            </p>
                          </td>
                        </tr>,
                      ]}
                      {forms.wizard.values.hasBeenInContactWithCovidPerson && (
                        <tr className="one-column">
                          <td colSpan="2">
                            <p>{translate("COVID_PERSON_LABEL")}</p>
                          </td>
                        </tr>
                      )}
                      {forms.wizard.values.hasBeenInContactWithCluster && (
                        <tr className="one-column">
                          <td colSpan="2">
                            <p>{translate("CONTACT_WITH_CLUSTER_LABEL")}</p>
                          </td>
                        </tr>
                      )}
                      {forms.wizard.values
                        .hasBeenContactedByHealthInvestigator && (
                        <tr className="one-column">
                          <td colSpan="2">
                            <p>
                              {translate("CONTACTED_BY_INVESTIGATOR_LABEL")}
                            </p>
                          </td>
                        </tr>
                      )}
                      {forms.wizard.values.haveScreeningVoucher && (
                        <tr className="one-column">
                          <td colSpan="2">
                            <p>{translate("SCREENING_VOUCHER_LABEL")}</p>
                          </td>
                        </tr>
                      )}
                      {forms.wizard.values.haveHautingPersonAtRisk && (
                        <tr className="one-column">
                          <td colSpan="2">
                            <p>{translate("HAUTING_PERSON_RISK_LABEL")}</p>
                          </td>
                        </tr>
                      )}
                      {forms.wizard.values.screeningForMySelf && (
                        <tr className="one-column">
                          <td colSpan="2">
                            <p>{translate("SCREENING_MYSELF_LABEL")}</p>
                          </td>
                        </tr>
                      )}
                      {forms.wizard.values.professionalNeeded && [
                        <tr className="one-column">
                          <td colSpan="2">
                            <p className="recap-label">
                              {translate("PROFESSIONAL_NEEDED_LABEL")}
                            </p>
                          </td>
                        </tr>,
                        <tr className="recap-row">
                          <td>
                            <p className="recap-sub-label">
                              {translate("QUESTION_COMPANY_NAME_LABEL")}
                            </p>
                          </td>
                          <td>
                            <p className="label">
                              {forms.wizard.values.companyName
                                ? forms.wizard.values.companyName
                                : translate("NOT_DEFINED_VALUE")}
                            </p>
                          </td>
                        </tr>,
                      ]}
                      {forms.wizard.values.willGoingToInstitution && (
                        <tr className="one-column">
                          <td colSpan="2">
                            <p>{translate("GOING_TO_INSTITUTION_LABEL")}</p>
                          </td>
                        </tr>
                      )}
                      {forms.wizard.values.travelByPlane && [
                        <tr className="one-column">
                          <td colSpan="2">
                            <p className="recap-label">
                              {translate("TRAVEL_BY_PLANE_LABEL")}
                            </p>
                          </td>
                        </tr>,
                        <tr className="recap-row">
                          <td>
                            <p className="recap-sub-label">
                              {translate("FLIGHT_NUMBER_LABEL")}
                            </p>
                          </td>
                          <td>
                            <p className="label">
                              {forms.wizard.values.flightNumber
                                ? forms.wizard.values.flightNumber
                                : translate("NOT_DEFINED_VALUE")}
                            </p>
                          </td>
                        </tr>,
                        <tr className="recap-row">
                          <td>
                            <p className="recap-sub-label">
                              {translate("PLANE_TICKET_LABEL")}
                            </p>
                          </td>
                          <td>
                            <p className="label">
                              {forms.wizard.values.planeTicketNumber
                                ? forms.wizard.values.planeTicketNumber
                                : translate("NOT_DEFINED_VALUE")}
                            </p>
                          </td>
                        </tr>,
                      ]}
                      {forms.wizard.values
                        .haveReceivedNotificationFromApplication && (
                        <tr className="one-column">
                          <td colSpan="2">
                            <p>
                              {translate(
                                "RECEIVED_TOUSCOVID_NOTIFICATION_LABEL"
                              )}
                            </p>
                          </td>
                        </tr>
                      )}
                      {forms.wizard.values.others && (
                        <tr className="one-column">
                          <td colSpan="2">
                            <p>{translate("REASON_TEST_OTHER_LABEL")}</p>
                          </td>
                        </tr>
                      )}
                    </table>
                  </div>
                  <div className="input-container">
                    <label
                      className={
                        this.state.giveConsent
                          ? "checkbox-field-checked"
                          : "checkbox-field"
                      }
                    >
                      <div>
                        <Field
                          name="giveConsent"
                          component="input"
                          className="checkboxBtn"
                          type="checkbox"
                          value={this.state.giveConsent}
                          checked={this.state.giveConsent}
                          onChange={this.handleGiveConsentChange}
                        />
                      </div>
                      {parseHTML(translate("GIVE_CONSENT_TEXT"))}
                    </label>
                    {this.state.errors.giveConsent &&
                      this.state.errors.giveConsent != "" && (
                        <p className="error-label">
                          {this.state.errors.giveConsent}
                        </p>
                      )}
                  </div>
                </div>
                {/* [MAGE.ALERT.WA.4 - 1.14] */}
                {this.state.errors.formError && this.state.errors.formError && (
                  <div className="form-error-container">
                    <p className="form-error-label">
                      {this.state.errors.formError}
                    </p>
                  </div>
                )}
                <div>
                  <button
                    type="button"
                    className="btnSecondary"
                    onClick={this.handlePreviousPage}
                  >
                    {translate("BUTTON_BACK_LABEL")}
                  </button>
                  <button
                    type="submit"
                    className="btnMain"
                    disabled={this.state.sendingData}
                  >
                    {!this.state.sendingData
                      ? translate("BUTTON_NEXT_LABEL")
                      : translate("BUTTON_LAST_STEP_BEFORE_END_PENDING_LABEL")}
                  </button>
                </div>
              </form>
            </div>
          </>
        )}
        {device === "phone/tablet" && (
          <>
            <div className="container-min">
              <p className="title-min">
                {translate("IDENTITY_VERIFICATION_LABEL")}
              </p>
              <form onSubmit={this.handleSubmit}>
                {/* [MAGE.COM.WA.52 - 1.13] */}
                <div className="fieldContainer">
                  <div className="edit-link-container">
                    <a className="edit-link" onClick={() => previousPage(1)}>
                      {translate("EDIT_LINK_TEXT")}
                    </a>
                  </div>
                  <div className="row-input">
                    <table className="table-recap">
                      <tr className="recap-row">
                        <td>
                          <p className="recap-label">
                            {translate("FIRSTNAME_LABEL")}
                          </p>
                        </td>
                        <td>
                          <p className="label">
                            {forms.wizard.values.firstName}
                          </p>
                        </td>
                      </tr>
                      <tr className="recap-row">
                        <td>
                          <p className="recap-label">
                            {translate("LASTNAME_LABEL")}
                          </p>
                        </td>
                        <td>
                          <p className="label">
                            {forms.wizard.values.lastName}
                          </p>
                        </td>
                      </tr>
                      <tr className="recap-row">
                        <td>
                          <p className="recap-label">
                            {translate("BIRTHNAME_LABEL")}
                          </p>
                        </td>
                        <td>
                          <p className="label">
                            {forms.wizard.values.birthName
                              ? forms.wizard.values.birthName
                              : translate("NOT_DEFINED_VALUE")}
                          </p>
                        </td>
                      </tr>
                      <tr className="recap-row">
                        <td>
                          <p className="recap-label">
                            {translate("GENDER_FORM_LABEL")}
                          </p>
                        </td>
                        <td>
                          <p className="label">
                            {forms.wizard.values.gender === "M"
                              ? translate("GENDER_MALE_LABEL")
                              : translate("GENDER_FEMALE_LABEL")}
                          </p>
                        </td>
                      </tr>
                      <tr className="recap-row">
                        <td>
                          <p className="recap-label">
                            {translate("BIRTHDAY_LABEL")}
                          </p>
                        </td>
                        <td>
                          <p className="label">
                            {formatDate(
                              forms.wizard.values.dtDay,
                              forms.wizard.values.dtMonth,
                              forms.wizard.values.dtYear,
                              translate("DATE_FORMAT")
                            )}
                          </p>
                        </td>
                      </tr>
                      <tr className="recap-row">
                        <td>
                          <p className="recap-label">
                            {translate("BIRTHPLACE_LABEL")}
                          </p>
                        </td>
                        <td>
                          <p className="label">
                            {forms.wizard.values.birthPlace
                              ? forms.wizard.values.birthPlace
                              : translate("NOT_DEFINED_VALUE")}
                          </p>
                        </td>
                      </tr>
                      <tr className="recap-row">
                        <td>
                          <p className="recap-label">
                            {translate("NATIONALITY_LABEL")}
                          </p>
                        </td>
                        <td>
                          {this.state.listOfCountry.map((country) => {
                            if (
                              forms.wizard.values.nationality == country.value
                            ) {
                              return <p className="label">{country.label}</p>;
                            }
                          })}
                        </td>
                      </tr>
                      <tr className="recap-row">
                        <td>
                          <p className="recap-label">
                            {translate("SECU_NUMBER_LABEL")}
                          </p>
                        </td>
                        <td>
                          <p className="label">
                            {forms.wizard.values.hasNir == "true"
                              ? forms.wizard.values.nir
                              : translate("NO_SECURITY_SOCIAL_LABEL")}
                          </p>
                        </td>
                      </tr>
                      <tr className="recap-row">
                        <td>
                          <p className="recap-label">
                            {translate("EMAIL_ADDRESS_LABEL")}
                          </p>
                        </td>
                        <td>
                          <p className="label">{forms.wizard.values.email}</p>
                        </td>
                      </tr>
                      <tr className="recap-row-none">
                        <td>
                          <p className="recap-label">
                            {translate("PHONE_NUMBER_LABEL")}
                          </p>
                        </td>
                        <td>
                          <p className="label">
                            {forms.wizard.values.phoneNumber}
                          </p>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <p className="title-min">
                    {translate("ACCOMMODATION_VERIFICATION_LABEL")}
                  </p>
                  <div className="edit-link-container">
                    <a className="edit-link" onClick={() => previousPage(3)}>
                      {translate("EDIT_LINK_TEXT")}
                    </a>
                  </div>
                  <div className="row-input">
                    <table className="table-recap">
                      <tr className="recap-row">
                        <td>
                          <p className="recap-label">
                            {translate("ACCOMMODATION_TYPE_LABEL")}
                          </p>
                        </td>
                        <td>
                          <p className="label">
                            {translate(
                              forms.wizard.values.accommodationTypeValue
                            )}
                          </p>
                        </td>
                      </tr>
                      <tr className="recap-row">
                        <td>
                          <p className="recap-label">
                            {translate("ADDRESS_LABEL")}
                          </p>
                        </td>
                        <td>
                          <p className="label">
                            {forms.wizard.values.secondStreetNumber
                              ? forms.wizard.values.secondStreetNumber
                              : translate("NOT_DEFINED_VALUE")}
                          </p>
                        </td>
                      </tr>
                      <tr className="recap-row">
                        <td>
                          <p className="recap-label">
                            {translate("ACCOMMODATION_CITY_LABEL")}
                          </p>
                        </td>
                        <td>
                          <p className="label">
                            {forms.wizard.values.city
                              ? forms.wizard.values.city
                              : translate("NOT_DEFINED_VALUE")}
                          </p>
                        </td>
                      </tr>
                      <tr className="recap-row">
                        <td>
                          <p className="recap-label">
                            {translate("ACCOMMODATION_COUNTRY_LABEL")}
                          </p>
                        </td>
                        <td>
                          {this.state.listOfCountry.map((country) => {
                            if (
                              forms.wizard.values.accommodationCountry ==
                              country.value
                            ) {
                              return <p className="label">{country.label}</p>;
                            }
                          })}
                        </td>
                      </tr>
                      <tr className="recap-row">
                        <td>
                          <p className="recap-label">
                            {translate("QUESTION_LAST_COUNTRY_LABEL")}
                          </p>
                        </td>
                        <td>
                          <p className="label">
                            {forms.wizard.values.hasBeenInOtherCountry == "true"
                              ? translate("RESPONSE_YES_TO_LAST_COUNTRY_LABEL")
                              : translate("RESPONSE_NO_TO_LAST_COUNTRY_LABEL")}
                          </p>
                        </td>
                      </tr>
                      {forms.wizard.values.hasBeenInOtherCountry == "true" && (
                        <tr className="recap-row">
                          <td>
                            <p className="recap-sub-label">
                              {translate("LAST_COUNTRY_LABEL")}
                            </p>
                          </td>
                          <td>
                            {this.state.listOfCountry.map((country) => {
                              if (
                                forms.wizard.values.lastVisitedCountry ==
                                country.value
                              ) {
                                return <p className="label">{country.label}</p>;
                              }
                            })}
                          </td>
                        </tr>
                      )}
                      <tr
                        className={
                          forms.wizard.values.livingOut
                            ? "recap-row"
                            : "recap-row-none"
                        }
                      >
                        <td>
                          <p className="recap-label">
                            {translate("TEMPORARY_RESIDENCE_LABEL")}
                          </p>
                        </td>
                        <td>
                          <p className="label">
                            {forms.wizard.values.livingOut
                              ? translate("RESPONSE_YES_LABEL")
                              : translate("RESPONSE_NO_LABEL")}
                          </p>
                        </td>
                      </tr>
                      {forms.wizard.values.livingOut && (
                        <tr className="recap-row-none">
                          <td>
                            <p className="recap-sub-label">
                              {translate("ACCOMMODATION_ZIP_CODE_LABEL")}
                            </p>
                          </td>
                          <td>
                            <p className="label">
                              {forms.wizard.values.outZipCode}
                            </p>
                          </td>
                        </tr>
                      )}
                    </table>
                  </div>

                  <p className="title-min">
                    {translate("TEST_REASONS_RECAP_LABEL")}
                  </p>
                  <div className="edit-link-container">
                    <a className="edit-link" onClick={() => previousPage(4)}>
                      {translate("EDIT_LINK_TEXT")}
                    </a>
                  </div>
                  <div className="row-input">
                    <table className="table-recap">
                      {forms.wizard.values.hasMedicalPrescription && [
                        <tr className="one-column">
                          <td colSpan="2">
                            <p className="recap-label">
                              {translate("MEDICAL_PRESCRIPTION_LABEL")}
                            </p>
                          </td>
                        </tr>,
                        <tr className="recap-row">
                          <td>
                            <p className="recap-sub-label">
                              {translate("DOCTOR_NAME_LABEL")}
                            </p>
                          </td>
                          <td>
                            <p className="label">
                              {forms.wizard.values.doctorName
                                ? forms.wizard.values.doctorName
                                : translate("NOT_DEFINED_VALUE")}
                            </p>
                          </td>
                        </tr>,
                      ]}
                      {forms.wizard.values.hasBeenInContactWithCovidPerson && (
                        <tr className="one-column">
                          <td colSpan="2">
                            <p>{translate("COVID_PERSON_LABEL")}</p>
                          </td>
                        </tr>
                      )}
                      {forms.wizard.values.hasBeenInContactWithCluster && (
                        <tr className="one-column">
                          <td colSpan="2">
                            <p>{translate("CONTACT_WITH_CLUSTER_LABEL")}</p>
                          </td>
                        </tr>
                      )}
                      {forms.wizard.values
                        .hasBeenContactedByHealthInvestigator && (
                        <tr className="one-column">
                          <td colSpan="2">
                            <p>
                              {translate("CONTACTED_BY_INVESTIGATOR_LABEL")}
                            </p>
                          </td>
                        </tr>
                      )}
                      {forms.wizard.values.haveScreeningVoucher && (
                        <tr className="one-column">
                          <td colSpan="2">
                            <p>{translate("SCREENING_VOUCHER_LABEL")}</p>
                          </td>
                        </tr>
                      )}
                      {forms.wizard.values.haveHautingPersonAtRisk && (
                        <tr className="one-column">
                          <td colSpan="2">
                            <p>{translate("HAUTING_PERSON_RISK_LABEL")}</p>
                          </td>
                        </tr>
                      )}
                      {forms.wizard.values.screeningForMySelf && (
                        <tr className="one-column">
                          <td colSpan="2">
                            <p>{translate("SCREENING_MYSELF_LABEL")}</p>
                          </td>
                        </tr>
                      )}
                      {forms.wizard.values.professionalNeeded && [
                        <tr className="one-column">
                          <td colSpan="2">
                            <p className="recap-label">
                              {translate("PROFESSIONAL_NEEDED_LABEL")}
                            </p>
                          </td>
                        </tr>,
                        <tr className="recap-row">
                          <td>
                            <p className="recap-sub-label">
                              {translate("QUESTION_COMPANY_NAME_LABEL")}
                            </p>
                          </td>
                          <td>
                            <p className="label">
                              {forms.wizard.values.companyName
                                ? forms.wizard.values.companyName
                                : translate("NOT_DEFINED_VALUE")}
                            </p>
                          </td>
                        </tr>,
                      ]}
                      {forms.wizard.values.willGoingToInstitution && (
                        <tr className="one-column">
                          <td colSpan="2">
                            <p>{translate("GOING_TO_INSTITUTION_LABEL")}</p>
                          </td>
                        </tr>
                      )}
                      {forms.wizard.values.travelByPlane && [
                        <tr className="one-column">
                          <td colSpan="2">
                            <p className="recap-label">
                              {translate("TRAVEL_BY_PLANE_LABEL")}
                            </p>
                          </td>
                        </tr>,
                        <tr className="recap-row">
                          <td>
                            <p className="recap-sub-label">
                              {translate("FLIGHT_NUMBER_LABEL")}
                            </p>
                          </td>
                          <td>
                            <p className="label">
                              {forms.wizard.values.flightNumber
                                ? forms.wizard.values.flightNumber
                                : translate("NOT_DEFINED_VALUE")}
                            </p>
                          </td>
                        </tr>,
                        <tr className="recap-row">
                          <td>
                            <p className="recap-sub-label">
                              {translate("PLANE_TICKET_LABEL")}
                            </p>
                          </td>
                          <td>
                            <p className="label">
                              {forms.wizard.values.planeTicketNumber
                                ? forms.wizard.values.planeTicketNumber
                                : translate("NOT_DEFINED_VALUE")}
                            </p>
                          </td>
                        </tr>,
                      ]}
                      {forms.wizard.values
                        .haveReceivedNotificationFromApplication && (
                        <tr className="one-column">
                          <td colSpan="2">
                            <p>
                              {translate(
                                "RECEIVED_TOUSCOVID_NOTIFICATION_LABEL"
                              )}
                            </p>
                          </td>
                        </tr>
                      )}
                      {forms.wizard.values.others && (
                        <tr className="one-column">
                          <td colSpan="2">
                            <p>{translate("REASON_TEST_OTHER_LABEL")}</p>
                          </td>
                        </tr>
                      )}
                    </table>
                  </div>
                  <div className="input-container">
                    <label
                      className={
                        this.state.giveConsent
                          ? "checkbox-field-checked"
                          : "checkbox-field"
                      }
                    >
                      <div>
                        <Field
                          name="giveConsent"
                          component="input"
                          className="checkboxBtn"
                          type="checkbox"
                          value={this.state.giveConsent}
                          checked={this.state.giveConsent}
                          onChange={this.handleGiveConsentChange}
                        />
                      </div>
                      {parseHTML(translate("GIVE_CONSENT_TEXT"))}
                    </label>
                    {this.state.errors.giveConsent &&
                      this.state.errors.giveConsent != "" && (
                        <p className="error-label">
                          {this.state.errors.giveConsent}
                        </p>
                      )}
                  </div>
                </div>
                {/* [MAGE.ALERT.WA.4 - 1.14] */}
                {this.state.errors.formError && this.state.errors.formError && (
                  <div className="form-error-container">
                    <p className="form-error-label">
                      {this.state.errors.formError}
                    </p>
                  </div>
                )}
                <div>
                  <div className="wizard-buttons-container">
                    <button
                      type="button"
                      className="btnSecondary"
                      onClick={this.handlePreviousPage}
                    >
                      {translate("BUTTON_BACK_LABEL")}
                    </button>
                    <button
                      type="submit"
                      className="btnMain"
                      disabled={this.state.sendingData}
                    >
                      {!this.state.sendingData
                        ? translate("BUTTON_NEXT_LABEL")
                        : translate(
                            "BUTTON_LAST_STEP_BEFORE_END_PENDING_LABEL"
                          )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    forms: state.form,
  };
}

export default reduxForm({
  form: "wizard",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validateSixthStep,
})(connect(mapStateToProps, null)(IdentityRecap));
